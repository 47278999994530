@import '../variables/index';

$small: 'negative-change',
  'positive-change';

$small-stroke: 'accordion-open-caret',
 'accordion-closed-caret';

$small-fill: 
  'accordion-closed',
  'accordion-open',
  'announcement',
  'bookmark-checked',
  'bookmark-unchecked',
  'caret-down',
  'caret-left',
  'caret-right',
  'caret-up',
  'caret-left-double',
  'caret-right-double',
  'chat',
  'chat-internal',
  'check',
  'closed-captions',
  'copy',
  'date-picker',
  'delete',
  'disallowed',
  'document',
  'download',
  'edit',
  'edit-user-action',
  'edit-list',
  'email',
  'error',
  'fast-forward',
  'feedback-dislike',
  'feedback-dislike-fill',
  'feedback-like',
  'feedback-like-fill',
  'filter',
  'full-screen',
  'gifted-shares',
  'help',
  'home',
  'indeterminate',
  'link-left',
  'link-right',
  'overflow-menu',
  'pause',
  'play',
  'plus',
  'pop-window',
  'print',
  'refresh',
  'remove',
  'reorder',
  'revert',
  'rewind',
  'search',
  'settings',
  'share',
  'subtract',
  'success',
  'time',
  'transact',
  'trash',
  'upload',
  'volume-off',
  'volume-on',
  'warning';

$medium-stroke: 'announcement',
  'bank',
  'caret-down',
  'caret-up',
  'chat-internal',
  'check',
  'date-picker',
  'disallowed',
  'document',
  'document-pdf',
  'download',
  'email',
  'error',
  'feedback-dislike',
  'feedback-like',
  'log-in',
  'log-out',
  'help',
  'play',
  'print',
  'remove',
  'search',
  'success',
  'upload',
  'user',
  'view-grid',
  'view-list',
  'warning';

$medium-fill: 'chat-internal-fill',
  'error-fill',
  'menu',
  'success-fill',
  'warning-fill',
  'feedback-dislike-fill',
  'feedback-like-fill';

@mixin icon-stroke-rules {
  use {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

%icon-stroke-rules {
  @include icon-stroke-rules;
}

@mixin icon-fill-rules {
  use {
    fill: currentColor;
  }
}
%icon-fill-rules {
  @include icon-fill-rules;
}

@mixin small-icon-rules {
  width: rem(14);
  height: rem(14);

  &.c11n-icon--smaller {
    width: rem($c11n-size-space-1x);
    height: rem($c11n-size-space-1x);
  }
}

%small-icon-rules {
  @include small-icon-rules;
}

@mixin small-icon-stroke-rules {
  use {
    stroke-width: rem($c11n-size-border-width-thick);
  }
}

%small-icon-stroke-rules {
  @include small-icon-stroke-rules;
}

@mixin medium-icon-rules {
  width: rem($c11n-size-space-3x);
  height: rem($c11n-size-space-3x);

  &.c11n-icon--larger {
    width: rem($c11n-size-space-6x);
    height: rem($c11n-size-space-6x);
  }
}

%medium-icon-rules {
  @include medium-icon-rules;
}

@mixin icon-child {
  @each $icon-name in $small {
    .c11n-#{$icon-name}-small {
      @extend %small-icon-rules;
    }
  }
  
  @each $icon-name in $small-fill {
    .c11n-#{$icon-name}-small {
      @extend %small-icon-rules;
      @extend %icon-fill-rules;
    }
  }
  
  @each $icon-name in $small-stroke {
    .c11n-#{$icon-name}-small {
      @extend %small-icon-rules;
      @extend %icon-stroke-rules;
      @extend %small-icon-stroke-rules;
    }
  }
  
  
  @each $icon-name in $medium-fill {
    .c11n-#{$icon-name}-medium {
      @extend %medium-icon-rules;
      @extend %icon-fill-rules;
    }
  }
  
  @each $icon-name in $medium-stroke {
    .c11n-#{$icon-name}-medium {
      @extend %medium-icon-rules;
      @extend %icon-stroke-rules;
    }
  }

  svg {
    display: block;
  }
  
  .c11n-sort-ascending-small,
  .c11n-sort-descending-small {
    use {
      fill: $c11n-color-vanguard-red-500;
    }
  }

  .c11n-positive-change-small {
    use {
      fill: $c11n-color-text-positive-on-light;
    }
  }

  .c11n-negative-change-small {
    use {
      fill: $c11n-color-text-negative-on-light;
    }
  }

  .c11n-time-small,
  .c11n-bookmark-unchecked-small {
    fill-rule: evenodd;
  }
}
