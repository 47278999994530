@import './skeleton-loader-mixins.scss';

.c11n-skeleton-loader {
  @include skeleton-loader-host;
  @include skeleton-loader-child;

  &.c11n-skeleton-loader--on-dark {
    @include skeleton-loader--on-dark;
  }

  &.c11n-skeleton-loader--radius {
    @include skeleton-loader--radius;
  }

  &.c11n-skeleton-loader--text-xs {
    @include skeleton-loader--text-xs;
  }

  &.c11n-skeleton-loader--text-sm {
    @include skeleton-loader--text-sm;
  }

  &.c11n-skeleton-loader--text-md {
    @include skeleton-loader--text-md;
  }

  &.c11n-skeleton-loader--text-lg {
    @include skeleton-loader--text-lg;
  }

  &.c11n-skeleton-loader--text-xl {
    @include skeleton-loader--text-xl;
  }

  &.c11n-skeleton-loader--text-2xl {
    @include skeleton-loader--text-2xl;
  }

  &.c11n-skeleton-loader--text-3xl {
    @include skeleton-loader--text-3xl;
  }

  &.c11n-skeleton-loader--text-4xl {
    @include skeleton-loader--text-4xl;
  }

  &.c11n-skeleton-loader--text-5xl {
    @include skeleton-loader--text-5xl;
  }

  &.c11n-skeleton-loader--text-6xl {
    @include skeleton-loader--text-6xl;
  }

  &.c11n-skeleton-loader--text-xs,
  &.c11n-skeleton-loader--text-sm,
  &.c11n-skeleton-loader--text-md,
  &.c11n-skeleton-loader--text-lg,
  &.c11n-skeleton-loader--text-xl,
  &.c11n-skeleton-loader--text-2xl,
  &.c11n-skeleton-loader--text-3xl,
  &.c11n-skeleton-loader--text-4xl,
  &.c11n-skeleton-loader--text-5xl,
  &.c11n-skeleton-loader--text-6xl {
    &:last-of-type {
      .c11n-skeleton-loader__container {
        margin-bottom: 0;
      }
    }
  }
}