@import './pagination-mixins.scss';

.c11n-pagination {
  @include pagination-host;
  @include pagination-child;

  @include activePageStyle();
  @include noTransitionStyle();
  @include rotatePreviousIcon();
  @include rotateLastPageIcon();
  @include pageNumberFontStyle();
}