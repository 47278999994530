@use "sass:map";
@import '../../variables';

@mixin drawer-host {
  --drawer-width: #{rem(480)};

  position: absolute;
  top: 0;
  height: 100%; 
  // overflow-x: hidden fallback for overflow-x: clip
  overflow-x: hidden; 
  overflow-x: clip;
  width: var(--drawer-width);
  pointer-events: none;
}

@mixin drawer-child {
  dialog {
    // override browser defaults start
    max-height: 100%;
    max-width: 100%;
    width: auto;
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    visibility: hidden;
    // override browser defaults end
    height: 100%;
    overflow: hidden;

    background-color: $c11n-color-ink-000;
    z-index: $c11n-elevation-z-index-1600;
    transform: translateX(var(--drawer-x-position-hidden));
    transition-property: transform, box-shadow, visibility;
    transition-timing-function: $animation-easing-decelerate-web;
    transition-duration: $animation-timing-collapse-large;
    transition-delay: 0ms, 0ms, $animation-timing-collapse-large;
    transform-style: flat;
    backface-visibility: hidden;

    &::backdrop {
      opacity: 0;
      background: $scrim-color;
      transition-property: opacity;
      transition-timing-function: $animation-easing-decelerate-web;
      transition-duration: $animation-timing-collapse-large;
      transform-style: flat;
      backface-visibility: hidden;
    }

    &[open] {
      visibility: visible;
      transition-property: transform, box-shadow;
    }
  }

  .c11n-drawer__close-btn {
    position: absolute;
    top: $c11n-size-space-2x;
    right: $c11n-size-space-2x; 
    // TODO: overriding min-width on ghost-variant
    ::ng-deep {
      .c11n-button__box {
        min-width: 0 !important;
      }
    }
  }

  .c11n-drawer__content {
    height: 100%;
    padding: rem($c11n-size-space-9x) rem($c11n-size-space-4x) 0 rem($c11n-size-space-4x);
  }

  .c11n-drawer__body {
    --focus-offset: calc(#{rem($c11n-size-space-0point5x)} + #{rem($c11n-size-border-width-thick)});

    height: 100%;
    padding: var(--focus-offset);
    margin: calc(var(--focus-offset) * -1);
    overflow: auto;
  }
}

@mixin drawer-open {
  pointer-events: auto;

  dialog {
    transform: translateX(0%);
    transition-duration: $animation-timing-expand-large;
    transition-timing-function: $animation-easing-accelerate-web;
    transition-delay: 0ms;


    &::backdrop {
      opacity: 1;
      transition-duration: $animation-timing-expand-large;
      transition-timing-function: $animation-easing-accelerate-web;
    }
  }

  &.c11n-drawer--modal dialog {
    box-shadow: map.get($c11n-box-shadow, 3200);
  }
}

@mixin drawer-modal {
  dialog {
    position: fixed;
    right: 0;
    min-height: 100vh;
    z-index: $c11n-elevation-z-index-3200;
    box-shadow: map.get($c11n-box-shadow, 0);
    width: var(--drawer-width);
  }
}

@mixin drawer-aligned-left {
  left: 0; 
  --drawer-x-position-hidden: -100%;

  &:not(.c11n-drawer--modal) dialog {
    border-right: solid rem($c11n-size-border-width-thin) $c11n-color-ink-400;
  }

  &.c11n-drawer--modal dialog {
    margin-right: auto;
  }
}

@mixin drawer-aligned-right {
  right: 0;
  --drawer-x-position-hidden: 100%;

  &:not(.c11n-drawer--modal) dialog {
    border-left: solid rem($c11n-size-border-width-thin) $c11n-color-ink-400;
  }

  &.c11n-drawer--modal dialog {
    margin-left: auto;
  }
}

@mixin drawer-sticky {
  dialog {
    overflow: visible;
  }

  .c11n-drawer__content {
    height: 100vh;
    position: sticky;
    top:0; 
  }
}