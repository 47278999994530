@import './search-mixins';

.c11n-search {
  @include search-host;
  @include search-child;

  &.c11n-search--cols2 {
    @include search--cols2;
  }
  &.c11n-search--cols3 {
    @include search--cols3;
  }
  &.c11n-search--large {
    @include search--large;
  }
  &.c11n-search--detached {
    @include search--detached;
  }
  &.c11n-search--attached {
    @include search--attached;
  }
  &.c11n-search--on-dark {
    @include search--on-dark;
  }
  &.c11n-search--on-dark.c11n-search--detached {
    @include search--on-dark-detached;
  }
  &.c11n-search--hide-label {
    @include search--hide-label;
  }
  &.c11n-search--hide-search-button {
    @include search--hide-search-button;
  }
  &.c11n-search--show-menu {
    @include search--show-menu;
  }
  &.c11n-search--above {
    @include search--above;
  }
  &.c11n-search--above.c11n-search--hide-label {
    @include search--above-hide-label;
  }
}
