@import './video-mixins';
@import '../graphic-pattern/';

.c11n-video {
  @include c11n-video-host;
  @include c11n-video-child;
  @include c11n-video-limelight-overrides;

  &.c11n-video--on-dark {
    @include c11n-video--on-dark;
  }

  &.c11n-video--centered-button {
    @include c11n-video--centered-button;
  }

  &.c11n-video--started {
    @include c11n-video--started;
  }

  &.c11n-video--16-9 {
    @include c11n-video--16-9;
  }
  
  &.c11n-video--4-3 {
    @include c11n-video--4-3;
  }
  
  &.c11n-video--1-1 {
    @include c11n-video--1-1;
  }
}
