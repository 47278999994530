// icon selectors

.icon {
  display: inline-block;
  background-image: url(../assets/portfolio_watch_icon_sprite.svg);
  background-repeat: no-repeat;
  vertical-align: middle;
}

.icon-large {
  height: 44px;
  width: 44px;
}

.icon-medium {
  height: 34px;
  width: 34px;
}

.icon-small {
  height: 30px;
  width: 30px;
}

.icon.icon-circle-1-no-fill {
  background-position: -0px -0px;
  height: 44px;
  width: 44px;
}

.icon.icon-circle-1-grey-fill {
  background-position: -45px -0px;
  height: 44px;
  width: 44px;
}

.icon.icon-circle-2-no-fill {
  background-position: -90px -0px;
  height: 44px;
  width: 44px;
}

.icon.icon-circle-2-grey-fill {
  background-position: -135px -0px;
  height: 44px;
  width: 44px;
}

.icon.icon-circle-check {
  background-position: -180px -0px;
  height: 44px;
  width: 44px;
}

.icon.icon-at {
  background-position: -0px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-phone {
  background-position: -35px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-printer {
  background-position: -70px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-plus {
  background-position: -110px -50px;
  height: 34px;
  width: 34px;
}

.icon.icon-info-large {
  background-image: url(../assets/vg_icon_info_lg.svg);
  height: 26px;
  width: 26px;
  margin-left: 10px;
}

.icon.icon-left-arrow-blue {
  background-position: -175px -45px;
  height: 34px;
  width: 34px;
}
.icon.icon-right-arrow-blue-no-BG {
  background-position: -210px -45px;
  height: 34px;
  width: 34px;
}
.icon.icon-right-arrow-blue {
  background-image: url(../assets/vg_icon_arrow_forward.svg);
  height: 36px;
  width: 36px;
  margin-left: 5px;
}

.icon.icon-down-arrow-blue {
  background-position: -245px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-up-arrow-blue {
  background-position: -280px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-down-arrow-red {
  background: url('../assets/vg_down_arrow_red.svg');
  height: 12px;
  width: 24px;
  transform: scale(1.5);
}

.icon.icon-up-arrow-red {
  background: url('../assets/vg_up_arrow_red.svg');
  height: 12px;
  width: 24px;
  transform: scale(1.5);
}

.icon.icon-left-arrow-white {
  background-position: -315px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-right-arrow-black {
  background-position: -350px -45px;
  height: 34px;
  width: 34px;
  transform: scale(0.75);
}

.icon.icon-down-arrow-white {
  background-position: -385px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-up-arrow-white {
  background-position: -420px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-x-light-grey {
  background-position: -455px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-x-dark-grey {
  background-position: -490px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-x-light-grey-circle {
  background-position: -525px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-x-dark-grey-circle {
  background-position: -560px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-box-light-grey {
  background-position: -595px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-box-dark-grey {
  background-position: -630px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-box-checked {
  background-position: -665px -45px;
  height: 34px;
  width: 34px;
}

.icon.icon-info-small {
  background-image: url(../assets/vg_icon_info_sm.svg);
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.icon.icon-faq {
  background-image: url(../assets/vg_icon_faq.svg);
  height: 26px;
  width: 26px;
  margin-left: 5px;
}

.icon.icon-faq-modernized {
  background-image: url(../assets/vg_icon_faq_modernized.svg);
  height: 24px;
  width: 24px;
}
.icon.icon-printer-modernized {
  background-image: url(../assets/printer-icon-modernized.svg);
  height: 22px;
  width: 24px;
}

.icon.icon-down-arrow-black {
  background-image: url(../assets/small_accordion-closed.svg);
  height: 20px;
  width: 26px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.icon.icon-up-arrow-black {
  background-image: url(../assets/small_accordion-closed.svg);
  transform: scaleY(-1);
  height: 20px;
  width: 26px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.icon.icon-download {
  background-image: url(../assets/medium_download_blue.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  margin-left: 5px;
  height: 20px;
  width: 20px;
}

.icon.icon-right-arrow-blue-modernized {
  background-image: url(../assets/small_link-right.svg);
  height: 18px;
  width: 18px;
  margin-left: 5px;
}

.icon.icon-left-arrow-blue-modernized {
  background-image: url(../assets/small_link-right.svg);
  height: 18px;
  width: 18px;
  margin-right: 5px;
  transform: rotateY(180deg);
}

.icon.icon-question-mark-black {
  background-image: url(../assets/small_help.svg);
  background-position: center;
  height: 18px;
  width: 18px;
  margin-top: 3px;
  margin-left: 10px;
  background-color: $vg-white;
  border: none;
  cursor: pointer;
}
