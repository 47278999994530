@import './sidebar-nav-mixins';

.c11n-sidebar-nav {
  
  @include sidebar-nav-host;
  @include sidebar-nav-optional-ul;

  .c11n-sidebar-nav__item {
    @include sidebar-nav-item-host;
  }
}