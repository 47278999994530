@import '../../variables';

@mixin spinner-host {
  display: block;
  width: auto;
  height: auto;
  --circleStrokeWidth: 7px;
  --spinnerSmall: #{ rem(18) };
  --spinnerMedium: #{ rem(60) };
  --spinnerLarge: #{ rem(166) };
}

@mixin spinner-child {

  svg {
    animation-name: svg-animation;
    animation-duration: 2s;
    animation-direction: normal;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-timing-function: linear;
  }

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  circle {
    stroke-width: var(--circleStrokeWidth);
    transform-origin: 0 0;
    fill: transparent;

    &:nth-child(1) {
      stroke: $c11n-color-ink-200;
      stroke-dashoffset: 0;
    }

    &:nth-child(2) {
      animation-name: circle-animation;
      animation-duration: 1.4s;
      animation-direction: normal;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      stroke: $c11n-color-red-600;
      stroke-linecap: square;
      stroke-dashoffset: 280;
      stroke-dasharray: 285;
    }
  }

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }

  @keyframes circle-animation-restart {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }
    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }

  .spinner-status {
    position: absolute;
    overflow: hidden;
    width: rem(1);
    height: rem(1);
    margin: rem(-1);
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
  }

  @media screen and (prefers-reduced-motion: reduce) {
    svg {
      animation-duration: 0s;
    }
    circle {
      &:nth-child(2) {
        animation-duration: 0s;
      }
    }
  }
}

@mixin spinner--dark {
  color: $white;
  circle {
    &:nth-child(1) {
      stroke: $c11n-color-ink-400;
    }
    &:nth-child(2) {
      stroke: $white;
    }
  }
}

@mixin spinner--small {
  --circleStrokeWidth: 15px;

  height: var(--spinnerSmall);
  width: var(--spinnerSmall);
  
  svg {
    max-width: var(--spinnerSmall);
    width: var(--spinnerSmall);
  }
}

@mixin spinner--medium {
  height: var(--spinnerMedium);
  width: var(--spinnerMedium);

  svg {
    max-width: var(--spinnerMedium);
    width: var(--spinnerMedium);
  }
}

@mixin spinner--large {
  --circleStrokeWidth: 3px;

  height: var(--spinnerLarge);
  width: var(--spinnerLarge);

  svg {
    max-width: var(--spinnerLarge);
    width: var(--spinnerLarge);
  }
}

@mixin spinner--restart {
  circle {
    &:nth-child(2) {
      animation-name: circle-animation-restart;
    }
  }
}