@use 'sass:math';
@import '../../variables';
@import '../menu/menu-mixins';
@import '../../icons/icons-mixins';

@mixin dropdown-host {
  --label-height: #{rem(7.5)};
  --label-margin: #{rem($c11n-size-space-1x)};
  --hint-height: #{rem($c11n-size-space-2x)};
  --hint-margin: #{rem($c11n-size-space-0point5x)};
  --trigger-height: #{rem($c11n-size-space-6x)};
  --clear-height: #{rem(22)};
  --independent-above-offset: #{0.25rem};
  --independent-multi-offset: #{0.125rem};
  position: relative;
  display: flex;
  line-height: normal;
}

@mixin dropdown-child {
  @include icon-child;
  button {
    border: 0;
    outline: rem(1) solid transparent;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .c11n-dropdown__container {
    display: grid;
  }

  .c11n-dropdown__label {
    display: flex;
    height: var(--label-height);
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 0 var(--label-margin) 0;
  }

  .c11n-dropdown__trigger {
    position: relative;
    background: $c11n-color-ink-000;
    height: var(--trigger-height);
    width: 100%;

    .c11n-dropdown__trigger-text {
      display: inline-block;
    }

    c11n-icon,
    .c11n-icon {
      color: $c11n-color-ink-800;
      pointer-events: none;
      svg[class*='c11n-caret-down'] {
        transition-property: transform;
        transition-timing-function: $animation-easing-standard-web;
        transition-duration: $animation-timing-collapse-small;
        transform-style: flat;
        backface-visibility: hidden;
        transform: scaleY(1);
      }
    }

    &[aria-expanded='true'] {
      c11n-icon,
      .c11n-icon {
        svg[class*='c11n-caret-down'] {
          transform: scaleY(-1);
          transition-duration: $animation-timing-expand-small;
        }
      }
      ~ .c11n-menu__menu {
        pointer-events: initial;
      }
    }
  }

  .c11n-dropdown__hint {
    height: var(--hint-height);
    margin-top: var(--hint-margin);

    &:empty {
      display: none;
      ~ .c11n-dropdown__menu {
        --hint-height: 0rem;
        --hint-margin: 0rem;
      }
    }
  }

  .c11n-dropdown__label,
  .c11n-dropdown__trigger,
  .c11n-dropdown__menu-item,
  .c11n-dropdown__hint {
    color: $c11n-color-ink-800;
  }

  .c11n-dropdown__menu {
    width: 100%;
    transition-property: opacity;
    transition-delay: 0ms;
    pointer-events: none;
    visibility: visible;
  }

  .c11n-dropdown__heading {
    @include c11n-font-rules('bold', 'sm');
    color: $c11n-color-ink-800;
    text-align: left;
    padding: rem($c11n-size-space-1point5x) rem($c11n-size-space-2x - 1)
      rem($c11n-size-space-2x - 2);
  }

  .c11n-dropdown__menu-item {
    display: block;
    text-align: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: relative;
    background: transparent;
    width: 100%;

    &:hover,
    &:focus-visible {
      background-color: $c11n-color-ink-100;
    }

    &[aria-checked='true'] {
      c11n-icon,
      .c11n-icon {
        svg.c11n-check-small {
          opacity: 1;
          transition-duration: $animation-timing-expand-small;
        }
      }
    }

    c11n-icon,
    .c11n-icon {
      position: absolute;
      top: 50%;
      // TODO: half diameter small icon token + 1
      margin-top: rem(-8);
      right: rem(15);

      svg.c11n-check-small {
        display: block;
        opacity: 0;
        transition-property: opacity;
        transition-timing-function: $animation-easing-standard-web;
        transition-duration: $animation-timing-collapse-small;
        transform-style: flat;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .c11n-dropdown__clear {
    position: absolute;
    box-sizing: border-box;
    right: rem($c11n-size-space-6x);
    top: 50%;
    top: calc(
      (var(--trigger-height) / 2) + var(--label-height) + var(--label-margin) +
        (var(--clear-height) / -2)
    );
    height: var(--clear-height);
    width: auto;
    padding: 0;

    button {
      border-radius: 0;
    }
  }

  .c11n-dropdown__group {
    @include c11n-menu-item-focus-styles();
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}

@mixin dropdown--start {
  justify-content: flex-start;
  .c11n-dropdown__menu {
    left: 0;
  }
}

@mixin dropdown--end {
  justify-content: flex-end;
  .c11n-dropdown__menu {
    right: 0;
  }
}

@mixin dropdown--medium {
  .c11n-dropdown__label {
    @include c11n-font-rules('regular', 'xs');
    @include c11n-text-crop-xs();
  }

  .c11n-dropdown__hint {
    @include c11n-font-rules('regular', 'xs');
  }

  .c11n-dropdown__trigger {
    .c11n-dropdown__trigger-text {
      @include c11n-font-rules('regular', 'md');
    }

    c11n-icon,
    .c11n-icon {
      svg.c11n-caret-down-small {
        // TODO: half diameter small icon token
        transform-origin: rem(7) rem(7);
      }
    }
  }

  .c11n-dropdown__trigger-text-selected {
    @include c11n-text-crop-md();
  }

  .c11n-dropdown__clear {
    @include c11n-font-rules('bold', 'sm');
  }
}

@mixin dropdown--large {
  --hint-margin: #{rem(2)};
  --label-height: #{rem(12)};
  --trigger-height: #{rem($c11n-size-space-8x)};
  --clear-height: #{rem(26)};

  .c11n-dropdown__label {
    @include c11n-font-rules('regular', 'md');
    @include c11n-text-crop-md();
  }

  .c11n-dropdown__hint {
    @include c11n-font-rules('regular', 'md');
  }

  .c11n-dropdown__trigger {
    .c11n-dropdown__trigger-text {
      @include c11n-font-rules('regular', 'lg');
    }

    c11n-icon,
    .c11n-icon {
      svg.c11n-caret-down-medium {
        // TODO: half diameter medium icon token
        transform-origin: rem(12) rem(12);
      }
    }
  }

  .c11n-dropdown__trigger-text-selected {
    @include c11n-text-crop-lg();
  }

  .c11n-dropdown__clear {
    right: rem($c11n-size-space-8x);
    @include c11n-font-rules('bold', 'md');
  }
}

@mixin dropdown--untouched {
  .c11n-dropdown__trigger {
    color: $c11n-color-ink-400;
  }
}

@mixin dropdown--hide-label {
  --label-height: 0rem;
  --label-margin: 0rem;
  .c11n-dropdown__label {
    display: none;
  }
}

@mixin dropdown--basic--basic-multi--independent {
  .c11n-dropdown__trigger {
    @include c11n-focus-outline-block-after();
  }
}

@mixin dropdown--basic--basic-multi {
  display: block;
  width: 100%;

  &.c11n-dropdown--medium {
    .c11n-dropdown__trigger {
      padding: rem($c11n-size-space-1point5x - 2) rem($c11n-size-space-6x)
        rem($c11n-size-space-1point5x) rem($c11n-size-space-2x);
    }
  }

  &.c11n-dropdown--large {
    .c11n-dropdown__trigger {
      padding: rem($c11n-size-space-2x - 2) rem($c11n-size-space-8x)
        rem($c11n-size-space-2x) rem($c11n-size-space-2x);
    }
  }

  .c11n-dropdown__trigger {
    box-shadow: inset 0 0 0 rem($c11n-size-border-width-thin)
      $c11n-color-ink-800;
    outline: rem(1) solid transparent;

    .c11n-dropdown__trigger-text {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    c11n-icon,
    .c11n-icon {
      svg[class*='c11n-caret-down'] {
        display: block;
        position: absolute;
        top: 50%;
        &.c11n-caret-down-small {
          // TODO: half diameter small icon token
          margin-top: rem(-7);
          right: rem(17);
        }
        &.c11n-caret-down-medium {
          // TODO: half diameter medium icon token
          margin-top: rem(-12);
          right: rem(20);
        }
      }
    }
  }
}

@mixin dropdown--independent {
  --trigger-height: #{rem($c11n-size-line-height-md) +
    $c11n-text-md-normal-underline-padding};

  .c11n-dropdown__trigger {
    margin-bottom: rem($c11n-size-space-0point5x);
    border-radius: 0;
    line-height: normal;
    background: transparent;
    @include c11n-link-underline-rules('.c11n-dropdown__trigger-text');

    > * {
      vertical-align: middle;
    }

    c11n-icon,
    .c11n-icon {
      display: inline-block;
      padding-top: 0;
      svg[class*='c11n-caret-down'] {
        display: block;
        margin-left: rem($c11n-size-space-1x);
      }
    }
  }

  &.c11n-dropdown--medium {
    .c11n-dropdown__trigger-text {
      padding-bottom: $c11n-text-md-normal-underline-padding;
    }
  }

  &.c11n-dropdown--large {
    --trigger-height: #{rem(34)};

    .c11n-dropdown__trigger-text {
      padding-bottom: $c11n-text-lg-normal-underline-padding;
    }
  }
}

@mixin dropdown--below {
  .c11n-dropdown__menu {
    top: calc(100% - #{var(--hint-height)} - #{var(--hint-margin)});
  }
}

@mixin dropdown--above {
  .c11n-dropdown__menu {
    bottom: calc(100% - #{var(--label-height)} - #{var(--label-margin)});
  }
}

@mixin dropdown--above-independent {
  .c11n-dropdown__menu {
    bottom: calc(
      100% - #{var(--label-height)} - #{var(--label-margin)} + #{var(
          --independent-above-offset
        )}
    );
  }
}

@mixin dropdown--above-independent--above-independent-multi {
  .c11n-dropdown__menu {
    bottom: calc(
      100% - #{var(--label-height)} - #{var(--label-margin)} + #{var(
          --independent-multi-offset
        )}
    );
  }
}

@mixin dropdown--below-independent--below-independent-multi {
  .c11n-dropdown__menu {
    top: calc(
      100% - #{var(--hint-height)} - #{var(--hint-margin)} + #{var(
          --independent-multi-offset
        )}
    );
  }
}

@mixin dropdown--basic-multi {
  .c11n-dropdown__trigger {
    .c11n-dropdown__trigger-text {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: $c11n-size-space-1x;
    }
  }
}

@mixin dropdown--independent-multi {
  --trigger-height: 2.25rem;
  --label-height: 0rem;
  --label-margin: 0rem;

  .c11n-dropdown__label {
    display: none;
  }

  .c11n-dropdown__trigger {
    margin-bottom: 0;
    @include c11n-link-underline-rules('.c11n-dropdown__trigger-text-selected');

    &:hover,
    &:focus {
      .c11n-dropdown__trigger-text {
        c11n-icon,
        .c11n-icon {
          background: $white;
          svg {
            use {
              fill: $black;
            }
          }
          @include c11n-hover-block-hover-style();
        }
      }
    }

    &:focus {
      outline: none;
      c11n-icon,
      .c11n-icon {
        @include c11n-focus-outline-block-after-rules();
      }
    }

    &[aria-expanded='true'] {
      .c11n-dropdown__trigger-text {
        c11n-icon,
        .c11n-icon {
          background: $black;
          svg {
            use {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .c11n-dropdown__trigger {
    .c11n-dropdown__trigger-text {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: rem($c11n-size-space-0point5x);
      color: $c11n-color-ink-800;
      height: 100%;

      &-selected {
        display: flex;
        height: auto;
        @include c11n-font-rules('bold', 'md');
        @include c11n-text-crop-md();
        padding-top: $c11n-text-md-bold-underline-padding;
        padding-bottom: $c11n-text-md-bold-underline-padding;
      }

      .c11n-badge {
        margin: rem(2) 0 0 0;
      }

      c11n-icon,
      .c11n-icon {
        position: relative;
        cursor: default;
        display: inline-block;
        width: rem($c11n-size-space-3x);
        height: rem($c11n-size-space-3x);
        box-sizing: border-box;
        padding: 0 rem(5);
        margin: 0 rem($c11n-size-space-0point5x) 0 0;
        border-radius: 50%;
        background-color: transparent;
        @include c11n-hover-block-base-styles();

        svg {
          position: relative;
          pointer-events: none;
          display: inline;
          use {
            transition-property: fill;
            transition-timing-function: $animation-easing-standard-web;
            transition-duration: $animation-timing-collapse-small;
            transform-style: flat;
            backface-visibility: hidden;
            transform: translate3d(0, 0, 0);
          }
        }
      }
    }
  }
}

@mixin dropdown--end-independent-multi {
  .c11n-dropdown__trigger {
    .c11n-dropdown__trigger-text {
      flex-direction: row-reverse;
      c11n-icon,
      .c11n-icon {
        margin: 0 0 0 rem($c11n-size-space-0point5x);
      }
    }
  }
}

@mixin dropdown--hide-label-independent-multi {
  .c11n-dropdown__trigger-text {
    .c11n-dropdown__trigger-text-selected {
      display: none;
    }
  }
}

@mixin dropdown--basic--independent {
  .c11n-dropdown__menu-item.c11n-menu__item {
    padding-right: rem($c11n-size-space-6x - $c11n-size-space-0point5x);
  }
}

@mixin dropdown--basic-multi--independent-multi {
  .c11n-dropdown__menu-item {
    padding-left: rem($c11n-size-space-6x - $c11n-size-space-0point5x);

    c11n-icon,
    .c11n-icon {
      outline: rem(1) solid $black;
      position: absolute;
      top: 50%;
      width: rem(14);
      height: rem(14);
      background: $white;
      // TODO: half diameter small icon token + 1
      margin-top: rem(-8);
      left: rem($c11n-size-space-2x);

      svg.c11n-check-small {
        display: block;
        opacity: 0;
        transition-property: opacity;
        transition-timing-function: $animation-easing-standard-web;
        transition-duration: $animation-timing-collapse-small;
        transform-style: flat;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
    }

    &[aria-checked='true'] {
      c11n-icon,
      .c11n-icon {
        background: $black;
        svg.c11n-check-small {
          opacity: 1;
          transition-duration: $animation-timing-expand-small;
          use {
            fill: $white;
          }
        }
      }
    }
  }
}
