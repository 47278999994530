@import './badge-mixins';

.c11n-badge {

  @include badge-host;
  @include badge-child;
  
  &.c11n-badge--medium {
    @include badge--medium;
  }
  
  &.c11n-badge--item-counter-on-light {
    @include badge--item-counter-on-light;
  }

  &.c11n-badge--item-counter-on-dark {
    @include badge--item-counter-on-dark;
  }
    
  &.c11n-badge--ink-100 {
    @include badge--border-color-ink-100;
  }
  
  &.c11n-badge--ink-800 {
    @include badge--border-color-ink-800;
  }
  
  &.c11n-badge--red-100 {
    @include badge--border-color-red-100;
  }
  
  &.c11n-badge--yellow-100 {
    @include badge--border-color-yellow-100;
  }
  
  &.c11n-badge--turquoise-100 {
    @include badge--border-color-turquoise-100;
  }
}
