@import '../../variables';

@mixin accordion-host {
  --border-width: #{rem($c11n-size-border-width-thin)};
  $border-color: $c11n-color-ink-300;
  background: transparent;
  display: block;
  overflow: visible;
  margin: 0;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  border: 0 none;
  border-bottom: rem($c11n-size-border-width-thin) solid $border-color;
  outline: rem(1) solid transparent;
  z-index: 0;

  &:focus-within {
    z-index: 1;
  }

  * {
    box-sizing: border-box;
  }
}

@mixin accordion-child {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    color: $black;
  }

  .c11n-accordion__heading {
    color: $black;
  }

  .c11n-accordion__heading-group {
    display: flex;
    flex-grow: 1;
    gap: rem($c11n-size-space-2x);
    justify-content: space-between;
  }

  .c11n-accordion__heading {
    margin: 0 rem($c11n-size-space-1x) 0 0;
    text-align: left;
    text-decoration: none;
    background: $white;
  }

  .c11n-accordion__trigger {
    @include c11n-focus-outline-block-after();
    // Accounting for extra spacing coming from bottom border
    &:focus-visible::after {
      height: calc(100% + var(--border-width));
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    cursor: default;
    left: 0;
    width: 100%;
    padding: rem($c11n-size-space-3x - 2) 0;
    margin: 0;
    background: $white;
    color: $black;
    border: 0 none;
    @include c11n-link-underline-rules('.c11n-accordion__heading span');

    &-items {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 100%;
      background: $white;
      color: $black;
    }

    &[aria-expanded='true'] {
      .c11n-accordion__icon {
        transform: scaleY(-1);
        transition-duration: $animation-timing-expand-medium;
      }
    }
  }

  .c11n-accordion__content {
    display: inline-block;
    min-width: rem(14);
    color: $black;
  }

  .c11n-accordion__icon {
    padding: 0;
    display: flex;
    transform: scaleY(1);
    transition-property: transform;
    transition-duration: $animation-timing-collapse-medium;
    transition-timing-function: $animation-easing-standard-web;
    backface-visibility: hidden;
    overflow: visible;
    border-radius: 0;
  }

  .c11n-accordion__body {
    --accordionHeight: auto;
    transition-property: opacity, visibility, height;
    transition-duration: $animation-timing-collapse-medium;
    transition-timing-function: $animation-easing-standard-web;
    transform-style: flat;
    backface-visibility: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    background: $white;

    &.transitioning-out {
      height: 0 !important;
      visibility: visible;
    }
  }

  .c11n-accordion__icon-container {
    border-radius: 50%;
    background-color: $c11n-color-ink-800;
    height: rem(14);
    .c11n-accordion__icon {
      margin: 0;
    }
  }
}

@mixin accordion--leading {
  .c11n-accordion__trigger-items {
    flex-direction: row-reverse;
  }
}

@mixin accordion--summary {
  .c11n-accordion__heading-group {
    flex-direction: column;
  }

  .c11n-accordion__content {
    display: inline-block;
    @include c11n-font-rules('normal', 'lg');
    @include c11n-text-crop-lg();
    padding: 0;
    margin: 0;
    min-width: calc(100% - 1.5rem);
    text-align: left;
  }

  @media screen and (min-width: rem($c11n-grid-breakpoint-sm)) {
    .c11n-accordion__content {
      padding: 0;
      margin: 0 0 0 rem(28);
      width: fit-content;
      min-width: auto;
      text-align: right;
    }

    .c11n-accordion__heading-group {
      flex-direction: row;
    }
  }
}

@mixin accordion--medium {
  display: grid;
  $border-color: $c11n-color-ink-300;
  .c11n-accordion__trigger {
    background: linear-gradient(
      to bottom,
      $border-color 0,
      $border-color rem($c11n-size-border-width-thin),
      $white rem($c11n-size-border-width-thin),
      $white 100%
    );
  }
  .c11n-accordion__content {
    display: inline-block;
    @include c11n-font-rules('normal', 'md');
    @include c11n-text-crop-md();
  }
}

@mixin accordion--medium--normal {
  .c11n-accordion__heading {
    @include c11n-font-rules('normal', 'md');
    @include c11n-text-crop-md();
    span {
      padding-bottom: $c11n-text-md-normal-underline-padding;
    }
  }
}

@mixin accordion--medium--bold {
  .c11n-accordion__heading {
    @include c11n-font-rules('bold', 'md');
    @include c11n-text-crop-md();
    span {
      padding-bottom: $c11n-text-md-bold-underline-padding;
    }
  }
}

@mixin accordion--medium--leading {
  .c11n-accordion__icon {
    margin: 0 rem($c11n-size-space-1point5x) 0 rem(1);
  }
}

@mixin accordion--medium--trailing {
  .c11n-accordion__icon {
    margin: 0 rem(1) rem(1) rem($c11n-size-space-1point5x);
  }
}

@mixin accordion--large {
  $border-color: $c11n-color-ink-300;
  .c11n-accordion__trigger {
    padding: rem($c11n-size-space-4x - 1) 0 rem($c11n-size-space-4x - 2) 0;
    background: linear-gradient(
      to bottom,
      $border-color 0,
      $border-color rem($c11n-size-border-width-thick),
      transparent rem($c11n-size-border-width-thick),
      transparent 100%
    );
  }
  .c11n-accordion__heading-group {
    min-height: rem(18);
  }
  .c11n-accordion__heading {
    padding: 0;
  }
  .c11n-accordion__icon {
    color: $white;
    border-radius: 50%;
    outline: rem(1) solid transparent;
    outline-offset: rem(2);
  }
}

@mixin accordion--large--bold {
  .c11n-accordion__heading {
    @include c11n-font-rules('bold', 'lg');
    @include c11n-text-crop-lg-bold();
    span {
      padding-bottom: $c11n-text-lg-bold-underline-padding;
    }
  }
}

@mixin accordion--large--heavy {
  .c11n-accordion__heading {
    @include c11n-font-rules('heavy', 'lg');
    @include c11n-text-crop-lg();
    span {
      padding-bottom: $c11n-text-lg-heavy-underline-padding;
    }
  }
}

@mixin accordion--large--leading {
  .c11n-accordion__icon-container {
    margin: rem(2) rem($c11n-size-space-1point5x) 0 rem(1);
  }
}

@mixin accordion--large--trailing {
  .c11n-accordion__icon-container {
    margin: rem(2) rem(1) 0 rem($c11n-size-space-1point5x);
  }
}

@mixin accordion--bold {
  $border-color: $c11n-color-ink-300;
  .c11n-accordion__trigger {
    background: linear-gradient(
      to bottom,
      $border-color 0,
      $border-color rem($c11n-size-border-width-thick),
      $white rem($c11n-size-border-width-thick),
      $white 100%
    );
  }
}

@mixin accordion--expanded {
  .c11n-accordion__body {
    height: auto;
    visibility: visible;
    transition-duration: $animation-timing-expand-medium;
    opacity: 1;
  }
}

@mixin accordion-icon {
  svg {
    fill: currentColor;
    width: rem(14);
    height: rem(14);
  }
}

@mixin accordion-icon-white {
  .c11n-accordion-closed-caret-small,
  .c11n-accordion-open-caret-small {
    use {
      stroke: $c11n-color-ink-000;
    }
  }
}

@mixin accordion-group-host {
  display: block;
  $border-color: $c11n-color-ink-300;
  border: 0 none;
  padding: 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
  background: linear-gradient(
    to top,
    $border-color 0,
    $border-color rem(1),
    $white rem(1),
    $white 100%
  );
}

@mixin accordion-group__accordion {
  border-bottom: rem($c11n-size-border-width-thin) solid transparent;

  &.c11n-accordion--medium:not(:last-of-type) {
    --border-width: #{rem($c11n-size-border-width-thin + 1)};
  }

  &.c11n-accordion--large:not(:last-of-type) {
    --border-width: #{rem($c11n-size-border-width-thick + 1)};
  }
}

@mixin accordion-group__accordion--margin-none {
  margin-bottom: 0;
}

@mixin accordion-group__accordion--margin-medium {
  margin-bottom: rem($c11n-size-space-6x);
}

@mixin accordion-group__accordion--margin-large {
  margin-bottom: rem($c11n-size-space-8x);
}

@mixin accordion-group__accordion--last-of-type {
  margin-bottom: 0;
}

@mixin accordion--with-animation-expanded {
  .c11n-accordion__body {
    height: var(--accordionHeight);
    overflow: hidden;
    animation: toggleAccordionExpand $animation-timing-expand-medium $animation-easing-standard-web forwards;
  }
}

@mixin accordion--with-animation-collapsed {
  .c11n-accordion__body {
    height: var(--accordionHeight);
    overflow: visible;
    animation: toggleAccordionCollapse $animation-timing-collapse-medium $animation-easing-standard-web forwards;
  }
}

@keyframes toggleAccordionExpand {
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

@keyframes toggleAccordionCollapse {
  0% {
    overflow: visible;
  }
  1% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}