@import '../../variables';

@mixin c11n-hint-error-default {
  display: grid;
  position: relative;
  height: auto;
  width: auto;
  gap: rem($c11n-size-space-0point5x);
  padding: 0;
  margin: rem($c11n-size-space-1x) 0 0 0;
  color: $default-color;
  line-height: 0;
  z-index: $c11n-elevation-z-index-100;
}

@mixin c11n-hint-error-child {
  .c11n-hint-error__content {
    display: block;
    padding: 0;
    margin: 0;
    @include c11n-font-rules('regular','xs');
    @include c11n-text-crop-xs();
  }

  .c11n-hint-error__icon {
    position: absolute;
    left: 0;
    top: rem(-4);
    color: $error-color;
  }
}

@mixin c11n-hint-error-large-child {
  .c11n-hint-error__content {
    @include c11n-font-rules('regular','md');
    @include c11n-text-crop-md();
  }
}

@mixin c11n-hint-error-error-child {
  .c11n-hint-error__content {
    padding-left: rem(18);
    padding-top: 0;
    margin: 0;
    color: $error-color;
  }
}

@mixin c11n-hint-error-error-large-child {
  .c11n-hint-error__content {
    padding-top: 0;
  }
  .c11n-hint-error__icon {
    top: rem(-1);
  }
}
