@import './navigation-mixins.scss';

.c11n-navigation {
  @include navigation-host;
  @include navigation-child;

  &.c11n-navigation--initializing {
    @include navigation--initializing;
  }

  &.c11n-navigation--small-responsive {
    @include navigation--small-responsive;
  }

  &.c11n-navigation--equal-width {
    @include navigation--equal-width;
  }
}
