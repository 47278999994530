@import '../../variables';

@mixin internal-read-aloud-child {
  position: relative;
  display: block;
  padding-left: rem($c11n-size-space-5x);

  [data-container="c11n-icon-container"] {
    position: absolute;
    top: 0;
    left: 0;
  }

  .c11n-internal-read-aloud__heading {
    @include c11n-font-rules('bold','md');
    margin-bottom: rem($c11n-size-space-2x);
  }
}
