@import './card-mixins.scss';

.c11n-card {
  @include card-host;
  @include card--image-aspect-ratio;

  &.c11n-card--density-small {
    @include card--density-small;
  }

  &.c11n-card--density-medium {
    @include card--density-medium;
  }

  &.c11n-card--density-large {
    @include card--density-large;
  }

  // TODO: In v2.0 we need to remove 'bottom'
  &.c11n-card--image-bottom,
  &.c11n-card--image-below {
    @include card--image-below;
  }

  &.c11n-card--content-center {
    @include card--content-center;
  }

  &.c11n-card--color-yellow {
    @include card--color-yellow;
  }

  &.c11n-card--color-red {
    @include card--color-red;
  }

  &.c11n-card--color-turquoise {
    @include card--color-turquoise;
  }

  &.c11n-card--border {
    @include card--border;
  }

  &.c11n-card--clickable {
    @include card--clickable;
    @include card--link-pseudo-click;
  }

  &.c11n-card--image-4-3 {
    @include card--image-4-3;
  }

  &.c11n-card--image-1-1 {
    @include card--image-1-1;
  }

  &.c11n-card--image-5-4 {
    @include card--image-5-4;
  }

  &.c11n-card--image-3-2 {
    @include card--image-3-2;
  }

  &.c11n-card--image-16-9 {
    @include card--image-16-9;
  }

  &.c11n-card--image-2-1 {
    @include card--image-2-1;
  }

  &.c11n-card--icon-above-center {
    @include card--icon-above-center;
  }

  &.c11n-card--icon-left-start {
    @include card--icon-left-start;
  }

  &.c11n-card--icon-left-center {
    @include card--icon-left-center;
  }
}

a.c11n-card {
  @include card--link;
}
