@import './date-picker-mixins';

.c11n-date-picker {

  @include date-picker-host;
  @include date-picker-child;

  .c11n-date-picker__box__input {
    @include date-picker-input-host;
  }

  &:focus-within {
    @include date-picker--focus-within;
  }

  &.c11n-date-picker--error {
    @include date-picker--error;
  }

  &.c11n-date-picker--large {
    @include date-picker--large-child;
  }

  &.c11n-date-picker--medium {
    .c11n-date-picker__box__input {
      @include date-picker--medium;
    }
  }

  &.c11n-date-picker--large {
    .c11n-date-picker__box__input {
      @include date-picker--large;
      @include date-picker-input-host--large;
    }
  }
}
