@import './chip-mixins.scss';

.c11n-chip-group {
  @include chip-group-host;
}

.c11n-chip {
  @include chip-host;
  @include chip-child;

  &.c11n-chip--yellow {
    @include chip--yellow;
  }

  &.c11n-chip--ink {
    @include chip--ink;
  }

  &.c11n-chip--internal {
    @include chip--internal;
  }

  &.c11n-chip--medium {
    @include chip--medium;
  }

  &.c11n-chip--removed {
    @include chip--removed;
  }

  &.c11n-chip--removable {
    @include chip--removable;
  }
}
