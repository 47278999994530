
.mat-menu-panel {
  max-width: none !important;
}

#rt-drawer {
  background-color:#fff;
  
  .mat-ripple {
    overflow: hidden;
  }
}

#rt-drawer-container {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color : #0009
  }
}