@import '../../variables';

@mixin c11n-video-host {

  --width: 16;
  --height: 9;

  display: block;
  position: relative;
  height: 0;
  padding-top: calc(var(--height)/var(--width) * 100%);
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 1080'%3E%3Cpath d='M1920 0H0V1080H1920V0Z' fill='%23660026'/%3E%3Cpath d='M1703.24 1080L1187.63 123.23c-20.34-39.67-42.09-68.98-65.25-87.91-23.52-19.1-51.86-31.33-81.9-35.32h-411.23V1080H1703.24Z' fill='%23c10029'/%3E%3Cpath d='M1290.75 1080L775.12 123.23c-20.34-39.67-42.09-68.98-65.25-87.91-23.51-19.1-51.85-31.33-81.88-35.32H216.76V1080H1290.75Z' fill='%23f6001e'/%3E%3Cpath d='M878.26 1080L362.62 123.23c-20.34-39.67-42.09-68.98-65.25-87.91C273.85 16.22 245.51 4 215.47 0H0V1080H878.26Z' fill='%23ff5050'/%3E%3C/svg%3E");

  &:hover {
    .c11n-video__player::after,
    .c11n-video__play-button,
    .c11n-video__play-button::before {
      transition-duration: $animation-timing-expand-medium;
    }
  }

  &:focus-within {

    .c11n-video__player::after,
    .c11n-video__play-button,
    .c11n-video__play-button::before { 
      transition-property: none;
    }

    @include c11n-focus-outline-sibling(
      '.c11n-video__play-button',
      '.c11n-video__focus-element',
      $width: 100%,
      $height: 100%,
      $top: 0,
      $left: 0
    );
  }

  &:hover,
  &:focus-within {

    .c11n-video__player::after {
      opacity: 1;
    }

    .c11n-video__play-button {
      &::before {
        border-color: $c11n-color-ink-800;
        background-color: $c11n-color-ink-000;
        width: rem(80);
        height: rem(80);
      }
      color: $c11n-color-ink-800;
    }
  }
}

@mixin c11n-video--started {
  .limelight-player {
    visibility: visible;
    ~ .c11n-video__player {
      display: none;
    }
  }
}

@mixin c11n-video-limelight-overrides {
  .c11n-video__player,
  .limelight-player {
    display: block;
    // !important necessary to override inline relative position injected by limelight player script
    position: absolute !important;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .c11n-video__poster {
    display: block;
    width: 100%;
    object-fit: cover;
    aspect-ratio: var(--width) / var(--height);
  }

  .limelight-player {

    visibility: hidden;
    
    .vjs-limelight-big-play {
      z-index: auto;
    }
  }
}

@mixin c11n-video-child {
  .c11n-video__player {
    &::after {
      content: '';
      display: block;
      background-color: $scrim-color;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0;
      transition-property: opacity;
      transition-duration: $animation-timing-collapse-medium;
      transition-timing-function: $animation-easing-standard-web;
    }
  }

  .c11n-video__play-button {

    display: flex;
    align-items: center;
    justify-content: center;
    color: $c11n-color-ink-000;
    background: transparent;
    border: 0;
    transition-property: color;
    transition-duration: $animation-timing-collapse-medium;
    transition-timing-function: $animation-easing-standard-web;
    box-sizing: border-box;
    outline: rem(1) solid transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 1;
    left: rem($c11n-size-space-5x);
    bottom: rem($c11n-size-space-5x);
    width: rem($c11n-component-button-height-large);
    height: rem($c11n-component-button-height-large);

    svg {
      position: relative;
      /* 3.3604 is the value to offset the center to place the centroid of the triangle in the center */
      transform: translateX(rem(3.3604));
    }

    &::before {
      content: '';
      display: block;
      width: rem($c11n-component-button-height-large);
      height: rem($c11n-component-button-height-large);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: rem($c11n-size-space-16x);
      background-color: $c11n-color-ink-800;
      border-color: $c11n-color-ink-000;
      border-style: solid;
      border-width: rem($c11n-size-border-width-thick);
      transition-property: background-color, border-color, width, height;
      transition-duration: $animation-timing-collapse-medium;
      transition-timing-function: $animation-easing-standard-web;
    }
  }
}

@mixin c11n-video--centered-button {
  .c11n-video__play-button {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

@mixin c11n-video--on-dark {
  
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 1080'%3E%3Cpath d='M1920 0H0V1080H1920V0Z' fill='%23ff5050'/%3E%3Cpath d='M1703.24 1080L1187.63 123.23c-20.34-39.67-42.09-68.98-65.25-87.91-23.52-19.1-51.86-31.33-81.9-35.32h-411.23V1080H1703.24Z' fill='%23f6001e'/%3E%3Cpath class='cls-4' d='M1290.75 1080L775.12 123.23c-20.34-39.67-42.09-68.98-65.25-87.91-23.51-19.1-51.85-31.33-81.88-35.32H216.76V1080H1290.75Z' fill='%23c10029'/%3E%3Cpath d='M878.26 1080L362.62 123.23c-20.34-39.67-42.09-68.98-65.25-87.91C273.85 16.22 245.51 4 215.47 0H0V1080H878.26Z' fill='%23660026'/%3E%3C/svg%3E");

  .c11n-video__play-button {
    color: $c11n-color-ink-800;
    &::before {
      background-color: $c11n-color-ink-000;
      border-color: $c11n-color-ink-800;
    }
  }

  &:hover,
  &:focus-within {
    .c11n-video__play-button {
      color: $c11n-color-ink-000;
      &::before {
        background-color: $c11n-color-ink-800;
        border-color: $c11n-color-ink-000;
      }
    }
  }
}

@mixin c11n-video--16-9 {
  --width: 16;
  --height: 9;
}

@mixin c11n-video--4-3 {
  --width: 4;
  --height: 3;
}

@mixin c11n-video--1-1 {
  --width: 1;
  --height: 1;
}