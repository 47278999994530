@import '../../variables';

@mixin banner-host {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-800;
  border-left: rem(4) solid $c11n-color-ink-800;
  position: relative;
  flex-direction: row;
  @include c11n-font-rules('regular', 'md');
  padding: rem($c11n-size-space-2x);
}

@mixin banner-child {
  .c11n-banner__content-header {
    width: 100%;
    @include c11n-font-rules('bold', 'md');
    @include c11n-text-crop-md();
    color: $c11n-color-ink-800;
    text-align: left;
    padding: 0 rem($c11n-size-space-4x) 0 0;
  }

  .c11n-banner__leading-icon {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: $c11n-color-ink-800;
  }

  .c11n-banner__close-icon {
    position: absolute;
    right: rem($c11n-size-space-1x - 1);
    top: rem($c11n-size-space-1x - 1);
    color: $c11n-color-ink-400;
  }

  .c11n-banner__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .c11n-banner__body {
    transition-property: visibility, max-height;
    transition-duration: $animation-timing-expand-medium;
    transition-timing-function: $animation-easing-standard-web;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    padding: 0;
    margin-top: rem($c11n-size-space-2x);
    color: $c11n-color-ink-800;

    &:empty {
      margin-top: 0;
    }
  }

  .c11n-banner__main {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    align-self: center;

    .c11n-banner__content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    details,
    .c11n-details,
    c11n-details {
      margin: rem(-3) 0 rem(-2) 0;
    }
  }

  .c11n-banner__body {
    @include c11n-font-rules('regular', 'md');
  }
}

@mixin banner--medium--small {
  .c11n-banner__leading-icon {
    width: fit-content;
    padding-right: rem($c11n-size-space-1x);
  }
}

@mixin banner--large {
  padding: rem($c11n-size-space-3x);
  .c11n-banner__main {
    padding-left: rem($c11n-size-space-1x);
    .c11n-banner__content-header {
      @include c11n-font-rules('bold', 'lg');
      @include c11n-text-crop-lg();
    }
  }
  .c11n-banner__leading-icon {
    padding-right: rem($c11n-size-space-2x);
    margin-top: rem(-2);
  }
}

@mixin banner--small {
  .c11n-banner {
    justify-content: center;
  }

  .c11n-banner__body {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: rem($c11n-size-space-4x);
  }

  .c11n-banner__content--no-close {
    .c11n-banner__body {
      margin-right: 0;
    }
  }
}

@mixin banner--is-expandable {
  .c11n-banner__content--collapsed + .c11n-banner__body {
    box-sizing: border-box;
    height: auto;
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    margin-bottom: 0;
  }
  .c11n-banner__content--expanded + .c11n-banner__body {
    height: auto;
    max-height: rem(1000);
    visibility: visible;
    opacity: 1;
    margin-bottom: rem($c11n-size-space-1point5x);
  }
}

@mixin banner--is-expandable--medium {
  padding-bottom: rem($c11n-size-space-1x);
  .c11n-banner__trailing-icon {
    top: rem($c11n-size-space-1point5x);
  }
}

@mixin banner--is-expandable--large {
  padding-bottom: rem($c11n-size-space-2x);
  .c11n-banner__trailing-icon {
    top: rem($c11n-size-space-2x);
  }
}

@mixin banner--error--warning {
  border-left-color: $error-color;
  .c11n-banner__leading-icon {
    color: $error-color;
  }
  .c11n-banner__main {
    padding-left: 0;
  }
  .c11n-banner__leading-icon {
    color: $error-color;
  }
}

@mixin banner--success {
  border-left-color: $success-color;
  .c11n-banner__main {
    padding-left: 0;
  }
  .c11n-banner__leading-icon {
    color: $success-color;
  }
}

@mixin banner--basic-plus-icon {
  .c11n-banner__main {
    padding-left: 0;
  }
}

@mixin banner--basic {
  .c11n-banner__leading-icon {
    display: none;
  }
}

@mixin banner--closed {
  display: none;
}