@import '../../variables';

@mixin card-host() {
  position: relative;
  display: flex;
  flex-direction: column;
  background: $c11n-color-ink-000;
  box-shadow: $c11n-elevation-box-shadow-100;
  outline: 1px solid transparent;

  .c11n-card__eyebrow {
    color: $c11n-color-ink-400;
  }

  .c11n-card__icon-container {
    display: flex;
  }

  .c11n-card__icon-and-content {
    display: flex;
    flex-direction: column;
  }

  .c11n-card__heading:not(:empty) {
    margin-bottom: rem($c11n-size-space-2x);
  }
}

@mixin card--link() {
  display: block;
  color: inherit;
  transition-property: box-shadow;
  transition-duration: $animation-timing-collapse-large;
  transition-timing-function: $animation-easing-standard-web;
  transform-style: flat;
  
  @include c11n-focus-outline-block-after();
  &:hover,
  &:focus {
    box-shadow: $c11n-elevation-box-shadow-800;
    transition-duration: $animation-timing-expand-large;
  }
}

@mixin card--image-aspect-ratio {
  .c11n-card__image-container img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}

@mixin card--image-below {
  flex-direction: column-reverse;
}

@mixin card--density-small {
  .c11n-card__content {
    padding: rem($c11n-size-space-2x);
  }

  .c11n-card__icon-container {
    padding-top: rem($c11n-size-space-2x);
    padding-left: rem($c11n-size-space-2x);
    padding-right: rem($c11n-size-space-2x);
  }

  &.c11n-card--image-padding {
    .c11n-card__image-container {
      padding: rem($c11n-size-space-2x);
    }

    @include image-padding-top-bottom-reset;
  }
}

@mixin card--density-medium {
  .c11n-card__content {
    padding: rem($c11n-size-space-3x);
  }

  .c11n-card__icon-container {
    padding-top: rem($c11n-size-space-3x);
    padding-left: rem($c11n-size-space-3x);
    padding-right: rem($c11n-size-space-3x);
  }

  &.c11n-card--image-padding {
    .c11n-card__image-container {
      padding: rem($c11n-size-space-3x);
    }

    @include image-padding-top-bottom-reset;
  }
}

@mixin card--density-large {
  .c11n-card__content {
    padding: rem($c11n-size-space-4x);
  }

  .c11n-card__icon-container {
    padding-top: rem($c11n-size-space-4x);
    padding-left: rem($c11n-size-space-4x);
    padding-right: rem($c11n-size-space-4x);
  }

  &.c11n-card--image-padding {
    .c11n-card__image-container {
      padding: rem($c11n-size-space-4x);
    }

    @include image-padding-top-bottom-reset;
  }
}

@mixin card--content-center {
  .c11n-card__content {
    text-align: center;
  }
  .c11n-chip-group {
    justify-content: center;
  }
}

@mixin card--color-yellow {
  background-color: $c11n-color-yellow-100;
}

@mixin card--color-red {
  background-color: $c11n-color-red-100;
}

@mixin card--color-turquoise {
  background-color: $c11n-color-turquoise-100;
}

@mixin card--border {
  border: $c11n-size-border-width-thin solid $c11n-color-ink-300;

  &.c11n-card--color-yellow {
    border-color: $c11n-color-yellow-600;
  }

  &.c11n-card--color-red {
    border-color: $c11n-color-red-600;
  }

  &.c11n-card--color-turquoise {
    border-color: $c11n-color-turquoise-600;
  }
}

@mixin card-hover-shadow {
  box-shadow: $c11n-elevation-box-shadow-800;
  transition-duration: $animation-timing-expand-large;
}

// Account for white outline when card is focused
@mixin card-focus-outline {
  .c11n-button__box {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: 2px solid $c11n-color-ink-000;
      outline-offset: 4px;
    }
  }
}

@mixin card--clickable {
  transition-property: box-shadow;
  transition-duration: $animation-timing-collapse-large;
  transition-timing-function: $animation-easing-standard-web;
  transform-style: flat;

  &:focus-within {
    @include c11n-focus-outline-block-after-rules();
    @include card-hover-shadow;
  }

  &:hover {  
    @include card-hover-shadow;
  }
}

@mixin card--link-pseudo-click {
  a {
    &:last-of-type {
      position: static;
      outline: none;

      // Outline being taken care of by focus outline mixin above in focus-within
      &:after {
        outline: none;
      }
    
      &:before {
        content: "";
        background: transparent;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    &.c11n-link:focus-visible {
      .c11n-link__content {
        outline: none;
      }
    }
  }
}

@mixin card--image-4-3 {
  .c11n-card__image-container img {
    aspect-ratio: 4 / 3;
  }
}

@mixin card--image-1-1 {
  .c11n-card__image-container img {
    aspect-ratio: 1 / 1;
  }
}

@mixin card--image-5-4 {
  .c11n-card__image-container img {
    aspect-ratio: 5 / 4;
  }
}

@mixin card--image-3-2 {
  .c11n-card__image-container img {
    aspect-ratio: 3 / 2;
  }
}

@mixin card--image-16-9 {
  .c11n-card__image-container img {
    aspect-ratio: 16 / 9;
  }
}

@mixin card--image-2-1 {
  .c11n-card__image-container img {
    aspect-ratio: 2 / 1;
  }
}

@mixin card--icon-above-center {
  .c11n-card__icon-container {
    justify-content: center;
  }
}

@mixin card--icon-left-start {
  .c11n-card__icon-and-content {
    flex-direction: row;
  }

  .c11n-card__icon-container {
    padding-right: 0;
  }
}

@mixin card--icon-left-center {
  .c11n-card__icon-and-content {
    flex-direction: row;
  }

  .c11n-card__icon-container {
    padding-top: 0;
    padding-right: 0;
    align-items: center;
  }
}

@mixin image-padding-top-bottom-reset {
  // TODO: In v2.0 we need to remove 'top'
  &.c11n-card--image-top,
  &.c11n-card--image-above {
    .c11n-card__image-container {
      padding-bottom: 0;
    }
  }

  // TODO: In v2.0 we need to remove 'bottom'
  &.c11n-card--image-bottom,
  &.c11n-card--image-below {
    .c11n-card__image-container {
      padding-top: 0;
    }
  }
}