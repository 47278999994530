@use 'sass:math';
@import './nav-overflow-mixins';
@import '../menu/menu-mixins';

.c11n-nav-overflow,
.c11n-tabs-overflow {
  @include nav-overflow-host;
  @include nav-overflow-child;
  @include menu-child;

  .c11n-nav-overflow__trigger,
  .c11n-tabs-overflow__trigger {
    @include nav-overflow-trigger-icon;

    &[aria-expanded = "true"] {
      @include nav-overflow-trigger-icon--expanded;
    }

    &:focus-visible {
      svg  {
        use {
          transition: none;
        }
      }
    }
  }

  .c11n-nav-overflow__item--with-icon,
  .c11n-nav-overflow__li--with-icon {
    @include nav-overflow-item-icon-rules;
  }

  &.c11n-nav-overflow--auto-width,
  &.c11n-tabs-overflow--auto-width {
    @include nav-overflow--auto-width;
  }

  &.c11n-nav-overflow--start,
  &.c11n-tabs-overflow--start {
    @include nav-overflow--start;
  }

  &.c11n-nav-overflow--center,
  &.c11n-tabs-overflow--center {
    @include nav-overflow--center;
  }

  &.c11n-nav-overflow--end,
  &.c11n-tabs-overflow--end {
    @include nav-overflow--end;
  }

  &.c11n-nav-overflow--below {
    @include nav-overflow--below;
  }

  &.c11n-nav-overflow--above {
    @include nav-overflow--above;
  }

  @each $key, $value in $z-index-list {
    &.c11n-nav-overflow--z-index-#{$key},
    &.c11n-tabs-overflow--z-index-#{$key} {
      .c11n-nav-overflow__menu,
      .c11n-tabs-overflow__menu {
        z-index: $value;
      }
    }
  }

  .c11n-navigation &,
  .c11n-tabs & {
    @include nav-overflow-navigation-rules;
    .c11n-nav-overflow__trigger,
    .c11n-tabs-overflow__trigger {
      @include navigation-overflow-trigger-icon;
    }
  }

  // TODO: remove this patch when we are able to make a visual breaking change by using .c11n-menu__item-label class in styles example
  .c11n-menu__item {
    @include c11n-link-underline-rules(span);
  }
}
