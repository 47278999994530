@import './dropdown-mixins';

.c11n-dropdown {
  @include dropdown-host;
  @include dropdown-child;
  @include menu-child();

  &.c11n-dropdown--start {
    @include dropdown--start;
  }

  &.c11n-dropdown--end {
    @include dropdown--end;
  }

  &.c11n-dropdown--medium {
    @include dropdown--medium;
  }

  &.c11n-dropdown--large {
    @include dropdown--large;
  }

  &.c11n-dropdown--untouched {
    @include dropdown--untouched;
  }

  &.c11n-dropdown--hide-label {
    @include dropdown--hide-label;
  }

  &.c11n-dropdown--basic, 
  &.c11n-dropdown--basic-multi, 
  &.c11n-dropdown--independent {
    @include dropdown--basic--basic-multi--independent;
  }

  &.c11n-dropdown--basic, 
  &.c11n-dropdown--basic-multi {
    @include dropdown--basic--basic-multi;
  }

  &.c11n-dropdown--independent {
    @include dropdown--independent;
  }

  &.c11n-dropdown--below {
    @include dropdown--below;
  }

  &.c11n-dropdown--above {
    @include dropdown--above;
  }

  &.c11n-dropdown--above.c11n-dropdown--independent-multi {
    @include dropdown--above-independent--above-independent-multi;
  }

  &.c11n-dropdown--above.c11n-dropdown--independent {
    @include dropdown--above-independent;
  }

  &.c11n-dropdown--below.c11n-dropdown--independent-multi {
    @include dropdown--below-independent--below-independent-multi;
  }

  &.c11n-dropdown--basic-multi {
    @include dropdown--basic-multi;
  }

  &.c11n-dropdown--independent-multi {
    @include dropdown--independent-multi;
  }

  &.c11n-dropdown--hide-label.c11n-dropdown--independent-multi {
    @include dropdown--hide-label-independent-multi;
  }

  &.c11n-dropdown--basic-multi, 
  &.c11n-dropdown--independent-multi {
    @include dropdown--basic--independent;
  }

  &.c11n-dropdown--basic-multi, 
  &.c11n-dropdown--independent-multi {
    @include dropdown--basic-multi--independent-multi;
  }

  &.c11n-dropdown--end.c11n-dropdown--independent-multi {
    @include dropdown--end-independent-multi;
  }
}