@import './icon-label-button-mixins';

.c11n-button {
  @include icon-label-button-host;
  @include icon-label-button-child;
  
  &.c11n-button--label-icon-primary,
  &.c11n-button--label-icon-secondary,
  &.c11n-button--label-icon-on-dark {

    &:hover {
      .c11n-button__box {
        transition-duration: $animation-timing-expand-medium;
        background-color: transparent;
        &::before {
          transition-duration: $animation-timing-expand-medium;
          width: 100%;
        }
        .c11n-button__labeled-icon {
          transition-duration: $animation-timing-expand-medium;
        }
      }
    }

    &:focus-visible {
      .c11n-button__box {
        transition: none;
        background-color: transparent;
        &::before {
          transition: none;
          width: 100%;
        }
        .c11n-button__labeled-icon {
          transition: none;
        }
      }
    }
  }

  &.c11n-button--label-icon-primary {
    .c11n-button__box {
      .c11n-button__labeled-icon {
        color: $white;
      }
    }

    &:hover {
      .c11n-button__box {
        color: $black;
        &::before {
          background-color: transparent;
        }
        .c11n-button__labeled-icon {
          color: $black;
        }
      }
    }

    &:focus-visible {
      .c11n-button__box {
        color: $black;
        transition: none;
        &::before {
          background-color: transparent;
          transition: none;
        }
        .c11n-button__labeled-icon {
          color: $black;
          transition: none;
        }
      }
    }
  }

  &.c11n-button--label-icon-secondary, 
  &.c11n-button--label-icon-on-dark {
    .c11n-button__box {
      .c11n-button__labeled-icon {
        color: $black;
      }
    }

    &:hover, &:focus-visible {
      .c11n-button__box {
        color: $white;
        &::before {
          background-color: $black;
        }
        .c11n-button__labeled-icon {
          color: $white;
        }
      }
    }

    &:focus-visible {
      .c11n-button__box {
        transition: none;
        &::before {
          transition: none;
        }
        .c11n-button__labeled-icon {
          transition: none;
        }
      }
    }
  }
}