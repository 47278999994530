@import './switch-mixins';

.c11n-switch {
  @include switch-host;
  @include switch-child;

  &.c11n-switch--leading {
    @include switch--leading;
  }
}
