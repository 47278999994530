@import '../../variables';

@mixin pagination-host {
  display: flex;
}

@mixin pagination-child {
  .c11n-pagination__previous {
    margin-right: rem($c11n-size-space-1x);
  }

  .c11n-pagination__next {
    margin-left: rem($c11n-size-space-1x);
  }

  .c11n-pagination__first {
    margin-right: rem($c11n-size-space-0point5x);

    &.c11n-pagination--text-link {
      margin-right: rem($c11n-size-space-1x);
    }
  }

  .c11n-pagination__last {
    margin-left: rem($c11n-size-space-0point5x);

    &.c11n-pagination--text-link {
      margin-left: rem($c11n-size-space-1x);
    }
  }

  .c11n-pagination__numbered {
    display: flex;

    .c11n-pagination__page-number {
      margin-right: rem($c11n-size-space-1x);

      &:last-child {
        margin-right: 0;
      }
    }

    .c11n-pagination__page--hidden {
      display: none;
    }
  }

  .c11n-pagination--hide-controls-with-no-results {
    visibility: hidden;
  }

  .c11n-pagination__ellipsis {
    font-family: $default-font;
    font-weight: $c11n-font-weight-normal;
    font-variant-numeric: tabular-nums;
    font-size: rem($c11n-size-typography-sm);
    line-height: rem($c11n-size-space-4x);
    margin-right: rem($c11n-size-space-1x);

    &:before {
      content: '\2026';
    }

    &.c11n-pagination__ellipsis--no-margin {
      margin-right: 0;
    }
  }
}

@mixin activePageStyle {
  .c11n-pagination--active .c11n-button.c11n-button--small .c11n-button__box,
  .c11n-pagination--active.c11n-button.c11n-button--small .c11n-button__box {
    background: $c11n-color-ink-800;
    color: $c11n-color-ink-000;
  }

  .c11n-pagination--active .c11n-button.c11n-button--small,
  .c11n-pagination--active.c11n-button.c11n-button--small {
    &:hover,
    &:focus-visible {
      &:before {
        background-color: transparent;
      }
    }
  }
}

@mixin noTransitionStyle {
  .c11n-pagination__page-number
    :not(.c11n-pagination--active)
    .c11n-button.c11n-button--small
    .c11n-button__box,
  .c11n-pagination__page-number
    :not(.c11n-pagination--active.c11n-button)
    .c11n-button__box {
    transition: none;
  }
}

@mixin rotatePreviousIcon {
  .c11n-pagination__previous .c11n-button__box {
    transform: rotate(180deg);
  }
}

@mixin rotateLastPageIcon {
  .c11n-pagination__last .c11n-button__box {
    transform: rotate(180deg);
  }
}

@mixin pageNumberFontStyle {
  .c11n-pagination__page-number .c11n-button span.c11n-button__label {
    font-weight: $c11n-font-weight-normal;
    font-variant-numeric: tabular-nums;
  }
}
