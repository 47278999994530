@import './hint-error-mixins';

.c11n-hint-error {
  @include c11n-hint-error-default;
  &.c11n-hint-error--error {
    @include c11n-hint-error-error-child;
  }
  &.c11n-hint-error--large {
    @include c11n-hint-error-large-child;
    @include c11n-hint-error-error-large-child;
  }
  @include c11n-hint-error-child;
}
