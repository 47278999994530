@import './accordion-mixins';

.c11n-accordion {
  @include accordion-host;
  @include accordion-child;

  .c11n-accordion__icon {
    @include accordion-icon;
  }

  @include accordion-icon-white;

  &.c11n-accordion--leading  {
    @include accordion--leading;
  }
  
  &.c11n-accordion--summary {
    @include accordion--summary;
  }

  &.c11n-accordion--medium {
    @include accordion--medium;
  }

  &.c11n-accordion--medium.c11n-accordion--normal-weight {
    @include accordion--medium--normal;
  }

  &.c11n-accordion--medium.c11n-accordion--bold-weight {
    @include accordion--medium--bold;
  }

  &.c11n-accordion--medium.c11n-accordion--leading {
    @include accordion--medium--leading;
  }

  &.c11n-accordion--medium.c11n-accordion--trailing {
    @include accordion--medium--trailing;
  }

  &.c11n-accordion--large {
    @include accordion--large;
  }

  &.c11n-accordion--large.c11n-accordion--bold-weight {
    @include accordion--large--bold;
  }

  &.c11n-accordion--large.c11n-accordion--heavy-weight {
    @include accordion--large--heavy;
  }

  &.c11n-accordion--large.c11n-accordion--leading {
    @include accordion--large--leading;
  }

  &.c11n-accordion--large.c11n-accordion--trailing {
    @include accordion--large--trailing;
  }

  &.c11n-accordion--bold-weight {
    @include accordion--bold;
  }

  &.c11n-accordion--expanded {
    @include accordion--expanded;
  }

  &.c11n-accordion--expanded.c11n-accordion--with-animation {
    @include accordion--with-animation-expanded;
  }
  
  &.c11n-accordion--with-animation {
    @include accordion--with-animation-collapsed;
  }

  .c11n-accordion-group & {
    @include accordion-group__accordion;
    &:last-of-type {
      @include accordion-group__accordion--last-of-type;
    }
  }

  .c11n-accordion-group.c11n-accordion-group--margin-none & {
    @include accordion-group__accordion--margin-none;
  }

  .c11n-accordion-group.c11n-accordion-group--margin-medium & {
    @include accordion-group__accordion--margin-medium;
  }

  .c11n-accordion-group.c11n-accordion-group--margin-large & {
    @include accordion-group__accordion--margin-large;
  }
}

.c11n-accordion-group {
  @include accordion-group-host;
}
