@import '../../variables';

@mixin value-change-host {
  display: inline-flex;
  white-space: nowrap;
  font-family: $c11n-font-family-sans;
  font-variant-numeric: tabular-nums;

  &.c11n-value-change--weight-normal {
    font-weight: $c11n-font-weight-normal;
  }
}

@mixin value-change-child {
  // icon styles
  --icon-margin-horizontal: #{rem($c11n-size-space-0point5x)};
  --icon-size: #{rem(14)};
  --icon-size-unitless: 14;
  --icon-base-size-unitless: 14;

  .c11n-value-change__icon-container {
    display: inline-flex;
    align-items: center;
    width: var(--icon-size);
    // extra top margin needed to align the icon with text's vertical alignment
    margin-top: 0.155em;
  }

  .c11n-value-change__icon {
    display: none;

    &[data-container='c11n-icon-container'] {
      $icon-scale: calc(var(--icon-size-unitless) / var(--icon-base-size-unitless));
      transform: scale($icon-scale);
      transform-origin: 0 50%;
    }
  }

  &.c11n-text-lg {
    --icon-size: #{rem($c11n-size-space-2x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-2x)};
  }

  &.c11n-text-xl {
    --icon-size: #{rem($c11n-size-space-2point5x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-2point5x)};
  }

  &.c11n-text-2xl {
    --icon-size: #{rem($c11n-size-space-3x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-3x)};
  }

  &.c11n-text-3xl {
    --icon-size: #{rem($c11n-size-space-4x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-4x)};
  }

  &.c11n-text-4xl {
    --icon-size: #{rem($c11n-size-space-4point5x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-4point5x)};
  }

  &.c11n-text-5xl {
    --icon-size: #{rem($c11n-size-space-6x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-6x)};
  }

  &.c11n-text-6xl {
    --icon-size: #{rem($c11n-size-space-8x)};
    --icon-size-unitless: #{strip-unit($c11n-size-space-8x)};
  }

  &.c11n-text-xl,
  &.c11n-text-2xl,
  &.c11n-text-3xl,
  &.c11n-text-4xl,
  &.c11n-text-5xl,
  &.c11n-text-6xl {
    --icon-margin-horizontal: #{rem($c11n-size-space-1x)};
  }
}

@mixin value-change--width-narrow {
  &.c11n-text-sm,
  &.c11n-text-md,
  &.c11n-text-lg {
    font-family: $c11n-font-family-sans-narrow;
  }
}

@mixin value-change--weight-bold {
  font-weight: $c11n-font-weight-bold;

  &.c11n-text-xl,
  &.c11n-text-2xl,
  &.c11n-text-3xl,
  &.c11n-text-4xl,
  &.c11n-text-5xl,
  &.c11n-text-6xl {
    font-weight: $c11n-font-weight-heavy;
  }
}

@mixin value-change--positive {
  .c11n-value-change__icon--positive {
    display: inline-block;
  }
}

@mixin value-change--negative {
  .c11n-value-change__icon--negative {
    display: inline-block;
  }
}

@mixin value-change--hide-icon {
  .c11n-value-change__icon-container {
    display: none;
  }
}

@mixin value-change--icon-leading {
  flex-direction: row-reverse;
  vertical-align: bottom;

  .c11n-value-change__icon-container {
    margin-right: var(--icon-margin-horizontal);
  }
}

@mixin value-change--icon-trailing {
  .c11n-value-change__icon-container {
    margin-left: var(--icon-margin-horizontal);
  }
}