@import './drawer-mixins';

.c11n-drawer {
	@include drawer-host;
	@include drawer-child;

	&.c11n-drawer--left {
		@include drawer-aligned-left;
	}

	&.c11n-drawer--right {
		@include drawer-aligned-right;
	}

	&.c11n-drawer--modal {
		@include drawer-modal;
	}

	&.c11n-drawer--open {
		@include drawer-open;
	}

	&.c11n-drawer--sticky {
		@include drawer-sticky;
	}

	.c11n-drawer--collapse-fast {
		transition-duration: 0ms;
	}
}