@import '../../variables';

@mixin segmented-control-host {
  --divider-width: #{rem($c11n-size-border-width-thin)};
  --segment-gap: #{rem(2)};
  --segment-height: #{rem($c11n-component-button-height-medium)};
  --segment-font-size: #{rem($c11n-size-typography-md)};
  --segment-label-padding-bottom: #{rem(2)};
  --segment-label-padding-horizontal: #{rem($c11n-size-space-3x)};
  --active-segment-bg: #{$c11n-color-ink-800};
  --active-segment-color: #{$white};

  display: block;

  &.c11n-segmented-control--small {
    --segment-height: #{rem($c11n-component-button-height-small)};
    --segment-font-size: #{rem($c11n-size-typography-sm)};
    --segment-label-padding-horizontal: #{rem($c11n-size-space-1point5x)};
  }

  &.c11n-segmented-control--large {
    --segment-height: #{rem($c11n-component-button-height-large)};
    --segment-label-padding-horizontal: #{rem($c11n-size-space-5x)};
  }

  &.c11n-segmented-control--yellow {
    --active-segment-bg: #{$c11n-color-yellow-400};
    --active-segment-color: #{$c11n-color-ink-800};
  }

  &.c11n-segmented-control--turquoise {
    --active-segment-bg: #{$c11n-color-turquoise-400};
    --active-segment-color: #{$c11n-color-ink-800};
  }

  .c11n-segmented-control__fieldset {
    // remove default fieldset styling
    border: none;
    padding: 0;
    margin: 0;

    position: relative;
    display: flex;
    gap: var(--segment-gap);
    border-radius: rem($c11n-size-space-4x);
    box-shadow: inset 0 0 0 rem($c11n-size-border-width-thin) $c11n-color-ink-800;
    outline: rem($c11n-size-border-width-thin) solid transparent;
    background-color: $white;
  }
}

@mixin segmented-control-child {
  .c11n-segmented-control__legend {
    @include segmented-control-sr-only;
  }
}

@mixin segmented-control-segment {
  display: block;
  min-width: 0;
  flex: 1;
  position: relative;

  &:not(:first-of-type) {
    &::before {
      content: '';
      width: var(--divider-width);
      height: calc(var(--segment-height) - #{rem($c11n-size-space-2x)});
      position: absolute;
      top: 50%;
      left: calc(var(--segment-gap) * -1);
      transform: translateY(-50%);
      background-color: $c11n-color-ink-300;

      transition-property: opacity;
      transition-duration: $animation-timing-collapse-small;
      transition-timing-function: $animation-easing-standard-web;
    }
  }

  &.c11n-segmented-control__segment:not([class$="--active"]) {
    .c11n-segmented-control__segment-label:hover {
      @include segmented-control-active-focus-hover;
    }
  }

  &.c11n-segmented-control__segment--active, &:hover {
    @include segmented-control-active-segment-sibling-before;

    & + .c11n-segmented-control__segment {
      @include segmented-control-active-segment-sibling-before;
    }
  }

  &.c11n-segmented-control__segment--active {
    .c11n-segmented-control__segment-label {
      background-color: var(--active-segment-bg);
      color: var(--active-segment-color);
      font-weight: $c11n-font-weight-bold;
    }
  }

  .c11n-segmented-control__segment-label {
    padding-left: var(--segment-label-padding-horizontal);
    padding-right: var(--segment-label-padding-horizontal);
    height: var(--segment-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem($c11n-size-space-4x);
    color: $c11n-color-ink-800;
    font-size: var(--segment-font-size);
    padding-bottom: var(--segment-label-padding-bottom);
    line-height: calc((var(--segment-height) - var(--segment-label-padding-bottom)) / var(--segment-font-size));

    transition-property: box-shadow, background-color, color;
    transition-duration: $animation-timing-collapse-small;
    transition-timing-function: $animation-easing-standard-web;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

@mixin segmented-control-active-segment-sibling-before {
  &::before {
    opacity: 0;
    transition-duration: $animation-timing-expand-small;
  }
}

@mixin segmented-control-segment-input {
  // remove default radio circle styling
  opacity: 0;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: all;

  &:checked {
    & + .c11n-segmented-control__segment-label {
      @include segmented-control-active-underline-for-hcm;
    }

    // TODO: this is required for angular segmented control component
    ::ng-deep {
      & + .c11n-segmented-control__segment-label {
        @include segmented-control-active-underline-for-hcm;
      }
    }
  }

  &:focus-visible {
    & + .c11n-segmented-control__segment-label {
      transition: none;
      @include c11n-focus-outline-block-after-rules;
    }

    // TODO: this is required for angular segmented control component
    ::ng-deep {
      & + .c11n-segmented-control__segment-label {
        transition: none;
        @include c11n-focus-outline-block-after-rules;
        @include segmented-control-active-focus-hover;
      }
    }
  }
}

@mixin segmented-control-sr-only {
  // TODO: let's make global mixin for sr-only
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
}

@mixin segmented-control-active-focus-hover {
  transition-duration: $animation-timing-expand-small;
  box-shadow: inset 0 0 0 rem($c11n-size-border-width-pipe) var(--active-segment-bg);
  outline: rem($c11n-size-border-width-pipe) solid transparent;
  background-color: $white;
  color: $c11n-color-ink-800;
  font-weight: $c11n-font-weight-bold;
}

@mixin segmented-control-active-underline-for-hcm {
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: rem($c11n-size-space-0point5x);
}
