@import '../../variables';

@mixin label-host {
  display: grid;
  position: relative;
  margin-bottom: rem($c11n-size-space-1x);
  padding: 0;
  width: 100%;
  color: $default-color;
}

@mixin label-large-child {
  .c11n-label__content {
    @include c11n-font-rules('regular','md');
    @include c11n-text-crop-md();
  }
}

@mixin label-child {
  .c11n-label__content {
    padding: 0;
    @include c11n-font-rules('regular','xs');
    @include c11n-text-crop-xs();
  }
  .c11n-label--tooltip {
    display: inline-block;
    padding: 0 rem($c11n-size-space-3x) 0 0;
    margin: 0;
    max-width: fit-content;
    width: auto;
    position: relative;
  }
}

@mixin label-tooltip-overrides {
  .c11n-tooltip__inner-wrapper {
    transform: initial;
  }
  .c11n-tooltip__popup {
    @include c11n-font-rules('regular','md');
  }
}

@mixin label-tooltip-large-overrides {
  .c11n-tooltip__trigger {
    top: rem(-1);
  }
}
