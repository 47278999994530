@import './input-mixins';

.c11n-input {
  @include input-host;
  @include c11n-focus-outline-sibling(
    '.c11n-input__input', 
    '.c11n-input__focus-element', 
    $width: 100%,
    $height: 100%,
    $top: 0,
    $left: 0
  );

  .c11n-input__input {
    @include input-input-host;
  }

  &.c11n-input--large {
    .c11n-input__input {
      @include input--large;
    }
  }

  &.c11n-input--error {
    .c11n-input__input {
      @include input--error;
    }
  }
}