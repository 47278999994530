@import './button-mixins';

.c11n-button {

  @include button-host;
  @include button-child;

  &:hover, &:focus-visible {
    @include button-hover-focus;
  }

  &.c11n-button--secondary,
  &.c11n-button--leading-icon-secondary,
  &.c11n-button--trailing-icon-secondary {
    &:hover, &:focus-visible {
      @include button-secondary-hover-focus;
    }
  }

  &.c11n-button--ghost,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--ghost-on-dark,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-ghost-on-dark {
    @include c11n-hover-block-before();
    &::before {
      // TODO: compute this value to always be half the height of our largest button
      border-radius: rem($c11n-size-space-4x);
    }
    .c11n-button__box {
      color: currentColor;
      border: none;
      background-color: transparent;
    }
  }

  &.c11n-button--ghost-circle {
    .c11n-button__box {
      max-width: 1ch;
    }
  }

  &.c11n-button--ghost-on-dark,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-ghost-on-dark {
    color: $white;
    &::before {
      background-color: rgba($c11n-color-ink-000, 0.3);
    }
  }
}