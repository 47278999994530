@import '../../variables';
@import './button-shared-mixins';

$border-width: $c11n-component-button-border-width;

@mixin button-child {

  .c11n-button__box {
    @include button__box;
  }

  .c11n-button__label {
    padding: 0;
    margin: 0;
    justify-content: center;
    white-space: nowrap;
  }

  @include button__box-before;
  @include button__label;

  .c11n-button__trailing-icon,
  .c11n-button__leading-icon {
    display: none;
    flex-direction: row;
    align-items: center;
    max-width: none;
    min-width: auto;
  }

  &.c11n-button--small {
    .c11n-button__box {
      height: rem($c11n-component-button-height-small);
      padding: 0 rem($c11n-size-space-2x);
    }
  }

  &.c11n-button--large {
    .c11n-button__box {
      height: rem($c11n-component-button-height-large);
      padding: 0 rem($c11n-size-space-4x);
    }
  }

  &.c11n-button--full-width {
    width: 100%;
  }

  &.c11n-button--leading-icon-primary,
  &.c11n-button--leading-icon-primary-on-dark,
  &.c11n-button--leading-icon-secondary,
  &.c11n-button--leading-icon-secondary-on-dark,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-primary,
  &.c11n-button--trailing-icon-primary-on-dark,
  &.c11n-button--trailing-icon-secondary,
  &.c11n-button--trailing-icon-secondary-on-dark,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--trailing-icon-ghost-on-dark  {
    .c11n-button__box {
      justify-content: center;
    }
  }

  %icon-rules {
    @include button-icon-transition;
    display: flex;
    border: 0 none;
    height: 100%;
    padding: 0;
  }

  &.c11n-button--leading-icon-primary,
  &.c11n-button--leading-icon-primary-on-dark,
  &.c11n-button--leading-icon-secondary,
  &.c11n-button--leading-icon-secondary-on-dark,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--leading-icon-ghost-on-dark {
    .c11n-button__leading-icon {
      @extend %icon-rules;
    }
  }

  &.c11n-button--trailing-icon-primary,
  &.c11n-button--trailing-icon-primary-on-dark,
  &.c11n-button--trailing-icon-secondary,
  &.c11n-button--trailing-icon-secondary-on-dark,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--trailing-icon-ghost-on-dark  {
    .c11n-button__trailing-icon {
      @extend %icon-rules;
    }
  }

  &.c11n-button--leading-icon-primary,
  &.c11n-button--leading-icon-primary-on-dark,
  &.c11n-button--leading-icon-secondary,
  &.c11n-button--leading-icon-secondary-on-dark,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--leading-icon-ghost-on-dark {
    .c11n-button__leading-icon {
      justify-content: flex-end;
      margin: 0 rem($c11n-size-space-1x) 0 0;
    }
  }

  &.c11n-button--trailing-icon-primary,
  &.c11n-button--trailing-icon-primary-on-dark,
  &.c11n-button--trailing-icon-secondary,
  &.c11n-button--trailing-icon-secondary-on-dark,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--trailing-icon-ghost-on-dark {
    .c11n-button__trailing-icon {
      justify-content: flex-start;
      margin: 0 0 0 rem($c11n-size-space-1x);
    }
  }

  &.c11n-button--secondary,
  &.c11n-button--loading-secondary,
  &.c11n-button--leading-icon-secondary,
  &.c11n-button--trailing-icon-secondary,
  &.c11n-button--ghost,
  &.c11n-button--loading-ghost,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--ghost-on-dark,
  &.c11n-button--loading-ghost-on-dark,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-ghost-on-dark {
    color: currentColor;
    .c11n-button__box {
      background-color: transparent;
      color: currentColor;
    }
  }

  &.c11n-button--ghost,
  &.c11n-button--loading-ghost,
  &.c11n-button--leading-icon-ghost,
  &.c11n-button--trailing-icon-ghost,
  &.c11n-button--ghost-on-dark,
  &.c11n-button--loading-ghost-on-dark,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-ghost-on-dark {
    .c11n-button__box {
      border: 0 none;
    }
  }

  &.c11n-button--on-dark,
  &.c11n-button--loading-on-dark,
  &.c11n-button--primary-on-dark,
  &.c11n-button--loading-primary-on-dark,
  &.c11n-button--leading-icon-primary-on-dark,
  &.c11n-button--trailing-icon-primary-on-dark {
    .c11n-button__box {
      background-color: $white;
      border: rem($border-width) solid $white;
      color: $black;
    }
  }

  &.c11n-button--secondary-on-dark,
  &.c11n-button--loading-secondary-on-dark,
  &.c11n-button--leading-icon-secondary-on-dark,
  &.c11n-button--trailing-icon-secondary-on-dark {
    .c11n-button__box {
      background-color: transparent;
      border: rem($border-width) solid $white;
      color: $white;
    }
  }

  &.c11n-button--on-dark,
  &.c11n-button--primary-on-dark,
  &.c11n-button--leading-icon-primary-on-dark,
  &.c11n-button--trailing-icon-primary-on-dark  {
    &:hover, &:focus-visible {
      @include on-dark-hover-focus-rules;
    }
  }

  &.c11n-button--secondary-on-dark,
  &.c11n-button--leading-icon-secondary-on-dark,
  &.c11n-button--trailing-icon-secondary-on-dark {
    &:hover, &:focus-visible {
      @include on-dark-secondary-hover-focus-rules;
    }
  }

  &.c11n-button--ghost-on-dark,
  &.c11n-button--leading-icon-ghost-on-dark,
  &.c11n-button--trailing-icon-ghost-on-dark  {
    color: $white;
    background: transparent;
  }
  
  .c11n-spinner {
    display: none;
  }

  &.c11n-button--loading-primary,
  &.c11n-button--loading-secondary,
  &.c11n-button--loading-on-dark,
  &.c11n-button--loading-primary-on-dark,
  &.c11n-button--loading-secondary-on-dark,
  &.c11n-button--loading-ghost,
  &.c11n-button--loading-ghost-on-dark {
    pointer-events: none;

    .c11n-button__box {
      position: relative;

      .c11n-spinner {
        position: absolute;
        display: flex;
      }

      .c11n-button__label {
        visibility: hidden;
      }
    }
  }

  &:not([class*='c11n-button--icon']):not([class*='c11n-button--label-icon']) {
    .c11n-button__box {
      min-width: rem($c11n-size-space-16x);
    }
    &.c11n-button--small {
      .c11n-button__box {
        min-width: rem($c11n-size-space-8x);
      }
    }

    &.c11n-button--large {
      .c11n-button__box {
        min-width: rem($c11n-size-space-18x);
      }
    }

    &.c11n-button--ghost  {
      .c11n-button__box {
        min-width: 0;
      }
    }
    &.c11n-button--ghost-on-dark  {
      .c11n-button__box {
        min-width: 0;
      }
    }
  }

  &.c11n-button--invalid {
    @include button-invalid-styles;
  }
  
}

@mixin button__box {
  @include button__box-shared;
  height: rem($c11n-component-button-height-medium);
  padding: 0 rem($c11n-size-space-3x);
  background-color: $c11n-component-button-background-color-primary-default;
  transition-property: color, background-color;
  transition-duration: $animation-timing-collapse-small;
  transition-timing-function: $animation-easing-standard-web;
  transform-style: flat;
  backface-visibility: hidden;
}



