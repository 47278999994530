@import '../../variables';

$sidebar-nav-text-offset: 1;

@mixin sidebar-nav-host {
  --padding-top: #{rem($c11n-size-space-1point5x - $sidebar-nav-text-offset)};
  --padding-bottom: #{rem($c11n-size-space-1point5x + $sidebar-nav-text-offset)};
  --padding-left: #{rem($c11n-size-space-1point5x)};
  --padding-right: #{rem($c11n-size-space-3x)};
  background-image: linear-gradient(to right, $c11n-color-ink-300 0, $c11n-color-ink-300 rem($c11n-size-border-width-thin), transparent rem($c11n-size-border-width-thin));
  display: grid;
  gap: #{rem($c11n-size-space-3x)};
}

@mixin sidebar-nav-optional-ul {
  > ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    display: contents;
  }
}

@mixin sidebar-nav-item-host {

  @include c11n-font-rules('bold', 'md');
  color: $c11n-color-ink-800;
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  background-image: linear-gradient($c11n-color-ink-800, $c11n-color-ink-800);
  background-size: rem($c11n-size-border-width-pipe) 0%;
  background-repeat: no-repeat;
  background-position: 0 0;
  display: flex;
  gap: rem($c11n-size-space-1x);
  align-items: flex-start;
  transition-property: background-size;
  transition-timing-function: $animation-easing-standard-web;
  transition-duration: $animation-timing-collapse-small;
  @include c11n-focus-outline-block-after();

  &:hover,
  &:focus-visible,
  &[aria-current="true"] {
    background-size: rem($c11n-size-border-width-pipe) 100%;
  }

  &[aria-current="true"] {
    background-image: linear-gradient($c11n-color-red-600, $c11n-color-red-600);
  }

  &:hover,
  &:focus-visible {
    transition-duration: $animation-timing-expand-small;
  }

  &:focus-visible {
    transition: none;
  }

  [data-container="c11n-icon-container"] {
    flex-shrink: 0;
    transform: translate(0, rem($sidebar-nav-text-offset));
  }

  .c11n-sidebar-nav__item__label-text {
    flex-grow: 1;
  }

  .c11n-sidebar-nav__item__badge {
    margin-top: rem(5 + $sidebar-nav-text-offset);
  }
}