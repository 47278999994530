@import '../../variables';

$select-height-medium: rem($c11n-size-space-6x);
$select-height-large: rem($c11n-size-space-8x);
$select-min-width: rem(44);
$select-font-size-medium: rem($c11n-size-typography-md);
$select-font-size-large: rem($c11n-size-typography-lg);
$select-svg-size: rem(14);
$select-svg-size-large: rem(24);
$arrow-color: $black;

@mixin select-host {
  @include c11n-font-rules('regular', 'md');
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

@mixin select-child {
  .c11n-select__box {
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    width: 100%;
    height: $select-height-medium;
    min-width: $select-min-width;
    background: $white;
    border: rem($c11n-size-border-width-thin) solid $default-color;
    border-radius: 0;

    &__icon {
      position: absolute;
      pointer-events: none;
      top: 0;
      right: rem($c11n-size-space-2x);
      height: 100%;
      display: flex;
      align-items: center;
      color: $arrow-color;

      svg {
        height: $select-svg-size;
        width: $select-svg-size;
      }
    }

    .c11n-select__focus-element {
      position: absolute;
      top: -1px;
      left: -1px;
      height: calc(100% + #{rem($c11n-size-border-width-thick)});
      width: calc(100% + #{rem($c11n-size-border-width-thick)});
    }
  }
}

@mixin select--focus-within {
  .c11n-select__box {
    @include c11n-focus-outline-inner();
  }

  .c11n-select__focus-element {
    @include c11n-focus-outline-outer();
  }
}

@mixin select--error {
  .c11n-select__box {
    border-color: $error-color;
  }
}

@mixin select--medium {
  // the below selector only works for styles only example (angular version has nested component) but nice to have here
  .c11n-select__box__select {
    @include select-input--medium;
  }

  .c11n-select__box {
    height: $select-height-medium;
  }

  .c11n-select__box__icon {
    right: rem(16);
  }
}

@mixin select--large {
  // the below selector only works for styles only example (angular version has nested component) but nice to have here
  .c11n-select__box__select {
    @include select-input--large;
  }

  .c11n-select__box {
    height: $select-height-large;
  }

  .c11n-select__box__icon {
    right: rem(16);
    svg {
      height: $select-svg-size-large;
      width: $select-svg-size-large;
      use {
        fill: transparent;
        stroke: $arrow-color;
      }
    }
  }
}

@mixin select-input-host {
  @include c11n-font-rules('regular', 'md');
  color: $black;
  position: relative;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: rem(0) rem(48) rem(2) rem(16);
  border: 0 none;
  margin: 0;
  text-overflow: ellipsis;
  cursor: default;
  z-index: 1;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none; // This order has been altered to fix a styling issue in the Angular version and older versions of Safari (v15.3) 
  &:focus {
    outline: none;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  &:invalid {
    color: $c11n-color-ink-400;
  }
}

@mixin select-input-child {
  option {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    color: $black;
  }

  option[data-option="placeholder"] {
    color: $c11n-color-ink-400;
  }
}

@mixin select-input--medium {
  @include c11n-font-rules('regular', 'md');
  padding: rem(0) rem(48) rem(2) rem(16);
}

@mixin select-input--large {
  @include c11n-font-rules('regular', 'lg');
  padding: rem(0) rem(48) rem(4) rem(16);
}

@mixin select-input--readonly {
  &:disabled {
    opacity: 1;
  }
}
