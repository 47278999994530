@import './breadcrumbs_mixins';

.c11n-breadcrumbs {
  @include breadcrumbs-list-host; 

  &__item {
    .c11n-link {
      @include breadcrumbs-item-link;
    }
  }

  &--hide-trailing-slash {
    @include breadcrumbs-item--hide-trailing-slash;

    .c11n-breadcrumbs__item:last-of-type {
      .c11n-link {  
        @include breadcrumbs-item-link--hide-trailing-slash;
      }
    }
  }

  &__item--overflow {
    @include breadcrumbs-item--overflow;

    span {
      @include breadcrumbs-item-span--overflow;
    }

    .c11n-link {
      @include breadcrumbs-item-link--overflow;
    }
  }
}

.c11n-breadcrumbs__overflow {  
  &__trigger {
    @include breadcrumbs-overflow-trigger;
  }

  &--center {
    .c11n-nav-overflow__menu {
      @include breadcrumbs-overflow-menu--center;
    }
  }

  &--start {
    .c11n-nav-overflow__menu {
      @include breadcrumbs-overflow-menu--start;
    }
  }

  &--end {
    .c11n-nav-overflow__menu {
      @include breadcrumbs-overflow-menu--end;
    }
  }
}