@import '../../variables';
@import '../navigation/navigation-mixins';
@import '../menu/menu-mixins';

@mixin tabs-host {
  display: flex;
}

@mixin tabs--small-responsive {
  .c11n-tabs__inner-wrapper {
    width: calc(100% - #{rem(38)});
  }
}

@mixin tabs--equal-width {
  display: block;

  &.c11n-tabs--has-overflow {
    position: relative;

    .c11n-tabs__inner-wrapper {
      width: calc(100% - #{rem(38)});

      ~ .c11n-tabs-overflow {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@mixin tab-panel-transition {
  opacity: 0;
  transition-property: var(--transitionProperty);
  transition-duration: $animation-timing-collapse-large;
  transition-timing-function: $animation-easing-standard-web;
  transform-style: flat;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  // needed for safari to trigger opacity transition
  display: block;

  &--active,
  &.c11n--transition-in {
    opacity: 1;
    transition-duration: $animation-timing-expand-large;
  }

  &--hidden {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    z-index: -1;
    * {
      display: none;
    }
  }
}

@mixin tab-panel-focus {
  @include c11n-focus-outline-block-after;
}
