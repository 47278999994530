@import '../../variables';

@mixin link-a {
  @include c11n-focus-outline-inline('.c11n-link__content');
}

@mixin link-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  font: inherit;
  text-align: inherit;
  @include c11n-focus-outline-block-after();
}

@mixin link-host {

  --common-bg-rule: 100% #{rem($c11n-size-border-width-thin)}, 0% #{rem($c11n-size-border-width-thick)};

  font-weight: $c11n-font-weight-bold;
  color: $interactive-color;
  position: relative;
  transition-property: color;
  transition-timing-function: $animation-easing-standard-web;
  transition-duration: $animation-timing-collapse-small;
  transform-style: flat;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  @include c11n-link-underline-rules('.c11n-link__content');

  padding-bottom: rem(3);
  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: rem(3);
  }

  &:focus,
  &:hover {
    color: $c11n-color-ink-800;
  }

  &:hover {
    transition-duration: $animation-timing-expand-small;
  }

  &:focus-visible {
    transition: none;
  }
}

@mixin link--md {

  --link-md-bold-padding-bottom: #{rem($c11n-text-md-bold-underline-padding)};
  padding-bottom: var(--link-md-bold-padding-bottom);

  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: var(--link-md-bold-padding-bottom);
  }
}

@mixin link--sm {

  --link-sm-bold-padding-bottom: #{rem($c11n-text-sm-bold-underline-padding)};
  padding-bottom: var(--link-sm-bold-padding-bottom);

  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: var(--link-sm-bold-padding-bottom);
  }
}

@mixin link--normal {
  font-weight: $c11n-font-weight-normal;
  
  padding-bottom: rem(2);
  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: rem(2);
  }
}

@mixin link--md--normal {

  --link-md-normal-padding-bottom: #{rem($c11n-text-md-normal-underline-padding)};
  padding-bottom: var(--link-md-normal-padding-bottom);

  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: var(--link-md-normal-padding-bottom);
  }
}

@mixin link--sm--normal {

  --link-sm-normal-padding-bottom: #{rem($c11n-text-sm-normal-underline-padding)};
  padding-bottom: var(--link-sm-normal-padding-bottom);

  .c11n-link__content,
  .c11n-link__text {
    padding-bottom: var(--link-sm-normal-padding-bottom);
  }
}

@mixin link--icon--leading-icon {

  @include c11n-link-underline-rules('.c11n-link__text');

  .c11n-link__content {
    background-image: none;
  }

  [data-container="c11n-icon-container"],
  .c11n-link__content > svg {
    display: inline-block;
    vertical-align: middle;
  }
}

@mixin link--icon {
  [data-container="c11n-icon-container"],
  .c11n-link__content > svg {
    margin: 0 0 rem(1) rem($c11n-size-space-0point5x);
  }
}

@mixin link--leading-icon {
  [data-container="c11n-icon-container"],
  .c11n-link__content > svg {
    margin: 0 rem($c11n-size-space-0point5x) rem(1) 0;
  }
}

@mixin link--secondary {

  color: $c11n-color-ink-800;

  .c11n-link__content {
    background-size: var(--common-bg-rule);
  }
}

@mixin link--reinforced {
  .c11n-link__content,
  .c11n-link__text {
    background-size: var(--common-bg-rule);
  }
  // Reinforced specificity added since removal of not selectors impacted priority order
  &:focus,
  &:hover {
    .c11n-link__content,
    .c11n-link__text {
      background-size: 100% rem($c11n-size-border-width-thin), 100% rem($c11n-size-border-width-thick);
    }
  }
}

@mixin link--on-dark {

  color: $c11n-color-ink-000 !important;
  
  .c11n-link__content {
    background-size: var(--common-bg-rule);
  }
}

@mixin link--no-wrap {
  white-space: nowrap;
}
