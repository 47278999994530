$c11n-focus-offset: 2;

@mixin c11n-focus-outline-inner {
  outline: solid rem($c11n-size-border-width-thick) $focus-color;
  outline-offset: rem($c11n-focus-offset);
}
@mixin c11n-focus-outline-outer {
  outline: solid rem($c11n-size-border-width-thick) $c11n-color-ink-000;
  outline-offset: rem($c11n-focus-offset + $c11n-size-border-width-thick);
}

@mixin c11n-focus-outline-inline($child-selector: '> span') {
  &:focus-visible {
    @include c11n-focus-outline-outer();
    #{$child-selector} {
      @include c11n-focus-outline-inner();
    }
  }
}

@mixin c11n-focus-outline-sibling-rules(
  $width: rem($c11n-size-space-2x),
  $height: rem($c11n-size-space-2x),
  $top: rem(5),
  $left: 0
  ) {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: $height;
    z-index: 1;
    pointer-events: none;
  }
  &::before {
    @include c11n-focus-outline-outer();
  }
  &::after {
    @include c11n-focus-outline-inner();
  }
}

@mixin c11n-focus-outline-sibling(
  $focusable-selector,
  $sibling-selector,
  $width: rem($c11n-size-space-2x),
  $height: rem($c11n-size-space-2x),
  $top: rem(4),
  $left: 0) {

  #{$focusable-selector}:focus-visible ~ #{$sibling-selector} {
    @include c11n-focus-outline-sibling-rules($width, $height, $top, $left);
  }
}

@mixin c11n-focus-outline-block-after-rules() {
  position: relative;
  @include c11n-focus-outline-outer();
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
    pointer-events: none;
    @include c11n-focus-outline-inner();
  }
}

@mixin c11n-focus-outline-block-after {
  &:focus-visible {
    @include c11n-focus-outline-block-after-rules();
  }
}
