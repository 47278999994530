@import './slider-mixins';

.c11n-slider {
  @include slider-host;
  @include slider-child;

  &.c11n-slider--value-indicator {
    @include slider--value-indicator;
  }

  &.c11n-slider--top-labels {
    @include slider--top-labels;
  }

  &.c11n-slider--error {
    @include slider-child-error;
  }
}
