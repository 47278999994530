@import '../../variables';

@mixin date-picker-host {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  min-width: rem(180);
  height: auto;
  display: block;
  box-sizing: border-box;
}

@mixin date-picker-child {

  * {
    box-sizing: border-box;
  }

  .c11n-date-picker__focus-element {
    margin: 0;
  }

  .c11n-date-picker__box {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    background: $white;
    min-width: rem(180);
    height: rem(48);
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: visible;
    border: rem($c11n-size-border-width-thin) solid $default-color;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
}

@mixin date-picker--focus-within {

  .c11n-date-picker__box {
    @include c11n-focus-outline-inner();
  }

  .c11n-date-picker__focus-element {
    @include c11n-focus-outline-outer();
  }
}


@mixin date-picker--large-child {
  .c11n-date-picker__box {
    height: rem(64);
  }
}

@mixin date-picker--large {

  padding: 0 rem($c11n-size-space-1x) 0 rem($c11n-size-space-2x);
  min-height: rem($c11n-size-space-3x);
  @include c11n-font-rules('regular', 'lg');
  font-variant-numeric: tabular-nums;

  &[type='date'] {
    // TODO: wondering if this presents any maintenance concerns. We could always set our sticker sheet up a little differently to accomdate
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" stroke="%23040405" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0.500 2.501 L23.500 2.501 L23.500 23.501 L0.500 23.501 Z"></path><path d="M5.5 0.501L5.5 5.501"></path><path d="M18.5 0.501L18.5 5.501"></path><path d="M0.5 7.501L23.5 7.501"></path></svg>');
    background-size: rem($c11n-size-space-3x);
    background-position: right 1.25rem top 50%;
    background-repeat: no-repeat;
  }

  &[type='date'][value*='-'] {
    color: $default-color;
    @supports (-moz-appearance:none) {
      background-position: right 1.75rem top 50% !important;
    }
  }
}

@mixin date-picker--error {
  .c11n-date-picker__box {
    border: rem($c11n-size-border-width-thin) solid $error-color;
  }
}

@mixin date-picker--medium {

  &[type='date'] {
    // TODO: wondering if this presents any maintenance concerns. We could always set our sticker sheet up a little differently to accomdate
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"><path d="M12.25,1.5h-1.5V.75a.75.75,0,0,0-1.5,0V1.5H4.75V.75a.75.75,0,0,0-1.5,0V1.5H1.75A1.752,1.752,0,0,0,0,3.25v9A1.752,1.752,0,0,0,1.75,14h10.5A1.752,1.752,0,0,0,14,12.25v-9A1.752,1.752,0,0,0,12.25,1.5ZM1.5,3.25A.25.25,0,0,1,1.75,3h1.5v.75a.75.75,0,0,0,1.5,0V3h4.5v.75a.75.75,0,0,0,1.5,0V3h1.5a.25.25,0,0,1,.25.25v2a.25.25,0,0,1-.25.25H1.75a.25.25,0,0,1-.25-.25Z"></path></svg>');
    background-size: rem(14) rem(14);
    background-position: right 1rem top 50%;
    background-repeat: no-repeat;
  }

  &[type='date'][value*='-'] {
    color: $default-color;
    @supports (-moz-appearance:none) {
      background-position: right 1.5rem top 50%;
    }
  }
}

@mixin date-picker-input-host {
  align-items: center;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  top: 0;
  border: 0 none;
  box-shadow: 0 0 0 0 transparent;
  @include c11n-font-rules('regular', 'md');
  font-variant-numeric: tabular-nums;
  text-transform: uppercase;
  text-align: left;
  outline: none;
  width: 100%;
  min-height: rem(17);
  overflow: visible;
  padding: 0 rem($c11n-size-space-0point5x) 0 rem($c11n-size-space-2x);
  color: $c11n-color-ink-400;
  background-color: $white;
  text-transform: uppercase;

  &[type='date']::-webkit-date-and-time-value {
    text-align:left;
  }

  &[type='date']:focus {
    outline: none;
  }

  &:focus {
    color: $default-color;
    outline: none;
  }

  &::placeholder {
    color: $placeholder-color;
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    padding: 0;
    background: transparent;
    height: rem(14);
    width: rem(14);
    left: auto;
    bottom: 0;
    right: rem(16);
    top: rem(6);
    outline: rem(2) solid transparent;
    outline-offset: rem(2);
  }

  &[type='date']::-webkit-calendar-picker-indicator:focus-visible {
    outline: rem(2) solid $focus-color;
    outline-offset: rem(2);
  }
}

@mixin date-picker-input-host--large {
  &[type='date']::-webkit-calendar-picker-indicator {
    height: rem(24);
    width: rem(24);
    right: rem(20);
  }
}
