@use 'sass:math';
@import '../../variables';

@mixin navigation-host {
  @include c11n-font-rules('bold', 'md');
  display: block;
  margin-top: rem($c11n-size-space-4x);
  margin-bottom: rem($c11n-size-space-6x);
  background: linear-gradient(
    to top,
    $c11n-color-ink-300 0,
    $c11n-color-ink-300 rem(1),
    $c11n-color-ink-000 rem(1),
    $c11n-color-ink-000 100%
  );
}

@mixin navigation--small-responsive {
  .c11n-navigation__inner-wrapper,
  .c11n-tabs__inner-wrapper {
    display: flex;
    > * {
      text-align: center;
      &:not(.c11n-nav-overflow):not(.c11n-tabs-overflow) {
        flex-grow: 1;
        padding-left: 0;
      }
    }
    .c11n-nav-item {
      max-width: calc(100% - #{rem(38)});
    }
    .c11n-nav-item,
    .c11n-tabs__tab {
      width: 100%;
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .c11n-nav-overflow,
  .c11n-tabs-overflow {
    width: rem($c11n-size-space-6x);
  }
}

@mixin navigation--equal-width {
  .c11n-navigation__inner-wrapper,
  .c11n-tabs__inner-wrapper {
    display: flex;

    .c11n-nav-item,
    .c11n-tabs__tab {
      flex: 1;
      text-align: center;
    }

    .c11n-tabs__tab-label,
    .c11n-nav-item__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@mixin navigation-child {
  .c11n-nav-item,
  .c11n-tabs__tab {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    margin-left: rem($c11n-size-space-3x);
    background: transparent;
    border: 0;
    @include c11n-link-underline-rules(
      $thickness: $c11n-size-border-width-pipe,
      $hoverthickness: $c11n-size-border-width-pipe
    );
    @include nav-item-and-overflow-trigger-styles();
    font: inherit;

    &:first-of-type,
    &.c11n-tabs__tab--hidden + .c11n-tabs__tab--active,
    &.c11n-nav-item--hidden + .active {
      margin-left: 0;
    }

    // TODO: Use BEM class style to change .active to be .c11n-nav-item--active, this would be a breaking change
    &:not(.c11n-tabs__tab--hidden)
      ~ .c11n-tabs__tab--hidden
      + .c11n-tabs__tab--active,
    &:not(.c11n-nav-item--hidden) ~ .c11n-nav-item--hidden + .active {
      margin-left: rem($c11n-size-space-3x);
    }
  }

  .c11n-nav-overflow,
  .c11n-tabs-overflow {
    padding-left: rem($c11n-size-space-1x);
  }

  .c11n-navigation__inner-wrapper,
  .c11n-tabs__inner-wrapper {
    font-weight: inherit;
    white-space: nowrap;
    display: inline;
  }
}

@mixin nav-item-and-overflow-trigger-styles() {
  color: $c11n-color-ink-800;
  display: inline-block;
  vertical-align: bottom;
  padding-top: rem($c11n-size-space-1point5x);
  padding-bottom: rem($c11n-size-space-1point5x);
  position: relative;
  @include c11n-focus-outline-block-after();

  &:focus-visible {
    z-index: 1;
  }

  &.c11n-tabs__tab {
    text-decoration: none;
  }

  &.active,
  &.c11n-tabs__tab--active {
    background-image: linear-gradient($c11n-color-red-600, $c11n-color-red-600);
    background-size: 100% rem($c11n-size-border-width-pipe);
    // TO DO:
    // The following code was added to support Windows High Contrast Mode
    // but it causes a visual defect on FireFox so another solution for WHCM must be found
    // text-decoration: underline;
    // text-decoration-color: transparent;
    // text-underline-offset: rem(5);
    // text-decoration-thickness: rem(4);
  }

  &.c11n-nav-item--hidden,
  &.c11n-tabs__tab--hidden {
    display: none;
  }

  &.c11n-tabs__tab--text-overflow,
  &.c11n-nav-item--text-overflow {
    display: grid;
  }
}

@mixin navigation--initializing {
  opacity: 0;
}
