@use 'sass:math';
@import '../../variables';

@mixin checkbox-host {
  --tile-label-focus-offset: #{rem(2px)};
  --tile-focus-element-offset: #{rem(5px)};
  --tile-focus-z-index: 2;
  --tile-hover-and-checked-z-index: 1;
  display: block;
  position: relative;
  @include c11n-font-rules();
  &:not(:last-of-type) {
    margin-bottom: rem($c11n-size-space-1x); 
  }
}

@mixin checkbox-child {
  .c11n-checkbox__focus-element {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }

  .c11n-checkbox__icons {
    pointer-events: none;
    position: absolute;
    top: rem(4);
    left: 0;
    width: rem(14);
    height: rem(14);
    box-sizing: content-box;
    border: solid rem($c11n-size-border-width-thin) $c11n-color-ink-800;
    background: $c11n-color-ink-000;
  }

  .c11n-checkbox__indeterminate,
  .c11n-checkbox__check {
    display: none;
    fill: $c11n-color-ink-000;
  }

  .c11n-checkbox__label {
    padding-right: rem($c11n-size-space-0point5x);
    padding-left: rem($c11n-size-space-3x);
    display: block;
    cursor: default;
    max-width: fit-content;
    position: relative;
    line-height: $c11n-line-height-md;
    // Needed to ensure checkbox keeps its height when there is no label content
    min-height: rem($c11n-size-space-3x);
  }
  .c11n-checkbox__label-content {
    color: $c11n-color-ink-800;
  }

  .c11n-checkbox__descriptive-label {
    display: none;
  }
}

@mixin checkbox-input--states {
  .c11n-checkbox__input {
    &:checked,
    &:indeterminate {
      ~ .c11n-checkbox__label .c11n-checkbox__icons {
        background-color: $c11n-color-ink-800;
      }
    }

    &:checked {
      ~ .c11n-checkbox__label .c11n-checkbox__icons .c11n-checkbox__check {
        display: block;
      }
    }

    &:disabled {
      ~ .c11n-checkbox__label {
        cursor: not-allowed;
        .c11n-checkbox__icons {
          opacity: 0.3;
        }
      }
    }
  }
}

@mixin checkbox-tile-label-checked-hover-rules {
  z-index: var(--tile-hover-and-checked-z-index);
  border-color: $c11n-color-ink-800;
}

@mixin checkbox-tile-label-checked-focus-rules {
  z-index: var(--tile-focus-z-index);
  border-color: $c11n-color-ink-800;
}

@mixin checkbox-input--states-tile {
  .c11n-checkbox__input {
    &:checked,
    &:hover:not(:disabled) {
      ~ .c11n-checkbox__label {
        @include checkbox-tile-label-checked-hover-rules;
      }
    }

    &:focus-visible {
      ~ .c11n-checkbox__label {
        @include checkbox-tile-label-checked-focus-rules;
      }
    }

    &:disabled {
      ~ .c11n-checkbox__label {
        border-color: $c11n-color-ink-300;
        cursor: not-allowed;
        
        .c11n-checkbox__icons {
          opacity: 0.3;
        }
      }
    }
  }
}

@mixin checkbox-input-host {
  opacity: 0;
  position: absolute;
  background: transparent;
  top: rem(2);
  left: rem(-2);
  z-index: -1;
  pointer-events: all;
  :focus {
    outline: none;
  }
}

@mixin checkbox-group-host {
  font-family: $default-font;
}

@mixin checkbox-group-child {
  fieldset {
    border: 0 none;
    padding: 0;
    margin: 0;
  }
  .c11n-checkbox-group__legend {
    margin: 0 0 rem($c11n-size-space-1x) 0;
    padding-left: 0;
    @include c11n-font-rules('bold', 'md');
    color: $c11n-color-ink-800;
  }
  .c11n-checkbox-group__error {
    margin-top: rem($c11n-size-space-1point5x);
  }
}

@mixin checkbox--indeterminate {
  pointer-events: none;
}

@mixin checkbox--indeterminate-clickable {
  pointer-events: all;
}

@mixin checkbox-input--indeterminate {
  .c11n-checkbox__input {
    ~ .c11n-checkbox__label {
      .c11n-checkbox__icons {
        background-color: $c11n-color-ink-800;
        .c11n-checkbox__indeterminate {
          display: block;
        }
        .c11n-checkbox__check {
          display: none;
        }
      }
    }
  }
}

@mixin checkbox-input--indeterminate-tile {
  .c11n-checkbox__input {
    ~ .c11n-checkbox__label {
      @include checkbox-tile-label-checked-hover-rules;
    }

    &:disabled {
      ~ .c11n-checkbox__label {
        border-color: $c11n-color-ink-300;
      } 
    }
  }
}

@mixin checkbox--focused-label {
  .c11n-checkbox__input:focus-visible {
    ~ .c11n-checkbox__label {
      @include c11n-focus-outline-inner();
      .c11n-checkbox__focus-element {
        @include c11n-focus-outline-outer();
      }
    }
  }
}

@mixin checkbox--hide-label {
  .c11n-checkbox__label {
    padding-left: 0;
    padding-right: 0;
    width: rem($c11n-size-space-2x); // width of checkbox square
    max-width: rem($c11n-size-space-2x); // width of checkbox square
  }
}

@mixin checkbox-sr-only {
  // TODO: let's make global mixin for sr-only
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
}

@mixin checkbox--tile {
  @include checkbox--focused-label;
  @include checkbox-tile--outline;
  @include checkbox-tile;
  @include checkbox-input--states-tile;
}

@mixin checkbox-tile--outline {
  input.c11n-checkbox__input {
    &:focus-visible {
      & ~ .c11n-checkbox__label {
        outline-offset: var(--tile-label-focus-offset);

        &:before, &:after {
          display: none;
        }

        .c11n-checkbox__focus-element {
          outline-offset: var(--tile-focus-element-offset);
        }
      }
    }
  }
}

@mixin checkbox-tile {
  background-color: $c11n-color-ink-000;
  height: 100%;

  &:not(:last-of-type) {
    margin-bottom: #{rem(-$c11n-size-border-width-thin)};
  }

  &.c11n-checkbox--bold-label {
    .c11n-checkbox__label-content {
      @include c11n-font-rules('bold', 'md');
    }
  }

  .c11n-checkbox__label {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: rem($c11n-size-space-3x) rem($c11n-size-space-3x) rem($c11n-size-space-3x) rem($c11n-size-space-6x);
    border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-300;

    span + svg {
      top: calc(#{rem($c11n-size-space-3x)} + #{rem($c11n-size-space-0point5x)});
      left: rem($c11n-size-space-3x);
    }
  }

  .c11n-checkbox__label-content {
    position: relative;
    &::before {
      left: calc(#{rem(-$c11n-size-space-3x)} - #{rem($c11n-size-space-1x)});
    }
  }

  .c11n-checkbox__label--bold {
    @include c11n-font-rules('bold', 'md');
  }

  .c11n-checkbox__descriptive-label {
    @include c11n-font-rules();
    display: block;
    padding-top: rem($c11n-size-space-1x);
  }

  .c11n-checkbox__focus-element {
    height: auto;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

}