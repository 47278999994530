@import '../../variables';

@mixin internal-callout-host {
  --padding-border-offset: calc(#{rem($c11n-size-space-2x)} - #{rem($c11n-size-border-width-thin)});

  --header-padding-bottom: #{rem($c11n-size-space-2x)};
  --header-padding-right: var(--padding-border-offset);
  --body-padding-top: #{rem($c11n-size-space-2x)};
  --body-padding-left: var(--padding-border-offset);

  display: block;
  border: rem($c11n-size-border-width-thin) solid $c11n-color-internal-800;
}

@mixin internal-callout-child {
  .c11n-internal-callout {
    &__header {
      padding: var(--padding-border-offset) var(--header-padding-right) var(--header-padding-bottom) var(--padding-border-offset);
      background-color: $c11n-color-internal-800;
      color: $white;
      white-space: nowrap;
      > * {
        @include c11n-font-rules('bold','md');
      }
    }

    &__body {
      padding: var(--body-padding-top) var(--padding-border-offset) var(--padding-border-offset) var(--body-padding-left);
      background-color: $c11n-color-internal-100;
      width: 100%;
    }
  }
}

@mixin internal-callout--horizontal {
  --header-padding-bottom: var(--padding-border-offset);
  --header-padding-right: #{rem($c11n-size-space-2x)};
  --body-padding-top: var(--padding-border-offset);
  --body-padding-left: #{rem($c11n-size-space-2x)};

  display: flex;
}