@import '../../variables';

@mixin input-host {
  $self: &;
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  background: transparent;
  overflow: visible;
}

@mixin input--large {
  height: rem($c11n-size-space-8x);
  @include c11n-font-rules('regular', 'lg');
  font-variant-numeric: tabular-nums;
  padding: 0 rem($c11n-size-space-2x) rem(4) rem($c11n-size-space-2x);
}

@mixin input--error {
  border: rem($c11n-size-border-width-thin) solid $error-color;
}

@mixin input-input-host {
  * {
    box-sizing: border-box;
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  @include c11n-font-rules('regular', 'md');
  font-variant-numeric: tabular-nums;
  width: 100%;
  height: rem($c11n-size-space-6x);
  overflow: visible;
  margin: 0;
  padding: 0 rem($c11n-size-space-2x) rem($c11n-size-space-0point5x)
    rem($c11n-size-space-2x);
  color: $default-color;
  background: $c11n-color-ink-000;
  border: rem($c11n-size-border-width-thin) solid $default-color;
  border-radius: 0;
  
  &::placeholder {
    color: $placeholder-color;
  }

  &:focus {
    outline: none;
  }
}