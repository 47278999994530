@import '../../variables';
@import '../button';
@import 'dialog-polyfill/dialog-polyfill';

@mixin modal-dialog-entrypoint-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $scrim-color;
  animation: show $animation-timing-expand-large $animation-easing-standard-web;

  @media print {
    background-color: rgba(255, 255, 255, 1);
  }
}

@mixin modal-dialog-entrypoint-child {
  dialog.c11n-modal-dialog {
    width: 100%;
    overflow: visible;
    display: none;
    color: $c11n-color-ink-800;
  }
  dialog.c11n-modal-dialog:not([open]) {
    display: none;
  }
  dialog.c11n-modal-dialog[open] {
    display: block;
    animation: show $animation-timing-expand-large $animation-easing-standard-web;
    &::backdrop {
      @include modal-dialog-entrypoint-backdrop;
    }
  }
  .c11n-modal-dialog--closing {
    opacity: 0;
    transition: opacity $animation-timing-collapse-large;
    &::backdrop {
      opacity: 0;
      transition: opacity $animation-timing-collapse-large;
    }
    & + .backdrop {
      opacity: 0;
      transition: opacity $animation-timing-collapse-large;
    }
  }
  @keyframes show {
    from { opacity: 0;}
    to { opacity: 1;}
  }
  @keyframes hide {
    to { opacity: 0; }
  }
  .c11n-modal-dialog {
    --documentMaxWidth: 41.25rem;
    --maxWidth: calc(var(--documentMaxWidth) + 4rem);
    $headerFooterSpace: rem(160);
    $minWindowHeightForMargin: rem(724);

    @include c11n-font-rules('regular','md');

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $c11n-elevation-z-index-3200;
    border: 0 none;
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    max-width: var(--maxWidth);
    height: fit-content;
    min-height: fit-content;
    max-height: 100%;
    
    @media screen and (min-width: $c11n-grid-breakpoint-sm) {
      left: 50%;
      top: 0;
      transform: translate(-50%, $c11n-size-space-16x);
      min-width: auto;
      &--full-height {
        transform: translate(-50%, 0%);
      }
    }
    @media screen and (min-width: $c11n-grid-breakpoint-sm) and (max-height: $minWindowHeightForMargin) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      min-width: auto;
    }
    @media screen and (max-width: var(--maxWidth)) {
      min-width: 100%;
    }
    @media screen and (min-width: var(--maxWidth)) {
      min-width: var(--maxWidth);
    }    
  }

  dialog.c11n-modal-dialog--content-width {
    @media screen and (min-width: $c11n-grid-breakpoint-md) {
      width: fit-content;
    }
  }
  
}
