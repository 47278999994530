@import '../../variables';

$border-width: $c11n-component-button-border-width;
$shakeAmount: #{rem($c11n-size-space-1x)};

@mixin button-host {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  border: 0;
  font-family: $c11n-component-button-font-family;
  font-weight: $c11n-component-button-font-weight;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border-radius: rem($c11n-size-space-4x);
  @include c11n-focus-outline-block-after();

  &:hover {
    .c11n-button__box {
      transition-duration: $animation-timing-expand-small;
    }
  }

  &:focus-visible {
    .c11n-button__box {
      transition: none;
    }
  }
}

@mixin button__box-shared {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: rem($c11n-component-button-font-size-medium);
  border-radius: rem($c11n-size-space-4x);
  border: rem($border-width) solid $black;
  margin: 0;
  width: auto;
  color: $white;
}

@mixin button__box-before {
  .c11n-button__box {
    &::before {
      width: rem($c11n-component-button-height-medium);
      height: rem($c11n-component-button-height-medium);
    }
  }
  &.c11n-button--small {
    .c11n-button__box {
      &::before {
        width: rem($c11n-component-button-height-small);
        height: rem($c11n-component-button-height-small);
      }
    }
  }
}

@mixin button__label {
  .c11n-button__label {
    @include c11n-font-rules('bold', 'md');
    @include c11n-text-crop-md();
  }
  &.c11n-button--small {
    .c11n-button__label {
      @include c11n-font-rules('bold', 'sm');
    }
  }
}

@mixin button-icon-black-fill {
  svg {
    use {
      fill: $black;
    }
  }
}

@mixin button-icon-white-fill {
  svg {
    use {
      fill: $white;
    }
  }
}

@mixin button-icon-black-stroke {
  svg {
    use {
      stroke: $black;
    }
  }
}

@mixin button-icon-white-stroke {
  svg {
    use {
      stroke: $white;
    }
  }
}

@mixin button-icon-transition {
  svg {
    use {
      transition-property: fill, stroke;
      transition-duration: $animation-timing-collapse-small;
      transition-timing-function: $animation-easing-standard-web;
      transform-style: flat;
      backface-visibility: hidden;
    }
  }
}

@mixin button-hover-focus {
  .c11n-button__box {
    background-color: $white;
    color: $c11n-component-button-background-color-primary-default;
  }
}

@mixin button-secondary-hover-focus {
  .c11n-button__box {
    background-color: $c11n-component-button-background-color-primary-default;
    color: $white;
  }
}

@mixin on-dark-hover-focus-rules {
  @include button-secondary-hover-focus;
}

@mixin on-dark-secondary-hover-focus-rules {
  @include button-hover-focus;
}

@mixin button-invalid-styles {
  animation-name: button-shake;
  animation-duration: $animation-timing-expand-large;
  animation-timing-function: $animation-easing-standard-web;
  animation-direction: normal;
}

@keyframes button-shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-$shakeAmount); }
  40% { transform: translateX($shakeAmount); }
  60% { transform: translateX(-$shakeAmount); }
  80% { transform: translateX($shakeAmount); }
  100% { transform: translateX(0); }
}