@import './textarea-mixins';

.c11n-textarea {
  @include textarea-host;
  @include c11n-focus-outline-sibling(
    '.c11n-textarea__textarea', 
    '.c11n-textarea__focus-element', 
    $width: 100%,
    $height: 100%,
    $top: 0,
    $left: 0
  );

  .c11n-textarea__textarea {
    @include textarea-textarea-host;
  }

  &.c11n-textarea--large {
    .c11n-textarea__textarea {
      @include textarea--large;
    }
  }

  &.c11n-textarea--error {
    .c11n-textarea__textarea {
      @include textarea--error;
    }
  }

  &.c11n-textarea--disabled {
    .c11n-textarea__textarea {
      @include textarea--disabled;
    }
  }

  &:not(.c11n-textarea--has-resize) {
    .c11n-textarea__textarea {
      @include textarea--not-resize;
    }
  }
}