@import './radio-mixins';

.c11n-radio {

  @include radio-host;
  @include radio-child;
  @include radio-input--states;
  @include radio-input--states-tile;

  @include c11n-hover-sibling-block-before('.c11n-radio__input', '.c11n-radio__label', '.c11n-radio__label-content');

  .c11n-radio__input {
    @include radio-input-host;
  }

  &.c11n-radio--focused-label {
    @include radio--focused-label;
  }

  &.c11n-radio--hide-label {
    @include radio--hide-label;
  }

  &:not(.c11n-radio--focused-label) {
    @include c11n-focus-outline-sibling('.c11n-radio__input', '.c11n-radio__label');
  }
}

.c11n-radio-group {
  @include radio-group-host;
  @include radio-group-child;

  .c11n-radio-group__legend--hidden {
    @include radio-sr-only;
  }

  &.c11n-radio-group--tiles {
    @include radio--tiles;
  }

}