@import '../../variables';

@mixin radio-host {
  --tile-label-focus-offset: #{rem(2px)};
  --tile-focus-element-offset: #{rem(5px)};
  --tile-focus-z-index: 2;
  --tile-hover-and-checked-z-index: 1;
  position: relative;
  display: block;
  &:not(:last-of-type) {
    margin-bottom: rem($c11n-size-space-1x); 
  }
}

@mixin radio-child {
  .c11n-radio__focus-element {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
  }

  .c11n-radio__label {
    position: relative;
    padding-right: rem($c11n-size-space-0point5x);
    display: block;
    padding-left: rem($c11n-size-space-3x);
    @include c11n-font-rules();
    color: $default-color;
    cursor: default;
    max-width: fit-content;
    line-height: $c11n-line-height-md;

    &-content {
      display: inline-block;
      min-width: 1ch;
      min-height: 1ch;
      color: $c11n-color-ink-800;
    }

    svg {
      display: block;
      position: absolute;
      top: rem(4);
      left: 0;
      width: rem($c11n-size-space-2x);
      height: rem($c11n-size-space-2x);
      circle {
        &.background {
          fill: $c11n-color-ink-000;
          stroke: $c11n-color-ink-800;
        }
        &.foreground {
          fill: transparent;
        }
      }
    }

    &::before,
    &::after {
      border-radius: 50%;
    }
  }

  .c11n-radio__hint {
    display: flex;
    flex-direction: row;
    &__icon {
      display: none;
      margin: 0 rem(2) 0 0;
    }
  }

  .c11n-radio__descriptive-label {
    display: none;
  }
}

@mixin radio-input-host {
  opacity: 0;
  position: absolute;
}

@mixin radio-group-host {
  font-family: $default-font;
}

@mixin radio-group-child {
  fieldset {
    border: 0 none;
    padding: 0;
    margin: 0;
  }
  .c11n-radio-group__legend {
    margin: 0 0 rem($c11n-size-space-1x) 0;
    padding-left: 0;
    @include c11n-font-rules('bold', 'md');
    color: $c11n-color-ink-800;
  }
  .c11n-radio-group__error {
    margin-top: rem($c11n-size-space-1point5x);
  }
}

@mixin radio-input--states {
  .c11n-radio__input {
    &:checked {
      ~ .c11n-radio__label {
        svg {
          circle {
            &.foreground {
              fill: $c11n-color-ink-800 !important;
            }
          }
        }
      }
    }

    &:disabled {
      ~ .c11n-radio__label {
        cursor: not-allowed;
        svg {
          opacity: 0.3;
        }
      }
    }
  }
}

@mixin radio-input--states-tile {
  .c11n-radio {
    .c11n-radio__input {
      &:checked,
      &:hover:not(:disabled) {
        ~ .c11n-radio__label {
          z-index: var(--tile-hover-and-checked-z-index);
          border-color: $c11n-color-ink-800;
        }
      }

      &:focus {
        ~ .c11n-radio__label {
          z-index: var(--tile-focus-z-index);
          border-color: $c11n-color-ink-800;
        }
      }

      &:disabled {
        ~ .c11n-radio__label {
          cursor: not-allowed;
          border-color: $c11n-color-ink-300;
          
          svg {
            opacity: 0.3;
          }
        }
      }
    }
  }
}

@mixin radio--focused-label {
  .c11n-radio__input:focus-visible {
    ~ .c11n-radio__label {
      @include c11n-focus-outline-inner();
      .c11n-radio__focus-element {
        @include c11n-focus-outline-outer();
      }
    }
  }
}

@mixin radio--hide-label {
  .c11n-radio__label {
    padding-left: 0;
    padding-right: 0;
    max-width: rem($c11n-size-space-2x); // width of radio circle
    width: rem($c11n-size-space-2x); // width of radio circle
  }
}

@mixin radio-sr-only {
  // TODO: let's make global mixin for sr-only
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
}

@mixin radio--tiles {
  @include radio--focused-label;
  @include radio-tile--outline;
  @include radio-tile;
  @include radio-input--states-tile;
}

@mixin radio-tile--outline {
  .c11n-radio {
    .c11n-radio__input {
      &:focus-visible {
        & ~ .c11n-radio__label {
          outline-offset: var(--tile-label-focus-offset);
          &:before, &:after {
            display: none;
          }
  
          .c11n-radio__focus-element {
            outline-offset: var(--tile-focus-element-offset);
          }
        }
      }
    }
  }
}

@mixin radio-tile {
  .c11n-radio {
    height: 100%;
    
    &:not(:last-of-type) {
      margin-bottom: #{rem(-$c11n-size-border-width-thin)};
    }

    &.c11n-radio--bold-label {
      .c11n-radio__label-content {
        @include c11n-font-rules('bold', 'md');
      }
    }
  
    .c11n-radio__label {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding: rem($c11n-size-space-3x) rem($c11n-size-space-3x) rem($c11n-size-space-3x) rem($c11n-size-space-6x);
      border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-300;
      background-color: $c11n-color-ink-000;
  
      span + svg {
        top: calc(#{rem($c11n-size-space-3x)} + #{rem($c11n-size-space-0point5x)});
        left: rem($c11n-size-space-3x);
      }
    }
  
    .c11n-radio__label-content {
      position: relative;
      &::before {
        left: calc(#{rem(-$c11n-size-space-3x)} - #{rem($c11n-size-space-1x)});
      }
    }
  
    .c11n-radio__descriptive-label {
      @include c11n-font-rules();
      display: block;
      padding-top: rem($c11n-size-space-1x);
    }
  
    .c11n-radio__focus-element {
      height: auto;
      width: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}