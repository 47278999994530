@import '../../variables';

@mixin switch-host {
  --component-height: #{rem($c11n-size-space-3point5x)};
  --component-width: #{rem($c11n-size-space-5point5x)};
  --outline-width: #{rem($c11n-size-border-width-thick)};
  --ghost-scale: 0;
  padding: 0;
  border: 0 none;
  border-radius: 0;
  user-select: none;
  appearance: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--component-height);
  gap: rem($c11n-size-space-0point5x);
  position: relative;
  &[aria-checked="true"] {
    .c11n-switch__box__thumb {
      color: $white;
      transform: translate(rem($c11n-size-space-2x),0);
      background: $black;
      [data-container="c11n-icon-container"] {
        visibility: visible;
      }
    }
    .c11n-switch__box__ghost {
      transform: translate(rem($c11n-size-space-2x),0) scale(var(--ghost-scale));
    }
  }
  &:focus, .c11n-switch:hover {
    background: transparent;
    outline: 0 none;
  }
  &:focus-visible span.c11n-switch__box {
    outline: var(--outline-width) solid $focus-color;
  }
  &:focus-visible span.c11n-switch__box::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--component-width);
    width: 100%;
    height: 100%;
    outline: var(--outline-width) solid $white;
    outline-offset: var(--outline-width);
  }
  &:hover .c11n-switch__box__ghost {
    opacity: 1;
    --ghost-scale: 1;
  }
  &:focus-visible .c11n-switch__box__ghost {
    opacity: 1;
    --ghost-scale: 1;
  }
}

@mixin switch--leading {
  flex-direction: row-reverse;
}

@mixin switch-child {
  $bar-width: rem($c11n-size-space-4point5x);
  $bar-height: rem($c11n-size-space-1point5x);
  .c11n-switch {
    &__box {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: var(--component-width);
      height: 100%;
      min-width: var(--component-width);
      box-sizing: border-box;
      background: transparent;
      margin: 0;
      &__bar {
        width: $bar-width;
        height: $bar-height;
        box-sizing: border-box;
        border-radius: rem($c11n-size-space-4x);
        background: $c11n-color-ink-300;
      }
      &__ghost {
        position: absolute;
        left: rem(-2);
        top: rem(-2);
        width: rem($c11n-size-space-4x);
        height: rem($c11n-size-space-4x);
        background: $ghost;
        border-radius: 50%;
        opacity: 0;
        transform: scale(var(--ghost-scale)) translate(0,0);
        transform-origin: center;
        transition-duration: $animation-timing-expand-small;
        transition-property: transform, opacity;
      }
      &__thumb {
        position: absolute;
        box-sizing: border-box;
        top: rem($c11n-size-space-0point5x);
        left: rem($c11n-size-space-0point5x);
        right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: rem($c11n-size-border-width-thin) solid $black;
        border-radius: 50%;
        height: rem($c11n-size-space-2point5x);
        width: rem($c11n-size-space-2point5x);
        background: $white;
        box-shadow: $c11n-elevation-box-shadow-400;
        transition: $animation-timing-expand-small transform;
        transition-timing-function: $animation-easing-standard-web;
        opacity: 1;
        transform: translate(0,0);
        padding: 0;
        [data-container="c11n-icon-container"] {
          visibility: hidden;
        }
      }
    }
    &__label {
      display: flex;
      height: rem($c11n-size-space-3x);
      justify-content: flex-start;
      align-items: flex-start;
      font-size: rem($c11n-size-typography-xs);
      line-height: rem($c11n-size-line-height-xs);
      width: fit-content;
      text-align: left;
      padding-top: rem(3);
    }
  }
}
