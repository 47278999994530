@import './client-only-action-mixins';

.c11n-client-only-action {
  @include client-only-action-host;

  &--internal {
    @include client-only-action--cursor;
    @include client-only-action--internal;
  }

  &--on-dark {
    @include client-only-action--on-dark;
  }

  &--has-floating-icon {
    @include client-only-action--has-floating-icon;
  }

  &:has(.c11n-link) {
    @include client-only-action--has-link;
  }
}