@import './spinner-mixins.scss';

.c11n-spinner {
  @include spinner-host;
  @include spinner-child;

  &.c11n-spinner--dark {
    @include spinner--dark;
  }

  &.c11n-spinner--small {
    @include spinner--small;
  }

  &.c11n-spinner--medium {
    @include spinner--medium;
  }

  &.c11n-spinner--large {
    @include spinner--large;
  }

  &.c11n-spinner--restart {
    @include spinner--restart;
  }
}
