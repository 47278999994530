/*
Values from:
http://confluence.vanguard.com:8090/display/FRODO/Branding
http://confluence.vanguard.com:8090/download/attachments/63390433/Vanguard%20Solid%20Colors.pdf?api=v2
http://confluence.vanguard.com:8090/download/attachments/63390433/Vanguard%20Solids%20and%20Tints.pdf?api=v2
*/

/**************************
 * Core corporate palette *
 **************************/

$base-font-color: rgb(26, 26, 26); // #1A1A1A

// Vanguard Colors
$vg-red: rgb(150, 21, 29) !default; // #96151D
$vg-white: white !default; // #FFFFFF
$vg-black: black !default; // #000000

// Support reds
$vg-orange-red: rgb(247, 68, 14) !default; // #F7440E
$vg-bright-red: rgb(245, 25, 20) !default; // #F51914
$vg-mid-red: rgb(194, 0, 41) !default; //#c20029
$vg-dark-red: rgb(98, 38, 57) !default; // #622639

// Neutrals
$vg-cool-gray: rgb(102, 102, 102) !default; // #666666
$vg-warm-gray: rgb(168, 160, 147) !default; // #A8A093
$vg-putty: rgb(224, 219, 208) !default; // #E0DBD0
$vg-gray-90: rgb(229, 229, 229) !default; // #E5E5E5
$vg-gray-19: rgb(48, 48, 48) !default; // #303030
$vg-gray-10: rgb(26, 26, 26) !default; // #1A1A1A
$vg-gray-chateau: rgb(160, 165, 169) !default; // #A0A5A9
$vg-light-gray: rgb(210, 210, 210) !default; // #D2D2D2
$box-shadow-gray: #c7c7c7 !default;
$modern-header-gray: #f5f6f6 !default;
$modern-subtitle-gray: #6c6c6c !default;
$background-gray: #f9f9f9 !default;

/***************************
 * Secondary color palette *
 ***************************/
$vg-orange: rgb(227, 114, 34) !default; // #E37222
$vg-yellow: rgb(234, 186, 31) !default; // #EABA1F
$vg-olive: rgb(154, 132, 25) !default; // #9A8419
$vg-lime: rgb(168, 180, 0) !default; // #A8B400
$vg-green: rgb(105, 146, 58) !default; // #69923A
$vg-aqua: rgb(0, 154, 166) !default; // #009AA6
$vg-teal: rgb(0, 103, 120) !default; // #006778
$vg-slate-blue: rgb(68, 105, 125) !default; // #44697D
$vg-navy-blue: rgb(0, 60, 105) !default; // #003C69
$vg-royal-blue: rgb(0, 82, 147) !default; // #005293
$vg-bright-blue: rgb(0, 82, 147) !default; // #0098DB
$vg-purple: rgb(128, 55, 155) !default; // #80379B
$vg-brown: rgb(90, 49, 28) !default; // #5A311C
$vg-cerulean: rgb(0, 125, 179) !default; // #007DB3

/*********************
 * Additional Colors *
 *********************/

// Link Colors
$vg-link-hover: rgb(150, 21, 29) !default; // #96151D
$vg-red-medium: rgb(125, 17, 25) !default; // #7D1119
$vg-red-dark: rgb(94, 13, 19) !default; // #5E0D13
$vg-link-active: rgb(141, 73, 0) !default; // #8D4900
$vg-link-visited: rgb(153, 122, 89) !default; // #997A59

// Typography
$vg-cool-gray-9: rgb(51, 51, 51) !default; // #333333
$vg-cool-gray-7: rgb(102, 102, 102) !default; // #666666
$vg-cool-gray-5: rgb(44, 44, 44) !default; // #999999

// Internal and messaging
$vg-messaging-error-light: rgb(223, 184, 187) !default; // #DFB8BB
$vg-messaging-info-light: rgb(250, 242, 187) !default; // #FAF2BB
$vg-messaging-error: rgb(218, 0, 0) !default; // #DA0000
$vg-internal-green: rgb(187, 207, 157) !default; // #BBCF9D
$vg-internal-green-light: rgb(211, 224, 197) !default; // #D3E0C5

// Asset class categories
$vg-stocks: rgb(150, 21, 29) !default; // #96151D
$vg-bonds: rgb(0, 82, 147) !default; // #005293
$vg-money-market: rgb(105, 146, 58) !default; // #69923A
$vg-balanced: rgb(128, 55, 155) !default; // #80379B
$vg-international: rgb(240, 171, 0) !default; // #F0AB00

//Custom
$gray: rgb(255, 255, 255) !default; // #fff
$gray-light: rgb(221, 221, 221) !default; //#DDDDDD
$half-white: rgb(216, 216, 216) !default; //#D8D8D8
$blazing-blue: rgb(0, 116, 201) !default; //#0074C9
$gray-dark: rgb(120, 120, 120) !default; //#787878
$gray-superlight: rgb(211, 211, 211) !default; //#d3d3d3
$gray-alert-hover: rgb(247, 247, 247) !default; //#f7f7f7
$hypothetical-yellow: rgba(255, 252, 242, 255) !default; //#FFFCF2
$caution-orange: #d26e2f !default;
$modern-link-blue: #145bff !default;
$modern-subtitle-black: #040505 !default;
$modern-want-help: #def5f0 !default;
$black-transparent-light: rgba(0, 0, 0, 0.125) !default;