@import '../../variables';

@mixin textarea-host {
  display: block;
  position: relative;
  /*
    TODOS:
    - allowing horizontal resize is prohibitive to focus mixin
  */
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  line-height: 0;
}

@mixin textarea--large { 
  @include c11n-font-rules('regular', 'lg');
}

@mixin textarea--error {
  border: rem($c11n-size-border-width-thin) solid $error-color;
}

@mixin textarea--disabled {
  cursor: not-allowed;
  pointer-events: none;
}

@mixin textarea--not-resize {
  resize: none;
}

@mixin textarea-textarea-host {
  * {
    box-sizing: border-box;
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: $default-color;
  &::placeholder {
    color: $placeholder-color;
  }
  display: block;
  width: 100%;
  margin: 0;
  padding: rem($c11n-size-space-1point5x) rem($c11n-size-space-2x);
  @include c11n-font-rules('regular', 'md');
  &:focus {
    color: $default-color;
    outline: none;
  }
  border: rem($c11n-size-border-width-thin) solid $default-color;
  border-radius: 0;
  position: relative;
  resize: vertical;
}

