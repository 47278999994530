@import '../../variables';

@mixin client-only-action-host {
  --icon-width: #{rem(14)};
  --padding-right: calc(#{rem($c11n-size-space-0point5x)} + var(--icon-width));
  --link-padding-bottom: #{rem(3)};
}

@mixin client-only-action--internal {
  display: inline-block;
  position: relative;
  width: fit-content;
  padding-right: var(--padding-right);
  color: $c11n-color-alert-400;
  cursor: not-allowed;

  [data-container="c11n-icon-container"] {
    position: absolute;
    top: 50%;;
    right: 0;
    transform: translateY(-50%);
  }
}

@mixin client-only-action--cursor {
  * {
    cursor: not-allowed;
  }
}

@mixin client-only-action--has-link {
  [data-container="c11n-icon-container"] {
    top: calc(50% + var(--link-padding-bottom));
  }
}

@mixin client-only-action--on-dark {
  color: $c11n-color-ink-000;
}

@mixin client-only-action--has-floating-icon {
  --padding-right: 0;

  [data-container="c11n-icon-container"] {
    left: calc(100% + rem($c11n-size-space-0point5x));
  }
}