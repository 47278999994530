@import '../../variables';
@import '../link/link-mixins';

@mixin breadcrumbs-list-host {
  --space-between: #{rem($c11n-size-space-2x)};
  --overflow-trigger-padding-bottom: #{rem(3)};
  --link-height: #{rem($c11n-size-space-3x)};
  --focus-offset: calc(#{rem($c11n-size-space-0point5x)} + #{rem($c11n-size-border-width-thick)});

  .c11n-breadcrumbs {
    &__list {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      white-space: nowrap;
      max-width: fit-content;
    }

    &__item {
      @include breadcrumbs-item();

      &:first-of-type {
        padding-left: 0;
      }
      
      &--current-page {
        @include breadcrumbs-item--current-page();
      }

      &--overflow {
        @include breadcrumbs-item--overflow();
      }

      ::ng-deep {
        .c11n-link {
          @include breadcrumbs-item-link;
        }

        .c11n-nav-overflow__trigger {
          @include breadcrumbs-overflow-trigger();
        }
      }
    }
  }
}

@mixin breadcrumbs-item {
  display: flex;
  align-items: flex-start;
  padding-left: var(--space-between);
  margin-left: 0;

  &::after {
    content: '/';
  }
}

@mixin breadcrumbs-item-link {
  display: flex;
  margin-right: var(--space-between);
}

@mixin breadcrumbs-item--current-page {
  @include c11n-font-rules('regular', 'md');
  color: $c11n-color-ink-800;
  min-width: rem(44); 

  &::after {
    content: '';
  }
}

@mixin breadcrumbs-item--overflow {
  overflow: hidden;
  padding: var(--focus-offset);
  margin: calc(var(--focus-offset) * -1);
  padding-left: var(--space-between);
  margin-left: 0;

  span {
    @include breadcrumbs-item-span--overflow;
  }

  ::ng-deep {
    .c11n-link {
      @include breadcrumbs-item-link--overflow;
    }

    span {
      @include breadcrumbs-item-span--overflow;
    }
  }
}

@mixin breadcrumbs-item-link--overflow {
  min-width: rem(44); 
}

@mixin breadcrumbs-item-span--overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin breadcrumbs-item--hide-trailing-slash {
  .c11n-breadcrumbs__item:last-of-type {
    &::after {
      content: '';
    }
  
    ::ng-deep {
      .c11n-link {
        @include breadcrumbs-item-link--hide-trailing-slash;
      }
    }
  }
}

@mixin breadcrumbs-item-link--hide-trailing-slash {
  margin-right: 0;
}

// Overrides for integrating nav-overflow into breadcrumbs. 
// Potentially remove when nav-overflow trigger is decoupled 
// from nav-overflow menu in v2.0.
@mixin breadcrumbs-overflow-trigger {
  @include breadcrumbs-overflow-trigger-underline;

  position: relative;
  margin-right: var(--space-between);
  width: fit-content !important;
  padding: 0 !important;
  padding-bottom: var(--overflow-trigger-padding-bottom) !important;
  border-radius: 0 !important;
  color: $interactive-color !important;

  .c11n-overflow-menu-small {
    color: transparent !important;
  }

  &::before {
    background-color: transparent !important;
  }
  
  &::after {
    content: '...' !important;
    position: absolute;
    @include c11n-font-rules('bold', 'md');
    bottom: #{rem($c11n-text-md-bold-underline-padding)} !important;
    left: 50%;
    transform: translateX(-50%);
  }

  &:focus-visible::after {
    content: '...' !important;
    transform: translate(-50%, -#{rem($c11n-text-md-bold-underline-padding)});
    height: calc(100% + #{rem($c11n-text-md-bold-underline-padding)}) !important;
  }
}

@mixin breadcrumbs-overflow-menu--center {
  left: calc(50% - var(--space-between) / 2);
  transform: translateX(-50%) !important;
}

@mixin breadcrumbs-overflow-menu--start {
  left: 0 !important;
  transform: translateX(0) !important;
}

@mixin breadcrumbs-overflow-menu--end {
  right: 0 !important;
  transform: translateX(calc(var(--space-between) * -1)) !important;
}

@mixin breadcrumbs-overflow-trigger-underline {
  background-image: linear-gradient($c11n-color-ink-800, $c11n-color-ink-800);
  background-size: 0% rem($c11n-size-border-width-thick), 0% rem($c11n-size-border-width-thick);
  background-repeat: no-repeat;
  background-position: 0% 100%;
  transition-property: background-image, background-size;
  transition-timing-function: $animation-easing-standard-web;
  transition-duration: $animation-timing-collapse-small;

  &:hover,
  &:focus-visible,
  &[aria-expanded = "true"] {
    color: $c11n-color-ink-800 !important; 
    background-size: 100% rem($c11n-size-border-width-thick), 100% rem($c11n-size-border-width-thick);
  }

  &:hover {
    transition-duration: $animation-timing-expand-small;
  }

  &:focus-visible {
    transition-property: none;
  }
}