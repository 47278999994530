@import '../../variables';

@mixin progress-bar-host {
  --progress-color: #{$c11n-color-turquoise-600};
  --progress-width: 0;

  display: block;

  &.c11n-progress-bar--success {
    --progress-color: #{$c11n-color-success-400};

    .c11n-progress-bar__label-icon {
      display: block;
    }
  }

  &.c11n-progress-bar--error {
    --progress-color: #{$c11n-color-alert-400};

    .c11n-progress-bar__label-icon {
      display: block;
    }

    .c11n-progress-bar__error-text {
      display: block;
    }
  }

  &.c11n-progress-bar--hide-label {
    .c11n-progress-bar__label-content {
      opacity: 0;
    }
  
    &.c11n-progress-bar--active {
      .c11n-progress-bar__label-content {
        @include c11n-sr-only;
      }
    }
  }
}

@mixin progress-bar-children {
  .c11n-progress-bar__label {
    display: block;
    position: relative;
    margin-bottom: rem($c11n-size-space-1x);
    @include c11n-font-rules('bold', 'sm');
  }

  .c11n-progress-bar__label-content {
    text-align: inherit;
  }

  .c11n-progress-bar__label-icon {
    color: var(--progress-color);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  .c11n-progress-bar__progress-container {
    display: block;
    width: 100%;
    height: rem($c11n-size-space-2x);

    box-shadow: inset 0 0 0 rem($c11n-size-border-width-thin) $c11n-color-ink-400;
    outline: transparent rem($c11n-size-border-width-thin) solid;
    background-color: $c11n-color-ink-100;

    background-repeat: no-repeat;
    background-image: linear-gradient(to right, var(--progress-color), var(--progress-color));
    background-size: calc(var(--progress-width) * 1%) 100%;

    transition-property: background-size;
    transition-duration: $animation-timing-expand-small;
    transition-timing-function: $animation-easing-standard-web;
    
    .c11n-progress-bar__progress, .c11n-progress-bar__progress-sr {
      @include c11n-sr-only;
    }
  }

  .c11n-progress-bar__helper-text, .c11n-progress-bar__error-text {
    text-align: left;
    margin-top: rem($c11n-size-space-0point5x);
    @include c11n-font-rules('regular', 'xs');
  }

  .c11n-progress-bar__error-text {
    color: $c11n-color-alert-400;
    display: none;
  }
}