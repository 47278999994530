@import '../../variables';

.c11n-table {

  $border-color: $c11n-color-ink-300;
  $self: &;

  %shared-font-styles {
    font-variant-numeric: tabular-nums;
  }

  @mixin not-active-static-styles() {
    svg {
      use {
        fill: $c11n-color-ink-400;
      }
    }
  }

  @mixin not-active-hover-focus-styles() {
    svg {
      use {
        &:nth-child(1) {
          fill: $c11n-color-ink-300;
        }
        &:nth-child(2) {
          fill: $interactive-color;
        }
      }
    }
  }

  @mixin descending-static-rules() {
    svg {
      use {
        &:nth-child(1) {
          fill: transparent;
        }
        &:nth-child(2) {
          fill: $interactive-color;
        }
      }
    }
  }

  @mixin descending-hover-focus-rules() {
    svg {
      use {
        &:nth-child(1) {
          fill: $interactive-color;
        }
        &:nth-child(2) {
          fill: $c11n-color-ink-300;
        }
      }
    }
  }

  @mixin ascending-static-rules() {
    svg {
      use {
        &:nth-child(1) {
          fill: $interactive-color;
          transform: rotate(180deg) translateY(rem(-3));
        }
        &:nth-child(2) {
          fill: transparent;
        }
      }
    }
  }

  @mixin ascending-hover-focus-rules() {
    svg {
      use {
        &:nth-child(1) {
          fill: $c11n-color-ink-300;
          transform: rotate(180deg) translateY(rem(3));
        }
        &:nth-child(2) {
          fill: $interactive-color;
        }
      }
    }
  }

  width: 100%;
  color: $default-color;
  border-collapse: collapse;
  border-spacing: 0;

  &--small {
    @extend %shared-font-styles;
    @include c11n-font-rules('narrow', 'sm');
    #{ $self }__thead-th {
      padding: rem($c11n-size-space-2x - $c11n-size-border-width-thin * 2) rem($c11n-size-space-1x);
    }
    #{ $self }__tbody-th,
    #{ $self }__tfoot-th,
    #{ $self }__td {
      padding: rem($c11n-size-space-2x - $c11n-size-border-width-thin * 2) rem($c11n-size-space-1x) rem($c11n-size-space-2x - $c11n-size-border-width-thin * 3);
      &--sortable {
        padding-right: rem($c11n-size-space-1point5x + $c11n-size-space-1x);
      }
    }
  }

  &--medium {
    @extend %shared-font-styles;
    @include c11n-font-rules('narrow', 'md');
    #{ $self }__thead-th {
      padding: rem($c11n-size-space-2point5x) rem($c11n-size-space-1x) rem($c11n-size-space-1point5x);
    }
    #{ $self }__tbody-th,
    #{ $self }__tfoot-th,
    #{ $self }__td {
      padding: rem($c11n-size-space-1point5x) rem($c11n-size-space-1x) rem($c11n-size-space-1point5x - $c11n-size-border-width-thin);
      &--sortable {
        padding-right: rem($c11n-size-space-1point5x + $c11n-size-space-1x);
      }
    }
  }

  &--sticky-th-vertical,
  &--sticky-th-horizontal {
    position: relative;
  }

  &--sticky-th-vertical {
    #{ $self }__thead-th {
      position: sticky;
      top: 0;
      z-index: $c11n-elevation-z-index-1600;
    }
  }

  &--sticky-th-horizontal {
    #{ $self }__thead-th {
      &:first-of-type {
        position: sticky;
        left: 0;
        z-index: $c11n-elevation-z-index-1600;
      }
    }
    #{ $self }__td,
    #{ $self }__tbody-th,
    #{ $self }__tfoot-th {
      &:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: $c11n-elevation-z-index-1600;
      }
    }
  }

  &--sticky-th-vertical#{&}--sticky-th-horizontal {
    #{ $self }__thead-th {
      position: sticky;
      top: 0;
      &:first-of-type {
        left: 0;
        z-index: $c11n-elevation-z-index-1600 + 1;
      }
      &:not(:first-of-type) {
        z-index: $c11n-elevation-z-index-1600;
      }
    }
    #{ $self }__td,
    #{ $self }__tbody-th,
    #{ $self }__tfoot-th {
      &:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: $c11n-elevation-z-index-1600;
      }
    }
  }

  &__symbols {
    display: none;
  }

  &__tr  {
    #{ $self }__td,
    #{ $self }__tbody-th {
      transition-property: background-color;
      transition-timing-function: $animation-easing-standard-web;
      transition-duration: $animation-timing-collapse-small;
      transform-style: flat;
      backface-visibility: hidden;
    }
    &:hover {
      #{ $self }__td,
      #{ $self }__tbody-th {
        transition-duration: $animation-timing-expand-small;
        background-color: $c11n-color-azure-100;
      }
    }
    &:last-child{
      #{ $self }__td,
      #{ $self }__tbody-th {
        border-bottom: none;
      }
    }
  }

  &__thead-th {

    font-weight: inherit;
    vertical-align: bottom;
    background: linear-gradient(to top, $border-color 0, $border-color rem($c11n-size-border-width-thin), $c11n-color-ink-100 rem($c11n-size-border-width-thin), $c11n-color-ink-100 100%);

    &:not(&--active-sort) {
      .c11n-link--icon {

        @include not-active-static-styles();

        &:hover, &:focus-visible {
          @include not-active-hover-focus-styles();
        }
      }
    }

    &--active-sort {

      &.descending {
        .c11n-link--icon {

          @include descending-static-rules();

          &:hover, &:focus-visible {
            @include descending-hover-focus-rules();
          }
        }
      }

      &.ascending {
        .c11n-link--icon {

          @include ascending-static-rules();

          &:hover, &:focus-visible {
            @include ascending-hover-focus-rules();
          }
        }
      }
    }

    .c11n-link--icon  {

      margin-bottom: rem(-2);
      padding-bottom: 0 !important;

      .c11n-link__content {
        display: inline-block;
        padding-bottom: rem(2) !important;
        padding-right: rem(12);
      }

      svg {
        position: absolute;
        bottom: rem(1);
        width: rem(8);
        height: rem(14);
        use {
          fill: transparent;
          transition-property: fill;
          transition-timing-function: $animation-easing-standard-web;
          transition-duration: $animation-timing-expand-small;
          transform-style: flat;
          backface-visibility: hidden;
          transform-origin: center;
          &:nth-child(1) {
            transform-origin: rem(4) rem(7);
            transform: rotate(180deg) translateY(rem(3));
          }
          &:nth-child(2) {
            transform: translateY(rem(3));
          }
        }
      }
    }

    &--info {
      @include c11n-font-rules('narrow', 'xs');
      color: $c11n-color-ink-800;
      display: inline-block;
    }
  }

  &__td,
  &__tbody-th {
    border-bottom: rem($c11n-size-border-width-thin) solid $border-color;
  }

  tfoot {
    .c11n-table__tr {
      &:first-child {
        .c11n-table__td {  
          background: linear-gradient(to bottom, $c11n-color-ink-800 0, $c11n-color-ink-800 rem($c11n-size-border-width-thin), $c11n-color-ink-100 rem($c11n-size-border-width-thin), $c11n-color-ink-100 100%);
        }
      }

      &:last-child {
        .c11n-table__td {
          border-bottom: none;
        }
      }

      &:hover {
        .c11n-table__td {
          background-color: $c11n-color-ink-100;
        }
      }
    }

    .c11n-table__td {
      transition: none;
      background: $c11n-color-ink-100;
    }
  }

  &__thead-th,
  &__tbody-th,
  &__tfoot-th,
  &__td {

    &:first-of-type {
      text-align: left;
    }

    &:not(:first-of-type) {
      text-align: right;
    }

    &--align-left {
      &:first-of-type,
      &:not(:first-of-type) {
        text-align: left;
      }
    }

    &--align-center {
      &:first-of-type,
      &:not(:first-of-type) {
        text-align: center;
      }
    }

    &--align-right {
      &:first-of-type,
      &:not(:first-of-type) {
        text-align: right;
      }
    }

    .c11n-table__icon-container {
      display: inline-block;
    }
  }

  &__td,
  &__tbody-th {
    vertical-align: top;
    background: $c11n-color-ink-000;
  }

  &__tfoot-th {
    vertical-align: top;
    background: $c11n-color-ink-100;
  }

  &__tbody-th,
  &__tfoot-th {
    font-weight: inherit;
  }

  // TODO: these style are no longer needed but removing
  // them may constitute a breaking change
  // DEPRECATED STYLES BEGIN
  &__a {

    font-weight: $c11n-font-weight-bold;
    color: inherit;

    span {
      border-bottom-width: rem($c11n-size-border-width-thin);
      border-bottom-style: solid;
      border-bottom-color: $border-color;
    }

    &:focus,
    &:hover {
      span {
        border-bottom-color: $c11n-color-ink-800;
      }
    }

    &:focus {

      outline: none;

      > span {
        outline: solid rem($c11n-size-border-width-thin) $c11n-color-ink-000;
        outline-offset: rem(2 + $c11n-size-border-width-thick);
      }

      span span {
        outline: solid rem($c11n-size-border-width-thick) $focus-color;
        outline-offset: rem(2);
      }
    }
  }

  &__sort-trigger {

    cursor: default;

    svg {

      margin-left: rem($c11n-size-space-0point5x);
      display: inline-block;
      visibility: hidden;
      position: absolute;
      bottom: rem(6);
      right: 0;
      transform: rotate(180deg);
      width: rem(8);
      height: rem(5);

      polygon {
        fill: $interactive-color;
      }
    }

    &--sort-active {
      color: $interactive-color;
      svg {
        width: rem(8);
        height: rem(5);
        visibility: visible;
      }
    }

    &:focus-within,
    &:hover {
      svg {
        visibility: visible;
      }
    }

    &:focus-within {

      outline: solid rem($c11n-size-border-width-thin) $c11n-color-ink-000;
      outline-offset: rem(2 + $c11n-size-border-width-thick);

      > span {
        outline: solid rem($c11n-size-border-width-thick) $focus-color;
        outline-offset: rem(2);
      }
    }

    input[type = 'checkbox'] {

      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;

      &:checked {
        ~ svg {
          transform: rotate(0);
          transform-origin: center;
        }
      }
    }

    ::selection {
      background: transparent;
    }
  }

  &__sort-trigger-inner-wrapper {
    position: relative;
    display: inline-block;
    padding-right: rem($c11n-size-space-1point5x);
  }
  // DEPRECATED STYLES END
}
