@import './graphic-pattern-mixins.scss';

.c11n-graphic-pattern {
  @include graphic-pattern-host;

  &.c11n-graphic-pattern--bright-1,
  &.c11n-graphic-pattern--dark-1,
  &.c11n-graphic-pattern--turquoise-1,
  &.c11n-graphic-pattern--yellow-1 {
    @include graphic-pattern--version1;
  }

  &.c11n-graphic-pattern--bright-1 {
    @include graphic-pattern--bright-1;
  }

  &.c11n-graphic-pattern--dark-1 {
    @include graphic-pattern--dark-1;
  }

  &.c11n-graphic-pattern--turquoise-1 {
    @include graphic-pattern--turquoise-1;
  }

  &.c11n-graphic-pattern--yellow-1 {
    @include graphic-pattern--yellow-1;
  }

  &.c11n-graphic-pattern--bright-2,
  &.c11n-graphic-pattern--dark-2,
  &.c11n-graphic-pattern--turquoise-2,
  &.c11n-graphic-pattern--yellow-2 {
    @include graphic-pattern--version2;
  }

  &.c11n-graphic-pattern--bright-2 {
    @include graphic-pattern--bright-2;
  }

  &.c11n-graphic-pattern--dark-2 {
    @include graphic-pattern--dark-2;
  }

  &.c11n-graphic-pattern--turquoise-2 {
    @include graphic-pattern--turquoise-2;
  }

  &.c11n-graphic-pattern--yellow-2 {
    @include graphic-pattern--yellow-2;
  }

  &.c11n-graphic-pattern--stacked-red {
    @include graphic-pattern--stacked-red;
  }

  &.c11n-graphic-pattern--stacked-multi {
    @include graphic-pattern--stacked-multi;
  }

  &.c11n-graphic-pattern--stacked-turquoise {
    @include graphic-pattern--stacked-turquoise;
  }

  &.c11n-graphic-pattern--stacked-yellow {
    @include graphic-pattern--stacked-yellow;
  }

  &.c11n-graphic-pattern--stacked-red,
  &.c11n-graphic-pattern--stacked-multi,
  &.c11n-graphic-pattern--stacked-turquoise,
  &.c11n-graphic-pattern--stacked-yellow {
    @include graphic-pattern--stacked-all;
  }
}