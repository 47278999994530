@import '../../variables';
@import './button-shared-mixins';

@mixin icon-button-host {
  @include button-host;
  &.c11n-button--icon-primary,
  &.c11n-button--icon-secondary,
  &.c11n-button--icon-ghost {
    border-radius: 50%;
    &.c11n-button--small {
      height: rem($c11n-component-button-height-small);
      width: rem($c11n-component-button-height-small);
    }
    &.c11n-button--medium {
      height: rem($c11n-component-button-height-medium);
      width: rem($c11n-component-button-height-medium);
    }
    &.c11n-button--large {
      height: rem($c11n-component-button-height-large);
      width: rem($c11n-component-button-height-large);
    }
  }

  &:hover {
    .c11n-button__icon-only svg use {
      transition-duration: $animation-timing-expand-small;
    }
  }

  &:focus-visible {
    .c11n-button__icon-only svg use {
      transition: none;
    }
  }
}

@mixin icon-button-child {
  color: inherit;
  &.c11n-button--icon-primary,
  &.c11n-button--icon-secondary,
  &.c11n-button--icon-ghost {
    .c11n-button__box {
      @include button__box-shared;
      padding: 0;
      background-color: transparent;
      height: 100%;
    }
  }

  .c11n-button__icon-only {
    @include button-icon-transition;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &.c11n-button--icon-primary {
    .c11n-button__box {
      background-color: $black;
      border: rem($c11n-size-border-width-thick) solid $black;
    }

    &:hover, &:focus-visible {
      .c11n-button__box {
        background-color: $white;
        color: $black;
      }
    }

    &.c11n--on-dark {
      .c11n-button__box {
        border-color: $white;
        background-color: $white;
        color: $black;
      }

      &:hover, &:focus-visible {
        .c11n-button__box {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &.c11n-button--icon-secondary {
    .c11n-button__box {
      border: rem($c11n-size-border-width-thick) solid $black;
      background-color: transparent;
      color: $black;
    }

    &:hover, &:focus-visible {
      .c11n-button__box {
        background-color: $black;
        color: $white;
      }
    }

    &.c11n--on-dark {
      .c11n-button__box {
        background-color: transparent;
        border-color: $white;
        color: $white;
      }

      &:hover, &:focus-visible {
        .c11n-button__box {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &.c11n-button--icon-ghost {
    @include c11n-hover-block-before();

    .c11n-button__box {
      color: currentColor;
      border: none;
      background-color: transparent;
    }

    &.c11n--on-dark {
      color: $white;
      &::before {
        background-color: rgba($c11n-color-ink-000, 0.3);
      }
    }
  }

  &.c11n-button--invalid {
    @include button-invalid-styles;
  }
}