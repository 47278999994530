@import './tabs-mixins.scss';

.c11n-tabs {
  @include navigation-host;
  @include tabs-host;
  @include navigation-child;

  &.c11n-tabs--initializing {
    @include navigation--initializing;
  }

  &.c11n-tabs--small-responsive {
    @include navigation--small-responsive;
    @include tabs--small-responsive;
  }

  &.c11n-tabs--equal-width {
    @include navigation--equal-width;
    @include tabs--equal-width;
  }

  & ~ .c11n-tabs__tab-panel {
    @include tab-panel-focus;
  }
}
