@import 'dialog-polyfill/dialog-polyfill';
@import './modal-dialog-entrypoint-mixins';

.c11n-modal-dialog-entrypoint {
  @include modal-dialog-entrypoint-child;
  
  dialog.c11n-modal-dialog[open] + .backdrop {
    @include modal-dialog-entrypoint-backdrop;
  }
}
