@import './label-mixins';

.c11n-label {
  @include label-host;
  @include label-child;

  &.c11n-label--medium {
    .c11n-tooltip {
      @include label-tooltip-overrides;
    } 
  }

  &.c11n-label--large {
    @include label-large-child;
    .c11n-tooltip {
      @include label-tooltip-large-overrides;
    }
  }
}
