@use 'sass:math';
@import '../../variables';
@import './tooltip-shared-mixins';
@import '../../icons/icons-mixins';

$tooltip-offset: 27;
$button-diameter: 24;
// TODO: build in support for different line-heights (i.e. - small, extra-small)
$line-height: 24;

@mixin tooltip-child {

  .c11n-tooltip__last-word-and-tooltip {
    position: relative;
    padding-right: rem($button-diameter + ($tooltip-offset - $button-diameter));
    height: rem($line-height);
    white-space: nowrap;
    z-index: $c11n-elevation-z-index-800 - 3;
    transition-property: z-index;
    transition-duration: 0ms;
    transition-delay: $animation-timing-collapse-medium;
  }

  .c11n-tooltip__inner-wrapper {
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 0;
    margin-top: rem(math.div($button-diameter, -2) + math.div($button-diameter - $line-height, 2));
    // minor compensation for uneven font size (17px)
    transform: translateY(#{rem(2)});
    white-space: normal;
  }

  .c11n-tooltip__trigger {

    background: transparent;
    border: none;
    color: inherit;
    cursor: default;
    padding: rem(5);
    // Safari button reset
    margin: 0;
    border-radius: 50%;

    @include c11n-hover-block-before();
    @include c11n-focus-outline-block-after();
    z-index: $c11n-elevation-z-index-800 + 1;
    &:focus-visible {
      z-index: auto;
    }

    // TODO - delete the following style block with next major release
    .c11n-icon {
      svg {
        display: block;
        pointer-events: none;
        @include small-icon-rules;
        use {
          fill: $c11n-color-ink-800;
        }
      }
    }

    &[aria-expanded='true'] {
      + .c11n-tooltip__popup {
        @include tooltip__popup-visible;
      }
    }
  }

  @include tooltip__popup;
  
}

@mixin tooltip--activated {
  .c11n-tooltip__last-word-and-tooltip {
    z-index: $c11n-elevation-z-index-800 - 2;
    transition-delay: 0ms;
  }
}

@mixin tooltip--on-dark {
  .c11n-tooltip__trigger {
    &::before {
      background-color: rgba($c11n-color-ink-000, 0.3);
    }
    // TODO - delete the following style block with next major release
    .c11n-icon {
      svg {
        use {
          fill: $c11n-color-ink-000 !important;
        }
      }
    }
  }
  .c11n-tooltip__popup {
    .c11n-tooltip__content {
      color: $c11n-color-ink-800;
      background: $c11n-color-ink-000;
    }
    .c11n-tooltip__pointer {
      svg {
        polygon {
          &:last-of-type {
            fill: $c11n-color-ink-000;
          }
        }
      }
    }
  }
}

@mixin tooltip--dismissed {
  .c11n-tooltip__popup {
    opacity: 0 !important;
    pointer-events: none !important;
    visibility: hidden !important;
    transition-delay: 0ms, $animation-timing-collapse-medium;
  }
}

@mixin tooltip--initializing {
  .c11n-tooltip__popup {
    display: block !important;
  }
}

@mixin tooltip--position-none {
  .c11n-tooltip__popup {
    display: none;
  }
}

@mixin tooltip--below {
  .c11n-tooltip__popup {

    top: rem($tooltip-offset);

    &::after {
      left: 0;
      height: rem($tooltip-offset);
      top: rem(-$tooltip-offset);
      width: 100%;
    }
  }
}

@mixin tooltip--left {
  .c11n-tooltip__popup {

    right: rem($tooltip-offset);

    &::after {
      right: rem(-$tooltip-offset);
      height: 100%;
      top: 0;
      width: rem($tooltip-offset);
    }
  }
  .c11n-tooltip__pointer {
    right: 0;
    transform: rotate(90deg);
  }
}

@mixin tooltip--right {
  .c11n-tooltip__popup {
      
    left: rem($tooltip-offset);

    &::after {
      left: rem(-$tooltip-offset);
      height: 100%;
      top: 0;
      width: rem($tooltip-offset);
    }
  }
  .c11n-tooltip__pointer {
    left: 0;
    transform: rotate(-90deg);
  }
}

@mixin tooltip--left-start--right-start {
  .c11n-tooltip__popup {
    top: 0;
    transform: translateY(
      calc(#{rem(math.div($button-diameter, 2) - $c11n-size-space-2x)})
    );
  }
  .c11n-tooltip__pointer {
    top: rem($c11n-size-space-2x);
  }
}

@mixin tooltip--left-center--right-center {
  .c11n-tooltip__popup {
    top: 0;
    transform: translateY(calc(-50% + #{rem(math.div($button-diameter, 2))}));
  }
  .c11n-tooltip__pointer {
    top: 50%;
  }
}

@mixin tooltip--left-end--right-end {
  .c11n-tooltip__popup {
    bottom: 0;
    transform: translateY(
      calc(#{rem(math.div(-$button-diameter, 2) + $c11n-size-space-2x)})
    );
  }
  .c11n-tooltip__pointer {
    bottom: rem($c11n-size-space-2x);
  }
}