$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$container-max-widths: (
  xs: 351px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;
$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;

