@use 'sass:math';
@import '../../variables';
@import '../menu/menu-mixins';

@mixin search-host() {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  --menuBottomPosition: auto;
  --menuOffsetPosition: #{rem(15)};
  --size: #{rem($c11n-size-space-6x)};
  --menuWidth: 100%;
  --inputGap: 0px;
  --inputWidth: calc(100% - var(--size) - var(--inputGap));
  --inputBorderColor: #{$c11n-color-ink-800};
  --inputBorderRightColor: #{$c11n-color-ink-800};
  --menuBorderSideColor: #{$c11n-color-ink-800};
  --menuOpacity: 0;
  --menuVisibility: hidden;
  --labelDisplay: grid;
  --submitButtonRadius: 50%;
  --submitBottomBorderWidth: #{rem($c11n-size-border-width-thick)};
  --col2: none;
  --col3: none;
  --col1-width: percentage(math.div(1,3));
  --col2-width: percentage(math.div(1,3));
  --col3-width: percentage(math.div(1,3));
}
@mixin search--above {
  --menuBottomPosition: calc(100% - var(--menuOffsetPosition));
}
@mixin search--cols1 {
  --col2: inline-block;
  --col2-width: 0%;
  --col3-width: 0%;
}
@mixin search--cols2 {
  --col2: inline-block;
  --col3-width: 0%;
}
@mixin search--cols3 {
  --col2: inline-block;
  --col3: inline-block;
}

@mixin search--large {
  --menuOffsetPosition: #{rem($c11n-size-space-2point5x)};
  --size: #{rem($c11n-size-space-8x)};
  --menuWidthAdjustment: #{rem(72)};
}
@mixin search--detached {
  --inputGap: #{rem($c11n-size-space-1x)};
  --menuWidth: calc(100% - var(--size) - var(--inputGap));
  --inputWidth: calc(100% - var(--size) - var(--inputGap));
}
@mixin search--attached {
  --inputWidth: calc(100% - var(--size) - var(--inputGap));
  --inputBorderRightColor: transparent;
  --submitButtonRadius: 0%;
}  
@mixin search--on-dark {
  --inputBorderColor: #{$c11n-color-ink-000};
  --inputBorderRightColor: #{$c11n-color-ink-800};
  --menuBorderSideColor: #{$c11n-color-ink-000};
}
@mixin search--on-dark-detached {
  --inputBorderRightColor: #{$c11n-color-ink-000};
}
@mixin search--hide-label {
  --labelDisplay: none;
}
@mixin search--show-menu {
  --menuOpacity: 1;
  --menuVisibility: visible;
}
@mixin search--above-hide-label {
  --menuBottomPosition: 100%;
}
@mixin search--hide-search-button {
  --inputWidth: calc(100% - var(--inputGap));
  --menuWidth: 100%;
  --inputBorderRightColor: #{$c11n-color-ink-800};
}
@mixin search-submit-button-styles {
  .c11n-search__form__submit {
    button {
      min-width: var(--size);
      border-radius: var(--submitButtonRadius);
      .c11n-button__box {
        box-sizing: border-box;
        border-radius: var(--submitButtonRadius);
        border-bottom-width: var(--submitBottomBorderWidth);
      }
    }
    button:focus-visible {
      .c11n-button__box {
        border-radius: var(--submitButtonRadius);
      }
    }
  }
  
}

@mixin search-menu-styles {
  @include menu-child();
  .c11n-menu__menu {
    bottom: var(--menuBottomPosition);
    width: var(--menuWidth);
    border-left-color: var(--menuBorderSideColor);
    border-right-color: var(--menuBorderSideColor);
    border-bottom-color: var(--menuBorderSideColor);
    opacity: var(--menuOpacity);
    visibility: var(--menuVisibility);
  }
}

@mixin search-child {
  .c11n-search__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    label.c11n-search__form__label {
      display: var(--labelDisplay);
      color: currentColor;
    }
    
    &__input-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      // z-index: $c11n-elevation-z-index-800 + 1;
      gap: var(--inputGap);
      box-sizing: border-box;
      &__input {
        position: relative;
        box-sizing: border-box;
        min-width: var(--inputWidth);
        height: 100%;
        flex-grow: 0;
        input.c11n-input__input {
          box-sizing: border-box;
          min-width: 100%;
          border-top-color: var(--inputBorderColor);
          border-bottom-color: var(--inputBorderColor);
          border-left-color: var(--inputBorderColor);
          border-right-color: var(--inputBorderRightColor);
          padding-right: rem($c11n-size-space-5x);
          text-overflow: ellipsis;
        }
        &__clear {
          position: absolute;
          color: $c11n-color-ink-400;
          width: rem($c11n-size-space-4x);
          height: rem($c11n-size-space-4x);
          right: rem($c11n-size-space-1x);
          top: 50%;
          transform: translateY(-50%);
          visibility: visible;
        }
        &__clear--hidden {
          visibility: hidden;
        }
      }
    }
    @include search-submit-button-styles();
  }
  
  .c11n-search__menu {
    @include search-menu-styles();
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: rem($c11n-size-space-6x);
      min-width: 100%;
      gap: $c11n-size-space-1x;

      &__label {
        overflow:hidden;
        white-space: nowrap;
        box-sizing: content-box;
        height: rem($c11n-size-line-height-md + 2.5px);
        color: $c11n-color-ink-800;
        b {
          @include c11n-font-rules('bold', 'md');
          line-height: inherit;
        }
        span {
          padding-bottom: $c11n-text-md-normal-underline-padding;
        }
      }
      &__label:nth-of-type(1) {
        width: var(--col1-width);
      }
      &__label:nth-of-type(2) {
        display: var(--col2);
        text-overflow: ellipsis;
        width: var(--col2-width);
      }
      &__label:nth-of-type(3) {
        margin-left: auto;
        color: $c11n-color-ink-400;
        display: var(--col3);
        text-overflow: ellipsis;
        white-space: nowrap;
        width: var(--col3-width);
      }
      &__label:last-of-type:not(:first-of-type) {
        // we don't want to set text-align to right 
        // if the there is only one column in the search menu
        text-align: right;
      }
    }
    &__item:hover, &__item:focus {
      .c11n-search__menu__item__label:nth-of-type(3) {
        color: $c11n-color-ink-800;
      }
    }
    &__summary {
      &__heading {
        @include c11n-font-rules('bold', 'sm');
        color: $c11n-color-ink-800;
        text-align: left;
        padding: rem($c11n-size-space-1point5x) rem($c11n-size-space-2x - 1) rem($c11n-size-space-2x - 2);
      }
      &__see-all .c11n-search__menu__item__label {
        @include c11n-font-rules('bold', 'md');
        color: $c11n-color-interactive-primary;
        overflow: visible;
        width: 100%;
        text-align: left;
      }
    }

    .c11n-menu__group {
      @include c11n-menu-item-focus-styles();
    }

    &__sr-only {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      width: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
    }
    
  }
}
