#focusPanelDrawerContainer {
  .c11n-drawer {
    &__close-btn {
      display: none;
    }
    &__content {
      padding: 0;
      padding-right: 5px;
    }
  }
}

#focusPanelDrawerContainer {
  aside.c11n-drawer {
    height: calc(
      100% + 82px
    ); // 82px is the offset added to stretch focus panel over secondary nav width.
  }
}
