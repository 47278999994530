@import '../../variables';
@import '../tooltip/tooltip-shared-mixins';

@mixin slider-host {
  position: relative;
  padding: 0 rem(1);
  display: flex;
  flex-direction: column;
}

@mixin slider-child-error {
  .c11n-slider-input {
    input {
      border: rem($c11n-size-border-width-thin) solid $error-color;
    }
  }
}

@mixin slider-child {
  .c11n-slider-input {
    max-width: 11ch;
    position: relative;
    &__input {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &::placeholder {
        color: $c11n-color-ink-800;
      }
      color: $c11n-color-ink-800;
      text-align: center;
      display: inline-block;
      @include c11n-font-rules('regular', 'md');
      font-variant-numeric: tabular-nums;
      line-height: rem(12);
      width: 11ch;
      height: rem($c11n-size-space-6x);
      overflow: visible;
      margin: 0;
      padding: 0 rem($c11n-size-space-2x) rem($c11n-size-space-0point5x)
        rem($c11n-size-space-2x);
      background: $white;
      border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-800;
      border-radius: 0;
      outline: none;
      text-align: center;
    }
    @include c11n-focus-outline-sibling(
      '.c11n-slider-input__input',
      '.c11n-slider-input__focus-element',
      $width: 100%,
      $height: 100%,
      $top: 0,
      $left: 0
    );
  }

  .c11n-slider__box {
    position: relative;
    display: flex;
    align-items: center;
    gap: rem($c11n-size-space-2x);
    width: 100%;
    min-height: rem(26);

    @media screen and (min-width: rem($c11n-grid-breakpoint-sm)) {
      gap: rem($c11n-size-space-3x);
    }

    &__min-cap,
    &__max-cap {
      @include c11n-font-rules('regular', 'md');
      @include c11n-text-crop-md();
      color: $c11n-color-ink-800;
      position: relative;
      top: 0;
      margin: 0;
    }

    &__inner {
      --value: 50;
      --pcvalue: calc(var(--value) * 1%);
      --thumbbg: #{$c11n-color-ink-000};
      --thumbhoverbg: #{$c11n-color-ink-000};
      --trackcolor1: #{$c11n-color-turquoise-600};
      --trackcolor2: #{$c11n-color-turquoise-100};

      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      --trackwidth: 100%;
      height: auto;
      z-index: $c11n-elevation-z-index-100;

      &__track {
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        box-sizing: border-box;
        height: rem($c11n-size-space-1x);
        border: rem($c11n-size-border-width-thin) solid var(--trackcolor1);
        border-radius: rem($c11n-size-space-0point5x);
        background: linear-gradient(
          to right,
          var(--trackcolor1) 0%,
          var(--trackcolor1) var(--pcvalue),
          var(--trackcolor2) var(--pcvalue),
          var(--trackcolor2) 100%
        );
      }

      &__thumb,
      &__glow {
        position: absolute;
        box-sizing: border-box;
        width: rem($c11n-size-space-3x);
        height: rem($c11n-size-space-3x);
        border-radius: 50%;
        top: -0.5rem;
        left: calc((var(--value) / 100) * var(--trackwidth));
        transform: translateX(-50%);
      }

      &__glow {
        background-color: rgba($c11n-color-ink-800, 0.1);
        border-radius: 50%;
        transform: scale(0) translateZ(0);
        transform-origin: center;
        transition-property: transform, background-color;
        transition-timing-function: $animation-easing-standard-web;
        transition-duration: $animation-timing-collapse-small;
        transform-style: flat;
        backface-visibility: hidden;
        width: rem($c11n-size-space-4x);
        height: rem($c11n-size-space-4x);
        top: -0.75rem;
        left: calc((var(--value) / 100) * var(--trackwidth) - 1rem);
      }

      &__thumb {
        background-color: var(--thumbbg);
        border: rem($c11n-size-border-width-thin) solid $black;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 0.5rem 0.5rem;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><line x1='1' y1='0' x2='1' y2='8' style='stroke:rgb(4,5,5);stroke-width:1' /><line x1='4' y1='0' x2='4' y2='8' style='stroke:rgb(4,5,5);stroke-width:1' /><line x1='7' y1='0' x2='7' y2='8' style='stroke:rgb(4,5,5);stroke-width:1' /></svg>");
      }

      &:hover {
        .c11n-slider__box__inner__glow {
          transform: scale(1) translateZ(0);
          transition-duration: $animation-timing-expand-small;
        }
        .c11n-slider__tooltip {
          .c11n-slider__tooltip-popup {
            @include tooltip__popup-visible;
          }
        }
      }

      &:focus-within {
        .c11n-slider__box__inner__thumb {
          outline: rem($c11n-size-border-width-thick) solid $focus-color;
          outline-offset: rem(2);
        }
        .c11n-slider__box__inner__glow {
          transform: scale(1) translateZ(0);
          transition-duration: $animation-timing-expand-small;
          outline: rem($c11n-size-border-width-thick) solid $white;
          outline-offset: rem(0);
        }
        .c11n-slider__tooltip {
          .c11n-slider__tooltip-popup {
            @include tooltip__popup-visible;
          }
        }
      }

      &__input {
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: border-box;
        width: calc(100% + 1.5rem);
        left: rem(-14);
        height: rem($c11n-size-space-1x);
        background-color: transparent;
        outline: none;
        opacity: 0;
        border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-800;

        &:-moz-focusring {
          color: transparent;
          text-shadow: none;
        }

        &::-moz-focus-inner {
          border: 0 none;
        }

        &::-webkit-slider-thumb {
          box-sizing: border-box;
          -webkit-appearance: none;
          appearance: none;
          width: 1.5rem;
          height: 1.5rem;
          background: var(--thumbbg);
          border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-800;
        }

        &::-moz-range-thumb {
          box-sizing: border-box;
          width: 1.5rem;
          height: 1.5rem;
          background: var(--thumbbg);
          border: rem($c11n-size-border-width-thin) solid $c11n-color-ink-800;
          border-radius: 50%;
        }
      }
    }
  }

  .c11n-slider__bottom-input {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    gap: 0.75rem;
    margin: rem(12) 0 0 0;

    label {
      width: fit-content;
      margin: 0;
    }
    input {
      text-align: right;
    }
  }

  .c11n-slider-sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  @include tooltip__popup('.c11n-slider__tooltip-');
}

@mixin slider--value-indicator {
  $tooltip-offset: 31;
  $button-diameter: 22;

  .c11n-slider__tooltip--above-start,
  .c11n-slider__tooltip--above-center,
  .c11n-slider__tooltip--above-end {
    @include tooltip--above($tooltip-offset, '.c11n-slider__tooltip-');
  }

  .c11n-slider__tooltip--above-start {
    @include tooltip--above-start--below-start(
      $button-diameter,
      '.c11n-slider__tooltip-'
    );
  }

  .c11n-slider__tooltip--above-center {
    @include tooltip--above-center--below-center(
      $button-diameter,
      '.c11n-slider__tooltip-'
    );
  }

  .c11n-slider__tooltip--above-end {
    @include tooltip--above-end--below-end(
      $button-diameter,
      '.c11n-slider__tooltip-'
    );
  }
}

@mixin slider--top-labels {
  padding-top: rem(28);

  .c11n-slider__box__min-cap,
  .c11n-slider__box__max-cap {
    position: absolute;
    margin: 0;
    top: rem(-28);
  }

  .c11n-slider__box__min-cap {
    left: 0;
  }

  .c11n-slider__box__max-cap {
    right: 0;
  }
}
