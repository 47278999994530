@import './segmented-control-mixins';

.c11n-segmented-control {
  @include segmented-control-host;
  @include segmented-control-child;

  .c11n-segmented-control__segment {
    @include segmented-control-segment;
  }

  .c11n-segmented-control__segment-input {
    @include segmented-control-segment-input;
  }
}