@use 'sass:math';
@import '../../variables';

$tooltip-offset: 27;
$button-diameter: 24;

@mixin tooltip__popup-visible {
  opacity: 1;
  pointer-events: initial;
  visibility: visible;
  transition-duration: $animation-timing-expand-medium;
  transition-delay: 0ms, 0ms;
}

@mixin tooltip__popup($namespace: '.c11n-tooltip__') {
  #{$namespace}popup {
    $max-width: 240;
    position: absolute;
    @include c11n-elevation(800);
    max-width: rem($max-width);
    border-radius: rem($c11n-size-border-radius-sm);
    width: max-content;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition-property: opacity, visibility;
    transition-timing-function: $animation-easing-standard-web;
    transition-duration: $animation-timing-collapse-medium;
    transition-delay: 0ms, $animation-timing-collapse-medium;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    outline: rem(1) solid transparent;
    text-align: left;

    &::after {
      content: '';
      position: absolute;
    }

    &:focus {
      outline: none;
    }

    #{$namespace}pointer {
      transform-origin: center;
      position: absolute;
      z-index: $c11n-elevation-z-index-800 - 2;
      width: 0;
      height: 0;
      overflow: visible;
      transform-origin: center;
      display: block;

      svg {
        display: block;
        position: absolute;
        left: rem(-8);
        top: rem(-5);
        width: rem(16);
        height: rem(6);

        polygon {
          &:first-of-type {
            fill: $c11n-color-ink-000;
          }
          &:last-of-type {
            fill: $c11n-color-ink-600;
          }
        }
      }
    }

    &::before {
      box-shadow: 0 0 0 rem(1) $c11n-color-ink-000;
      z-index: $c11n-elevation-z-index-800 - 3;
    }

    #{$namespace}content {
      color: $c11n-color-ink-000;
      background: $c11n-color-ink-600;
      padding: rem($c11n-size-space-2x);
      box-shadow: inset 0 0 0 rem(1) $c11n-color-ink-000;
      border-radius: rem($c11n-size-border-radius-sm);
      position: relative;
      display: block;

      > * {
        @include c11n-font-rules();
        @include c11n-text-crop-md();
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}

@mixin tooltip--above(
  $tooltip-offset-local: $tooltip-offset,
  $namespace: '.c11n-tooltip__'
) {
  #{$namespace}popup {
    bottom: rem($tooltip-offset-local);

    &::after {
      left: 0;
      height: rem($tooltip-offset-local);
      bottom: rem(-$tooltip-offset-local);
      width: 100%;
    }
  }
  #{$namespace}pointer {
    bottom: 0;
    transform: rotate(180deg);
  }
}

@mixin tooltip--above-start--below-start(
  $button-diameter-local: $button-diameter,
  $namespace: '.c11n-tooltip__'
) {
  #{$namespace}popup {
    left: 0;
    transform: translate(
      #{rem(math.div($button-diameter-local, 2) - $c11n-size-space-2x)}
    );
  }
  #{$namespace}pointer {
    left: rem($c11n-size-space-2x);
  }
}

@mixin tooltip--above-center--below-center(
  $button-diameter-local: $button-diameter,
  $namespace: '.c11n-tooltip__'
) {
  #{$namespace}popup {
    left: 0;
    transform: translateX(
      calc(-50% + #{rem(math.div($button-diameter-local, 2))})
    );
  }
  #{$namespace}pointer {
    left: 50%;
  }
}

@mixin tooltip--above-end--below-end(
  $button-diameter-local: $button-diameter,
  $namespace: '.c11n-tooltip__'
) {
  #{$namespace}popup {
    right: 0;
    transform: translateX(
      #{rem(math.div(-$button-diameter-local, 2) + $c11n-size-space-2x)}
    );
  }
  #{$namespace}pointer {
    right: rem($c11n-size-space-2x);
  }
}
