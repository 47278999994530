@import '../../variables';
@import './button-shared-mixins';

@mixin icon-label-button-host {
  @include button-host;
  &.c11n-button--label-icon-primary,
  &.c11n-button--label-icon-secondary,
  &.c11n-button--label-icon-on-dark {
    color: $black;
    z-index: 0;
  }
}

@mixin icon-label-button-child {
  &.c11n-button--label-icon-primary,
  &.c11n-button--label-icon-secondary,
  &.c11n-button--label-icon-on-dark {
    &.c11n-button--small {
      .c11n-button__box {
        padding-left: rem($c11n-size-space-1point5x);
      }
    }

    .c11n-button__box {
      @include button__box-shared;
      justify-content: space-between;
      padding: 0 0 0 rem($c11n-size-space-3x);
      background-color: transparent;
      border: none;
      position: relative;
      transition-property: color;
      transition-duration: $animation-timing-collapse-medium;
      transition-timing-function: $animation-easing-standard-web;
      transform-style: flat;
      backface-visibility: hidden;

      &::before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border: rem($border-width) solid transparent;
        border-radius: rem(100);
        transition-property: width, background-color;
        transition-duration: $animation-timing-collapse-medium;
        transition-timing-function: $animation-easing-standard-web;
        transform-style: flat;
        backface-visibility: hidden;
      }

      .c11n-button__labeled-icon {
        display: flex;
        justify-content: center;
        margin: 0 0 0 rem($c11n-size-space-1x);
        transition-property: color;
        transition-duration: $animation-timing-collapse-medium;
        transition-timing-function: $animation-easing-standard-web;
        transform-style: flat;
        backface-visibility: hidden;
      }
    }
  }

  @include button__box-before;
  @include button__label;

  .c11n-button__labeled-icon {
    display: none;
    flex-direction: row;
    align-items: center;
    width: rem($c11n-component-button-height-medium);
    height: rem($c11n-component-button-height-medium);
  }

  &.c11n-button--small {
    .c11n-button__labeled-icon {
      width: rem($c11n-component-button-height-small);
      height: rem($c11n-component-button-height-small);
      max-width: none;
      min-width: auto;
    }
  }

  &.c11n-button--label-icon-primary {
    .c11n-button__box {
      color: $black;
      border: 0 none;

      &::before {
        background-color: $black;
        border: rem($c11n-size-border-width-thick) solid $black;
      }
    }
  }

  &.c11n-button--label-icon-secondary {
    .c11n-button__box {
      color: $black;
      &::before {
        background-color: transparent;
        border: rem($c11n-size-border-width-thick) solid $black;
      }
    }
  }

  &.c11n-button--label-icon-on-dark {
    .c11n-button__box {
      color: $white;
      &::before {
        border: rem($c11n-size-border-width-thick) solid $white;
        background-color: $white;
      }
    }
    &.c11n-button--label-icon-secondary {
      .c11n-button__box {
        color: $white;
        &::before {
          background-color: transparent;
          border: rem($c11n-size-border-width-thick) solid $white;
        }
      }
    }
  }

  &.c11n-button--invalid {
    @include button-invalid-styles;
  }
}

