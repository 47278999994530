@import '../../variables';

@mixin skeleton-loader-host {
  --radius: 999px;
  --animation-duration: 1.5s;
  --on-dark-opacity: 0.2;
}

@mixin skeleton-loader-child {
  .c11n-skeleton-loader__container {
    position: relative;
    overflow: hidden;
    background: $c11n-color-ink-200;
    outline: rem(1) solid transparent;
    z-index: 1;
  }

  .c11n-skeleton-loader__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-fill-mode: forwards; 
    animation-iteration-count: 3;
    animation-name: shimmer;
    animation-timing-function: $animation-easing-standard-web;
    animation-duration: var(--animation-duration); // See below about duration/delay
    background: linear-gradient(90deg, $c11n-color-ink-200 0%, $c11n-color-ink-300 50%, $c11n-color-ink-200 100%);
  }

  .c11n-skeleton-loader__sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

@mixin skeleton-loader--on-dark {
  .c11n-skeleton-loader__container {
    background: $c11n-color-ink-800;
    opacity: var(--on-dark-opacity);
  }

  .c11n-skeleton-loader__gradient {
    background: linear-gradient(90deg, $c11n-color-ink-800 0%, $c11n-color-ink-400 50%, $c11n-color-ink-800 100%);
  }
}

@mixin skeleton-loader--radius {
  .c11n-skeleton-loader__container {
    border-radius: var(--radius);
  }
}

@mixin skeleton-loader--text-xs {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-1x);
    margin-bottom: rem($c11n-size-space-1x);
  }
}

@mixin skeleton-loader--text-sm {
  .c11n-skeleton-loader__container {
    height: rem(10);
    margin-bottom: rem(10);
  }
}

@mixin skeleton-loader--text-md {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-1point5x);
    margin-bottom: rem($c11n-size-space-1point5x);
  }
}

@mixin skeleton-loader--text-lg {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-2x);
    margin-bottom: rem($c11n-size-space-2x);
  }
}

@mixin skeleton-loader--text-xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-2point5x);
    margin-bottom: rem($c11n-size-space-1point5x);
  }
}

@mixin skeleton-loader--text-2xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-3x);
    margin-bottom: rem($c11n-size-space-2x);
  }
}

@mixin skeleton-loader--text-3xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-4x);
    margin-bottom: rem($c11n-size-space-2x);
  }
}

@mixin skeleton-loader--text-4xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-5x);
    margin-bottom: rem($c11n-size-space-3x);
  }
}

@mixin skeleton-loader--text-5xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-6x);
    margin-bottom: rem($c11n-size-space-3x);
  }
}

@mixin skeleton-loader--text-6xl {
  .c11n-skeleton-loader__container {
    height: rem($c11n-size-space-8x);
    margin-bottom: rem($c11n-size-space-4x);
  }
}

// Animation should be 1.3s long and wait 0.2s before running again
// 1.5s total animation time
// Animation should reach its 'complete' state at the 87% mark since 1.3/1.5 is 0.87
// The 87% to 100% part of the animation should be the same to simualte the delay
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  87% {
    transform: translateX(100%);
  }
  
  100% {
    transform: translateX(100%);
  }
}
