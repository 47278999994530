@import './checkbox-mixins';

.c11n-checkbox {

  @include checkbox-host;
  @include checkbox-child;
  @include checkbox-input--states;

  @include c11n-hover-sibling-block-before('.c11n-checkbox__input', '.c11n-checkbox__label', '.c11n-checkbox__label-content');

  .c11n-checkbox__input {
    @include checkbox-input-host;
  }

  &.c11n-checkbox--indeterminate {
    @include checkbox--indeterminate;
    @include checkbox-input--indeterminate;
  }

  &.c11n-checkbox--indeterminate.c11n-checkbox--tile {
    @include checkbox-input--indeterminate-tile;
  }

  &.c11n-checkbox--indeterminate-clickable {
    @include checkbox--indeterminate-clickable;
  }

  &.c11n-checkbox--focused-label {
    @include checkbox--focused-label;
  }

  &.c11n-checkbox--hide-label {
    @include checkbox--hide-label;
  }

  &.c11n-checkbox--tile {
    @include checkbox--tile;
  }

  &:not(.c11n-checkbox--focused-label) {
    @include c11n-focus-outline-sibling('.c11n-checkbox__input', '.c11n-checkbox__label');
  }
}

.c11n-checkbox-group {
  @include checkbox-group-host;
  @include checkbox-group-child;

  .c11n-checkbox-group__legend--hidden {
    @include checkbox-sr-only;
  }
}