@use 'sass:math';
@import '../../variables';

$diameter: $c11n-size-space-3x;
// The gutter size is the same as defined in our grid system, 24px
// since we need to align the steps with the grid
$gutter-size: $c11n-size-space-3x;
$track-height: $c11n-size-space-0point5x;

%sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
}

@mixin step-indicator-host {
  --half-svg-width: #{rem(7)};
  --svg-spacing: #{rem(5)};
  --default-label-color: #{rgba($c11n-color-ink-800, 1)};

  display: block;
}

@mixin step-indicator-child {
  .c11n-step-indicator__container {
    position: relative;
  }

  .c11n-step-indicator__track {
    outline: rem(1) solid transparent;
    position: absolute;

    &::before {
      content: '';
      display: block;
      height: 100%;
      background-color: $c11n-color-ink-300;
    }
  }

  .c11n-step-indicator__list {
    list-style: none;
    padding-left: 0;
    font-family: $c11n-font-family-sans;
    font-size: rem($c11n-size-typography-xs);
    margin: 0;

    .c11n-step-indicator__list-item {

      counter-increment: step-counter;
      word-break: break-word;

      .c11n-step-indicator__step-container,
      .c11n-step-indicator__step-label {
        line-height: $c11n-line-height-xs;
      }

      .c11n-step-indicator__step-container {

        position: relative;

        &::before {
          content: counter(step-counter);
          font-weight: $c11n-font-weight-bold;
          display: block;
          text-align: center;
          padding: rem(math.div($c11n-size-space-3x - $c11n-size-line-height-xs, 2)) 0;
          width: rem($c11n-size-space-3x);
          height: rem($c11n-size-space-3x);
          border-radius: 50%;
          background-color: $c11n-color-turquoise-600;
          color: rgba($c11n-color-ink-800, 0.01);
          box-shadow: inset 0 0 0 rem($c11n-size-border-width-pipe) $c11n-color-turquoise-600;
          transition-property: background-color, box-shadow, color;
          transition-duration: $animation-timing-expand-large;
          transition-timing-function: $animation-easing-standard-web;
          transform-style: flat;
          backface-visibility: hidden;
          transform: translate3d(0, 0, 0);
          outline: rem(1) solid transparent;
        }
      }

      .c11n-step-indicator__step-label {
        &__sr-only {
          @extend %sr-only;
        }
      }

      &.c11n-step-indicator__list-item--current {

        font-weight: $c11n-font-weight-bold;

        .c11n-step-indicator__step-container {
          &::before {
            color: var(--default-label-color);
            background-color: $c11n-color-ink-000;
          }
        }

        ~ .c11n-step-indicator__list-item {

          .c11n-step-indicator__step-container {
            &::before {
              color: var(--default-label-color);
              background-color: $c11n-color-ink-000;
              box-shadow: inset 0 0 0 rem($c11n-size-border-width-pipe) $c11n-color-ink-300;
            }
          }
        }
      }
    }
  }
}

@mixin step-indicator-child--horizontal {
  .c11n-step-indicator__track {
    top: math.div(rem($diameter - $track-height), 2);
    left: 0;
    width: 100%;
    height: rem($track-height);
    // Track begins at the center of the step container
    padding-left: rem(math.div($diameter, 2));

    &::before {
      background-image: linear-gradient(to right, $c11n-color-turquoise-600, $c11n-color-turquoise-600);
      background-repeat: repeat-y;
      transition-property: background-size;
      transition-duration: $animation-timing-expand-large;
      transition-timing-function: $animation-easing-standard-web;
      transform-style: flat;
      backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
    }
  }

  .c11n-step-indicator__step-container {
    &::before {
      margin-right: auto;
      margin-bottom: rem($c11n-size-space-1point5x);
    }
  }
}

@mixin step-indicator-child--vertical {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 0 rem($c11n-size-space-1x);
      position: relative;
      padding-bottom: rem($gutter-size);

      &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: rem($track-height);
        top: rem(math.div($diameter, 2));
        left: calc(#{rem(math.div($diameter, 2))} - #{rem(math.div($track-height, 2))});
        background-color: $c11n-color-turquoise-600;
        transform-origin: top;
        transform: scaleY(1);
        transition-property: transform;
        transition-duration: $animation-timing-expand-large;
        transition-timing-function: $animation-easing-standard-web;
      }

      &:last-child {
        padding-bottom: 0;

        &::before {
          // easier to hide last child instead of including :last-child
          // in the above selector to decrease the specificity
          display: none;
        }
      }

      &--current, 
      &--current ~ .c11n-step-indicator__list-item {
        &::before {
          transform: scaleY(0);
        }
      }

      .c11n-step-indicator__step-label {
        padding-top: rem($c11n-size-space-0point5x);
        padding-bottom: rem($c11n-size-space-0point5x);
      }
    }
  }

  .c11n-step-indicator__track {
    width: rem($track-height);
    height: calc(100% - #{rem($diameter)});
    top: rem(math.div($diameter, 2));
    left: calc(#{rem(math.div($diameter, 2))} - #{rem(math.div($track-height, 2))});
  }
}

@mixin step-indicator-icon {
  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  .c11n-step-indicator__list-item [data-container="c11n-icon-container"],
  .c11n-step-indicator__list-item > .c11n-step-indicator__step-container > svg {
    position: absolute;
    top: var(--svg-spacing);
    left: var(--svg-spacing);
    transition-property: opacity;
    transition-duration: $animation-timing-expand-large;
    transition-timing-function: $animation-easing-standard-web;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    color: $c11n-color-ink-000;
  }

  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  .c11n-step-indicator__list-item--current [data-container="c11n-icon-container"], 
  .c11n-step-indicator__list-item--current > .c11n-step-indicator__step-container > svg {
    opacity: 0;
  }

  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  .c11n-step-indicator__list-item--current ~ .c11n-step-indicator__list-item [data-container="c11n-icon-container"], 
  .c11n-step-indicator__list-item--current ~ .c11n-step-indicator__list-item > .c11n-step-indicator__step-container > svg {
    opacity: 0;
  }
}

@mixin step-indicator--center {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item {
      text-align: center;
    }
  }
}

@mixin step-indicator--center--not-current-only {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item {
      .c11n-step-indicator__step-container {
        &::before {
          margin-left: auto;
        } 
      }
    }
  }
}

@mixin step-indicator-icon--center--not-current-only {
  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  .c11n-step-indicator__list-item [data-container="c11n-icon-container"], 
  .c11n-step-indicator__list-item > .c11n-step-indicator__step-container > svg {
    position: absolute;
    top: rem(5);
    left: calc(50% - #{var(--half-svg-width)});
  }
}

@mixin step-indicator--current-only--none--not-hide-track {
  .c11n-step-indicator__list {
    display: flex;
    justify-content: space-between;
  }
}

@mixin step-indicator--current-only--none--hide-track {
  .c11n-step-indicator__list {
    position: relative;
    display: inline-block;
    vertical-align: top;
    line-height: 0;
    &-item {
      display: inline-block;
      &:not(:last-child) {
        margin-right: rem($c11n-size-space-2x);
      }
    }
  }
}

@mixin step-indicator--not-current-only--not-none {
  .c11n-step-indicator__list {
    display: grid;
    grid-gap: 0 rem($c11n-size-space-3x);
  }
}

@mixin step-indicator--hide-step-numbers {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item,
    .c11n-step-indicator__list-item--current ~ .c11n-step-indicator__list-item {
      .c11n-step-indicator__step-container {
        &::before {
          color: rgba($c11n-color-ink-800, 0);
        }
      }
    }
  }
}

@mixin step-indicator--label-none {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item {
      .c11n-step-indicator__step-label {
        @extend %sr-only;
      }
      .c11n-step-indicator__step-container {
        &::before {
          margin-bottom: 0;
        }
      }
    }
  }  
}

@mixin step-indicator--label-current-only {
  .c11n-step-indicator__list {
    padding-bottom: rem($c11n-size-space-2x);
    &-item {
      font-weight: $c11n-font-weight-bold;
      
      .c11n-step-indicator__step-label {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        opacity: 0;
        transition-property: opacity;
        transition-duration: math.div($animation-timing-expand-large, 2);
        transition-timing-function: $animation-easing-standard-web;
        transform-style: flat;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
      }
      &::before {
        margin-bottom: 0;
      }
      &:not(.c11n-step-indicator__list-item--current) {
        .c11n-step-indicator__step-label{
          -webkit-user-select: none;
          user-select: none;
        }
      }
    }
    &-item--current {
      .c11n-step-indicator__step-label {
        white-space: nowrap;
        opacity: 1;
        transition-delay: math.div($animation-timing-expand-large, 2);
        z-index: 1;
      }
    }
  }
  .c11n-step-indicator__track {
    padding-right: rem(math.div($diameter, 2));
  } 
}

@mixin step-indicator--hide-track {
  .c11n-step-indicator__track {
    display: none;
  }

  &.c11n-step-indicator--vertical {
    .c11n-step-indicator__list-item {
      &::before {
        display: none;
      }
    }
  }
}

@mixin step-indicator--not-started {
  .c11n-step-indicator__list {
    .c11n-step-indicator__list-item {
      .c11n-step-indicator__step-container {
        &::before {
          color: rgba($c11n-color-ink-800, 1);
          background-color: $c11n-color-ink-000;
          box-shadow: inset 0 0 0 rem($c11n-size-border-width-pipe) $c11n-color-ink-300;

        }

        // TO DO: Remove second selector for version 2.0 as part of a breaking change
        [data-container="c11n-icon-container"], svg {
          opacity: 0;
        }
      }
    }
  }

  .c11n-step-indicator__track {
    &::before {
      background-size: 0
    }
  }

  &.c11n-step-indicator--vertical {
    .c11n-step-indicator__list {
      .c11n-step-indicator__list-item {
        &::before {
          transform: scaleY(0);
        }
      }
    }
  }
}

@mixin step-indicator-step-loop--align-start($total-steps) {
  .c11n-step-indicator__track {
    // When track is left aligned we calculate the padding needed at the end of the track
    // Track ends at the middle of the last step container
    // $c11n-size-space-3x is the gutter size, we need to know how many gutters are in between the total nuber of column (e.g. - 4 gutters for 5 columns)
    // For example for 5 steps ($i is total number of steps)
    // ((100% - 4 gutters) / 5) - half the diameter of step container = gives us total width of column less half the diameter of the step
    padding-right: calc(((100% - #{rem($gutter-size * ($total-steps - 1))}) * #{math.div(1, $total-steps)}) - #{rem(math.div($diameter, 2))});
  }
  .c11n-step-indicator__list {
    // $i is the total number of grid columns we need, for example 5 steps requires 5 columns
    grid-template-columns: repeat($total-steps, 1fr);
  }
}

@mixin step-indicator-step-loop--align-center($total-steps) {
  .c11n-step-indicator__track {
    // When track is center aligned we calculate the padding needed at boths end of the track, and the value of each is the same
    // Track begins at the middle of the first step container, centered in the column
    // Track ends at the middle of the last step container, also centered in the column
    // For example for 5 steps ($i is total number of steps)
    // (100% - 4 gutters) / 5 / 2 = gives us half the width of a column
    padding-left: calc((100% - #{rem($gutter-size * ($total-steps - 1))}) * #{math.div(1, $total-steps * 2)});
    padding-right: calc((100% - #{rem($gutter-size * ($total-steps - 1))}) * #{math.div(1, $total-steps * 2)});
  }
}

@mixin step-indicator-track-loop {
  @for $i from 2 through 12 {
    @for $j from 1 through $i {
      // Track includes class that shows what step we are on out of total number of steps
      .c11n-step-indicator__track {
        // In order to fill the track to the current step we need to have a class for each step
        // For example, and this loop generates all of the steps, but for step 2 of 5,
        // $j is 2, $i is 5
        // Given than on step 1 we are at 0% and at step 5 we are at 100%.
        // step 2 would be 25%, 3 would be 50%, and 4 would be 75%
        // We need to calculate this value dynamically
        &.c11n-step-indicator__track--step-#{$j}-of-#{$i} {
          &::before {
            // For step 2 of 5 the percentage is calculated as follows:
            // 2 - 1 / 5 - 1 = 1 / 4 = 25%
            background-size: percentage(math.div($j - 1, $i - 1)) rem($track-height);
          }
        }
      }
    }
  }
}