@use 'sass:math';
@import '../../variables';

@mixin c11n-menu-item-top-focus-styles() {
  &::before {
    height: calc(100% + #{rem(13)});
    top: rem(math.div(-14, 2));
  }

  &::after {
    height: calc(100% + #{rem(9)});
    top: rem(math.div(-10, 2));
  }
}

@mixin c11n-menu-item-bottom-focus-styles() {
  &::before {
    height: calc(100% + #{rem(13)});
    top: rem(math.div(-13, 2));
  }

  &::after {
    height: calc(100% + #{rem(9)});
    top: rem(math.div(-9, 2));
  }
}

@mixin c11n-menu-item-only-of-type-focus-styles() {
  &::before {
    height: calc(100% + #{rem(14)});
    top: rem(math.div(-14, 2));
  }

  &::after {
    height: calc(100% + #{rem(10)});
    top: rem(math.div(-10, 2));
  }
}

@mixin c11n-menu-item-focus-styles-pseudo($focus-pseudo-class: 'focus-visible', $is-nested-child: false) {

  &:first-of-type {
    @if $is-nested-child {
      > * {
        &:first-child {
          &:not(:only-of-type) {
            .c11n-menu__item:#{ $focus-pseudo-class } {
              @include c11n-menu-item-top-focus-styles();
            }
          }
        }
      }
    } @else {
      > .c11n-menu__item {
        &:first-child {
          &:not(:only-of-type) {
            &:#{ $focus-pseudo-class }  {
              @include c11n-menu-item-top-focus-styles();
            }
          }
        }
      }
    }
  }

  &:last-of-type {
    @if $is-nested-child {
      > * {
        &:last-child {
          &:not(:only-of-type), 
          &:only-of-type:not(:only-child) {
            .c11n-menu__item:#{ $focus-pseudo-class }  {
              @include c11n-menu-item-bottom-focus-styles();
            }
          }
        }
      }
    } @else {
      > .c11n-menu__item {
        &:last-child {
          &:not(:only-of-type), 
          &:only-of-type:not(:only-child){
            &:#{ $focus-pseudo-class }  {
              @include c11n-menu-item-bottom-focus-styles();
            }
          }
        }
      }
    }
  }

  &:only-of-type {
    @if $is-nested-child {
      > * {
        &:only-of-type {
          .c11n-menu__item:#{ $focus-pseudo-class }  {
            @include c11n-menu-item-only-of-type-focus-styles();
          }
        }
      }
    } @else {
      > .c11n-menu__item {
        &:only-of-type {
          &:#{ $focus-pseudo-class }  {
            @include c11n-menu-item-only-of-type-focus-styles();
          }
        }
      }
    }
  }
}

@mixin c11n-menu-item-focus-styles($is-nested-child: false) {

  @include c11n-menu-item-focus-styles-pseudo($is-nested-child: $is-nested-child);

}

@mixin base-focus-styles() {
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $c11n-elevation-z-index-800;
    pointer-events: none;
  }

  &::before {
    border: solid rem($c11n-size-border-width-thick) $c11n-color-ink-000;
    width: calc(100% + #{rem(14)});
    left: rem(math.div(-14, 2));
    height: calc(100% + #{rem(12)});
    top: rem(math.div(-12, 2));
  }

  &::after {
    border: solid rem($c11n-size-border-width-thick) $focus-color;
    width: calc(100% + #{rem(10)});
    left: rem(math.div(-10, 2));
    height: calc(100% + #{rem(8)});
    top: rem(math.div(-8, 2));
  }
}

@mixin menu-child() {

  .c11n-menu__menu {
    position: absolute;
    border: solid rem($c11n-size-border-width-thin) $c11n-color-ink-800;
    @include c11n-elevation(800);
    transition-property: opacity, visibility;
    transition-delay: 0ms, $animation-timing-collapse-small;
    transition-timing-function: $animation-easing-standard-web;
    transition-duration: $animation-timing-collapse-small;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    background-color: $c11n-color-ink-000;
    opacity: 0;
    visibility: hidden;
  }

  .c11n-menu__trigger {
    &[aria-expanded = "true"] {
      ~ .c11n-menu__menu {
        opacity: 1;
        visibility: visible;
        transition-duration: $animation-timing-expand-small;
        transition-delay: 0ms, 0ms;
      }
    }
  }

  .c11n-menu__group {
    &:not(:only-of-type):not(:first-of-type) {
      border-top: solid rem($c11n-size-border-width-thick) $c11n-color-ink-200;
    }
  }
  
  .c11n-menu__item {
    @include c11n-font-rules('regular', 'md');
    @include c11n-link-underline-rules('.c11n-menu__item-label');
    padding-top: rem($c11n-size-space-1point5x - 2);
    padding-bottom: rem($c11n-size-space-1point5x + 2);
    padding-left: rem($c11n-size-space-2x - 1);
    padding-right: rem($c11n-size-space-2x - 1);
    transition-property: background-color;
    transition-timing-function: $animation-easing-standard-web;
    transition-duration: $animation-timing-collapse-small;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);

    // button overrides
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    font-weight: inherit;
    font-family: inherit;
    text-align: inherit;
    width: 100%;

    &:hover, &:focus-visible {
      background: $c11n-color-ink-100;
    }

    &:hover {
      transition-duration: $animation-timing-expand-small;
    }
    
    &:focus-visible {
      @include base-focus-styles();
      transition: none;
      position: relative;
      z-index: $c11n-elevation-z-index-800;
      outline: none;
    }

    .c11n-menu__item-label {
      padding-bottom: $c11n-text-md-normal-underline-padding;
    }
  }
}