@import './link-mixins';

a.c11n-link {
  @include link-a;
}

button.c11n-link {
  @include link-button;  
}

a.c11n-link,
button.c11n-link {

  @include link-host;

  &.c11n-text-md,
  .c11n-text-md & {
    @include link--md;
  }

  &.c11n-text-sm,
  .c11n-text-sm & {
    @include link--sm;
  }

  &--normal {

    @include link--normal;

    &.c11n-text-md,
    .c11n-text-md & {
      @include link--md--normal;
    }

    &.c11n-text-sm,
    .c11n-text-sm & {
      @include link--sm--normal;
    }
  }

  &--icon,
  &--leading-icon {
    @include link--icon--leading-icon;
  }

  &--icon {
    @include link--icon;
  }

  &--leading-icon {
    @include link--leading-icon;
  }

  &--secondary {
    @include link--secondary;
  }

  .c11n-link--reinforced &,
  &--reinforced {
    @include link--reinforced;
  }

  &--on-dark {
    @include link--on-dark;
  }
  
  &--no-wrap {
    @include link--no-wrap;
  }
}