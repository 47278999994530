@font-face {
  font-family: 'MarkPro';
  src: url(../assets/MarkPro.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-Bold';
  src: url(../assets/MarkPro-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-Heavy';
  src: url(../assets/MarkPro-Heavy.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-Italic';
  src: url(../assets/MarkPro-Italic.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-Narrow';
  src: url(../assets/MarkPro-Narrow.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-NarrowHeavy';
  src: url(../assets/MarkPro-NarrowHeavy.otf) format('opentype');
}
@font-face {
  font-family: 'MarkPro-NarrowItalic';
  src: url(../assets/MarkPro-NarrowItalic.otf) format('opentype');
}
