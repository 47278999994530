@import '../../variables';

@mixin chip-group-host() {
  @include c11n-focus-outline-block-after();
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: rem($c11n-size-space-1x);
  padding: 0;
  margin: 0;
  line-height: 1;
}

@mixin chip-host() {
  --chip-fg-color: $c11n-color-ink-800;
  --chip-bg-color: #{$c11n-color-turquoise-100};
  --chip-alt-bg-color: #{$c11n-color-turquoise-600};
  --chip-border-color: #{$c11n-color-turquoise-600};
  --chip-alt-fg-color: #{$c11n-color-ink-000};
  --remove-button-width: 1.5rem;
  --height: 1.5rem;
  box-sizing: border-box;
  max-height: var(--height);
  white-space: nowrap;
  width: fit-content;
  padding: 0;
  margin: 0;
}

@mixin chip-child {
  .c11n-chip__box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: var(--height);
    width: fit-content;
    margin: 0;
    padding: 0 rem($c11n-size-space-1x);
    border-radius: rem($c11n-size-border-radius-sm);
    background: var(--chip-bg-color);
    border: rem($c11n-size-border-width-thin) solid var(--chip-border-color);
    overflow: visible;
    position: relative;

    &__label {
      font-size: rem($c11n-size-typography-xs);
      font-family: $default-font;
      font-weight: $c11n-font-weight-bold;
      color: $c11n-color-ink-800;
      display: inline-block;
      height: inherit;
      line-height: var(--height);
      background: transparent;
    }

    &--button,
    &--link {
      height: inherit;
      box-sizing: border-box;
      -webkit-appearance: none;
      appearance: none;
      text-decoration: none;
      max-height: var(--height);
      @include c11n-focus-outline-block-after();
      &:hover, &:focus-visible {
        background: var(--chip-alt-bg-color);
        .c11n-chip__box__label {
          color: var(--chip-alt-fg-color);
        }
      }
    }
  }

  button,
  a,
  a:active,
  a:visited {
    box-sizing: border-box;
    -webkit-appearance: none;
    appearance: none;
    text-decoration: none;
    color: var(--chip-fg-color);
  }

  a:hover {
    text-decoration: none;
    color: var(--chip-alt-fg-color);
  }
}

@mixin chip--yellow {
  --chip-bg-color: #{$c11n-color-yellow-100};
  --chip-alt-bg-color: #{$c11n-color-yellow-400};
  --chip-border-color: #{$c11n-color-yellow-600};
  --chip-alt-fg-color: $c11n-color-ink-800;
}

@mixin chip--ink {
  --chip-bg-color: #{$c11n-color-ink-200};
  --chip-alt-bg-color: #{$c11n-color-ink-600};
  --chip-border-color: #{$c11n-color-ink-800};
}

@mixin chip--internal {
  --chip-bg-color: #{$c11n-color-internal-400};
  --chip-alt-bg-color: #{$c11n-color-internal-800};
  --chip-border-color: #{$c11n-color-internal-800};
  --chip-alt-fg-color: #{$c11n-color-ink-000};
}

@mixin chip--medium {
  --height: 2rem;
  --remove-button-width: 2rem;
}

@mixin chip--removed {
  margin: 0;
  display: none;
}

@mixin chip--removable {
  .c11n-chip__box {
    padding: 0 0 0 rem($c11n-size-space-1x);

    button {
      position: relative;
      margin: 0 0 0 rem($c11n-size-space-1x);
      border: 0 none;
      border-radius: 0;
      background: var(--chip-bg-color);
      width: var(--remove-button-width);
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      svg {
        max-width: rem($c11n-size-space-1x);
        max-height: rem($c11n-size-space-1x);
        use {
          fill: var(--chip-fg-color);
        }
      }

      @include c11n-focus-outline-block-after();
      &:hover, &:focus-visible {
        background: var(--chip-alt-bg-color);
        border-color: var(--chip-alt-bg-color);
        // TODO: make use of icon component?
        svg {
          use {
            fill: var(--chip-alt-fg-color);
          }
        }
      }
    }
  }
}
