@import './banner-mixins';

.c11n-banner {
  @include banner-host;
  @include banner-child;

  &.c11n-banner--medium,
  &.c11n-banner--small {
    @include banner--medium--small;
  }

  &.c11n-banner--large {
    @include banner--large;
  }

  &.c11n-banner--small {
    @include banner--small;
  }

  &.c11n-banner--is-expandable {
    @include banner--is-expandable;
  }

  &.c11n-banner--medium.c11n-banner--is-expandable {
    @include banner--is-expandable--medium;
  }

  &.c11n-banner--large.c11n-banner--is-expandable {
    @include banner--is-expandable--large;
  }

  &.c11n-banner--error,
  &.c11n-banner--warning {
    @include banner--error--warning;
  }

  &.c11n-banner--success {
    @include banner--success;
  }

  &.c11n-banner--basic-plus-icon {
    @include banner--basic-plus-icon;
  }

  &.c11n-banner--basic {
    @include banner--basic;
  }

  &.c11n-banner--closed {
    @include banner--closed;
  }
}
