@mixin c11n-link-underline-local-rules($thickness) {
  // TODO?
  // This could easily be converted to something where the underline isn’t animated.
  // One would just have to change the existing code to the below commented code and
  // make sure to set the text-decoration-color instead of the background-image and 
  // the background-size where approptiate.
  //
  // text-decoration-color: transparent;
  // text-decoration-line: underline;
  // text-decoration-style: solid;
  // text-decoration-thickness: rem(1);
  // text-underline-offset: rem(5);
  background-image: linear-gradient(currentColor, currentColor), linear-gradient(currentColor, currentColor);
  background-size: 0% rem($thickness), 0% rem($thickness);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  transition-property: background-size;
  transition-duration: $animation-timing-collapse-small;
  transition-timing-function: $animation-easing-standard-web;
  transform-style: flat;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;
}

@mixin hover-underline($thickness: $c11n-size-border-width-thin, $hoverthickness: $c11n-size-border-width-thick) {
  transition-duration: $animation-timing-expand-small;
  background-size: 100% rem($thickness), 100% rem($hoverthickness);
  // for usability with Windows High Contrast Mode we need the following
  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-underline-offset: rem(5);
}

@mixin c11n-link-underline-rules($element: null, $thickness: $c11n-size-border-width-thin, $hoverthickness: $c11n-size-border-width-thick) {  
  @if $element != null {
    #{$element} {
      @include c11n-link-underline-local-rules($thickness);
    }
  } @else {
    @include c11n-link-underline-local-rules($thickness);
  }
  
  &:hover, &:focus-visible {
    @if $element != null {
      #{$element} {
        @include hover-underline($thickness, $hoverthickness);
      }
    } @else {
      @include hover-underline($thickness, $hoverthickness);
    }
  }

  &:hover {
    @if $element != null {
      #{$element} {
        transition-duration: $animation-timing-expand-small;
      }
    } @else {
      transition-duration: $animation-timing-expand-small;
    }
  }
  
  &:focus-visible {
    @if $element != null {
      #{$element} {
        transition-property: none;
      }
    } @else {
      transition-property: none;
    }
  }

}