@import './step-indicator-mixins';

.c11n-step-indicator {

  @include step-indicator-host;
  @include step-indicator-child;
  @include step-indicator-icon;
  
  &:not(.c11n-step-indicator--vertical) {
    @include step-indicator-track-loop;
    @include step-indicator-child--horizontal;
  }

  &.c11n-step-indicator--vertical {
    @include step-indicator-child--vertical;
  }
  
  &.c11n-step-indicator--center {
    @include step-indicator--center;
  }

  &.c11n-step-indicator--label-current-only {
    @include step-indicator--label-current-only;
  }

  &.c11n-step-indicator--label-none {
    @include step-indicator--label-none;
  }

  &.c11n-step-indicator--hide-track {
    @include step-indicator--hide-track;
  }
  
  &.c11n-step-indicator--center:not(.c11n-step-indicator--label-current-only) {
    @include step-indicator--center--not-current-only;
    @include step-indicator-icon--center--not-current-only;
  }

  &:not(.c11n-step-indicator--label-current-only):not(.c11n-step-indicator--label-none):not(.c11n-step-indicator--vertical) {
    @include step-indicator--not-current-only--not-none;
  }

  &.c11n-step-indicator--label-current-only:not(.c11n-step-indicator--hide-track):not(.c11n-step-indicator--vertical), 
  &.c11n-step-indicator--label-none:not(.c11n-step-indicator--hide-track):not(.c11n-step-indicator--vertical) {
    @include step-indicator--current-only--none--not-hide-track;
  }
  
  &.c11n-step-indicator--label-current-only.c11n-step-indicator--hide-track:not(.c11n-step-indicator--vertical), 
  &.c11n-step-indicator--label-none.c11n-step-indicator--hide-track:not(.c11n-step-indicator--vertical) {
    @include step-indicator--current-only--none--hide-track;
  }

  &.c11n-step-indicator--not-started {
    @include step-indicator--not-started;
  }

  &.c11n-step-indicator--hide-step-numbers {
    @include step-indicator--hide-step-numbers;
  }
  
  @for $i from 2 through 12 {
    &.c11n-step-indicator--#{$i}-steps:not(.c11n-step-indicator--label-current-only):not(.c11n-step-indicator--label-none):not(.c11n-step-indicator--vertical) {
      @include step-indicator-step-loop--align-start($i);
    }
    &.c11n-step-indicator--#{$i}-steps.c11n-step-indicator--center:not(.c11n-step-indicator--label-current-only):not(.c11n-step-indicator--label-none):not(.c11n-step-indicator--vertical) {
      @include step-indicator-step-loop--align-center($i);
    }
  }
}