@mixin c11n-hover-block-base-styles($scale: 0, $radioOrCheckbox: false) {
  &::before {
    content: '';
    position: absolute;
    background-color: rgba($c11n-color-ink-800, 0.1);
    .c11n--on-dark & {
      background-color: rgba($c11n-color-ink-000, 0.3);
    }
    border-radius: 50%;
    transform: scale($scale) translateZ(0);
    transform-origin: center;
    transition-property: transform, background-color;
    transition-timing-function: $animation-easing-standard-web;
    transition-duration: $animation-timing-collapse-small;
    transform-style: flat;
    backface-visibility: hidden;
    @if $radioOrCheckbox {
      top: rem(-4);
      left: rem(-8);
      width: rem($c11n-size-space-4x);
      height: rem($c11n-size-space-4x);
      pointer-events: none;
    } @else {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@mixin c11n-hover-block-hover-style() {
  &::before {
    transform: scale(1) translateZ(0);
    transition-duration: $animation-timing-expand-small;
  }
}

@mixin c11n-hover-block-focus-style() {
  @include c11n-hover-block-hover-style();
  &::before {
    transition-property: none;
  }
}

@mixin c11n-hover-block-before() {
  position: relative;
  @include c11n-hover-block-base-styles();
  &:hover {
    @include c11n-hover-block-hover-style();
  }

  &:focus-visible {
    @include c11n-hover-block-focus-style()
  }
}

@mixin c11n-hover-sibling-block-before($parent-sibling, $parent, $self) {
  #{$self} {
    @include c11n-hover-block-base-styles(0.5, true);
  }
  #{$parent} {
    &:hover {
      #{$self} {
        @include c11n-hover-block-hover-style();
      }
    }
  }
  #{$parent-sibling} {
    &:not(:disabled) {
      &:focus-visible {
        ~ #{$parent} {
          #{$self} {
              @include c11n-hover-block-focus-style();
          }
        }
      }
    }
    &:disabled {
      ~ #{$parent} {
        #{$self} {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}