@import './value-change-mixins';

.c11n-value-change {
  @include value-change-host;
  @include value-change-child;

  &.c11n-value-change--width-narrow {
    @include value-change--width-narrow;
  }

  &.c11n-value-change--weight-bold {
    @include value-change--weight-bold;
  }

  &.c11n-value-change--positive {
    @include value-change--positive;
  }

  &.c11n-value-change--negative {
    @include value-change--negative;
  }

  &.c11n-value-change--hide-icon {
    @include value-change--hide-icon;
  }

  &.c11n-value-change--icon-leading {
    @include value-change--icon-leading;
  }

  &.c11n-value-change--icon-trailing {
    @include value-change--icon-trailing;
  }
}