@use 'sass:math';
@import '../../variables';
@import '../navigation/navigation-mixins';

$z-index-list: (
  100: $c11n-elevation-z-index-100,
  400: $c11n-elevation-z-index-400,
  1600: $c11n-elevation-z-index-1600,
  2400: $c11n-elevation-z-index-2400,
  3200: $c11n-elevation-z-index-3200
);

@mixin nav-overflow-host {
  --trigger-height: #{rem($c11n-size-space-3x)};
  --trigger-margin: #{rem($c11n-size-space-1x)};

  position: relative;
  display: inline-block;
  line-height: normal;
}

@mixin nav-overflow-child {
  .c11n-nav-overflow__label,
  .c11n-tabs-overflow__label {
    display: inline-block;
    @include c11n-font-rules('bold', 'md');
    color: $c11n-color-ink-800;
    padding-right: var(--trigger-margin);
    vertical-align: text-bottom;
    &:empty {
      display: none;
    }
  }

  .c11n-nav-overflow__trigger,
  .c11n-tabs-overflow__trigger {
    // TODO: can't inherit next line from global?
    border: 0;
    position: relative;
    display: inline-block;
    width: var(--trigger-height);
    height: var(--trigger-height);
    box-sizing: border-box;
    padding: rem(5);
    border-radius: 50%;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $black;
    @include c11n-hover-block-before();
    @include c11n-focus-outline-block-after();
    @include c11n-link-underline-rules('.c11n-nav-overflow__trigger-label span');

    .c11n-overflow-menu-small {
      display: block;
    }

    &-label {
      position: absolute;
      right: rem($c11n-size-space-3x);
      top: 0;
      display: inline-block;
      white-space: nowrap;
      @include c11n-font-rules('bold', 'md');
      color: $c11n-color-ink-800;
      padding-right: var(--trigger-margin);
      &:empty {
        display: none;
      }
      span {
        padding-bottom: rem($c11n-text-md-bold-underline-padding);
      }
    }

    &[aria-expanded = "true"] {
      color: $white;
      &::before {
        background: $c11n-color-ink-800;
        transform: scale(1);
      }
    }
  }

  .c11n-nav-overflow__group,
  .c11n-tabs-overflow__group {
    list-style: none;
    padding-left: 0;
    margin: 0;
    text-align: left;
    @include c11n-menu-item-focus-styles(true);
  }

  .c11n-nav-overflow__menu,
  .c11n-tabs-overflow__menu {
    color: $c11n-color-ink-800;
    @include c11n-font-rules();
    width: rem(240);
    max-width: rem(240);
    // TODO: remove the style below from the default style once we introduce breaking change 
    // for nav overflow to include '--below' class for default below direction.
    // also, don't forget to include --below mixin as default for tabs-overflow once we remove this
    // since tabs-overflow won't need above direction hence '--below' class separately.
    top: calc(var(--trigger-height) + var(--trigger-margin));
  }

  .c11n-nav-overflow__item--with-icon,
  .c11n-nav-overflow__li--with-icon,
  .c11n-tabs-overflow__item--with-icon,
  .c11n-tabs-overflow__li--with-icon {
    .c11n-nav-overflow__a, .c11n-nav-overflow__button,
    .c11n-tabs-overflow__a, .c11n-tabs-overflow__button {
      padding-left: rem($c11n-size-space-6x - $c11n-size-border-width-thin);
      position: relative;
    }
    .c11n-nav-overflow__button,
    .c11n-tabs-overflow__button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 0 none;
      background: transparent;
    }
  }

  .c11n-nav-overflow__li,
  .c11n-tabs-overflow__li {
    position: relative;
  }

  .c11n-nav-overflow__a, .c11n-nav-overflow__button,
  .c11n-tabs-overflow__a, .c11n-tabs-overflow__button {
    color: inherit;
    line-height: inherit;
    display: block;
    span {
      padding-bottom: $c11n-text-md-normal-underline-padding;
    }
  }

  .c11n-nav-overflow__button,
  .c11n-tabs-overflow__button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0 none;
    background: transparent;
    width: 100%;
    text-align: left;
  }
}

@mixin nav-overflow-navigation-rules {
  .c11n-nav-overflow__trigger,
  .c11n-tabs-overflow__trigger {
    @include nav-item-and-overflow-trigger-styles;
    width: auto;
    height: rem($c11n-size-space-6x);
    padding-right: var(--trigger-margin);
    padding-left: var(--trigger-margin);
    border-radius: 0;

    &::before {
      top: 50%;
      left: 50%;
      margin-top: calc(var(--trigger-height) / -2);
      margin-left: calc(var(--trigger-height) / -2);
      height: var(--trigger-height);
      width: var(--trigger-height);
    }
  }

  .c11n-nav-overflow__menu,
  .c11n-tabs-overflow__menu {
    white-space: normal;
    top: 100%;
    &.c11n-nav-overflow--center {
      transform: translateX(calc(50% - #{math.div(rem(30), 2)}));
    }
  }
}

@mixin navigation-overflow-trigger-icon {
  .c11n-overflow-menu-small {
    transform-origin: rem(7) rem(7);
    transform: rotate(90deg);
  }
}

@mixin nav-overflow-item-icon-rules {
  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  [data-container="c11n-icon-container"], .c11n-menu__item > svg {
    color: $c11n-color-ink-400;
    position: absolute;
    top: rem($c11n-size-space-1point5x);
    left: rem($c11n-size-space-1point5x - $c11n-size-border-width-thin * 2);
  }
}

@mixin nav-overflow-trigger-icon {
  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  [data-container="c11n-icon-container"], svg.c11n-overflow-menu-small {
    position: relative;
    pointer-events: none;
    transition-property: color, fill;
    transition-timing-function: $animation-easing-standard-web;
    transition-duration: $animation-timing-collapse-small;
    transform-style: flat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
}

@mixin nav-overflow-trigger-icon--expanded {
  // TO DO: Remove second selector for version 2.0 as part of a breaking change
  [data-container="c11n-icon-container"], svg.c11n-overflow-menu-small {
    color: $c11n-color-ink-000;
    transition-duration: $animation-timing-expand-small;
  }
}

@mixin nav-overflow--auto-width {
  .c11n-nav-overflow__menu, 
  .c11n-tabs-overflow__menu {
    width: max-content;
  }
}

@mixin nav-overflow--start {
  .c11n-nav-overflow__menu,
  .c11n-tabs-overflow__menu {
    left: 0;
  }
}

@mixin nav-overflow--center {
  .c11n-nav-overflow__menu, 
  .c11n-tabs-overflow__menu {
    right: 0;
    transform: translateX(calc(50% - #{calc(var(--trigger-height) / 2)}));
  }
}

@mixin nav-overflow--end {
  .c11n-nav-overflow__menu,
  .c11n-tabs-overflow__menu {
    right: 0;
  }
}

@mixin nav-overflow--below {
  .c11n-nav-overflow__menu {
    top: calc(var(--trigger-height) + var(--trigger-margin));
  }
}

@mixin nav-overflow--above {
  .c11n-nav-overflow__menu {
    // TODO: remove the style below from the above style once we remove the style
    // for below direction from the default style
    top: initial;
    bottom: calc(var(--trigger-height) + var(--trigger-margin));
  }
}