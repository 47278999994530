@import '../../variables';

@mixin notifier-main {
  .c11n-notifier {
    @include c11n-focus-outline-block-after();
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    display: inline-block;
    background: transparent;
    border: 0 none;
    border-radius: 50%;
    padding: 0;

    &__box {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      background: $c11n-color-ink-000;
      height: rem($c11n-size-space-6x);
      width: rem($c11n-size-space-6x);
      border-radius: 50%;
      outline: rem(1) solid transparent;
      position: relative;
      border: 0 none;

      &__icon {
        position: relative;
        color: $black;
      }

      &__badge {
        position: absolute;
        top: rem($c11n-size-space-0point5x);
        left: rem($c11n-size-space-3x);
        z-index: 2;
      }
    }

    &:hover &__box,
    &:focus &__box {
      background-color: $c11n-color-ink-200;
    }
  }
}
