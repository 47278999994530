@import '../../variables';

@mixin badge-host {
  --color: #{$c11n-color-ink-000};
  --background-color: #{$c11n-color-red-600};
  --border-color: #{$c11n-color-ink-000};
  --border-width: #{rem($c11n-size-border-width-thick)};
  --line-height: #{rem(12)};
  // super specific padding values needed for centering
  --padding: #{rem(0.5) rem(3.7) rem(1.5)};
  display: flex;
}

@mixin badge-child {
  .c11n-badge__counter {
    font-family: $c11n-font-family-sans;
    font-weight: $c11n-font-weight-bold;
    font-size: rem($c11n-size-typography-xs);
    line-height: var(--line-height);
    font-variant-numeric: tabular-nums;
    text-align: center;
    color: var(--color);
    background-color: var(--background-color);
    padding: var(--padding);
    border-radius: rem(12);
    border-style: solid;
    border-width: var(--border-width);
    border-color: var(--border-color);
  }
}

@mixin badge--medium {
  // super specific padding values needed for centering
  --padding: #{rem(3.5) rem(6.7) rem(4.5)};
}

@mixin badge--item-counter-on-light {
  --border-width: 0;
  --background-color: #{$c11n-color-ink-800};
}

@mixin badge--item-counter-on-dark {
  --border-width: 0;
  --background-color: #{$c11n-color-ink-000};
  --color: #{$c11n-color-ink-800};
}

@mixin badge--border-color-ink-100 {
  --border-color: #{$c11n-color-ink-100};
}

@mixin badge--border-color-ink-800 {
  --border-color: #{$c11n-color-ink-800};
}

@mixin badge--border-color-red-100 {
  --border-color: #{$c11n-color-red-100};
}

@mixin badge--border-color-yellow-100 {
  --border-color: #{$c11n-color-yellow-100};
}

@mixin badge--border-color-turquoise-100 {
  --border-color: #{$c11n-color-turquoise-100};
}