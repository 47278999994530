@import '../../variables';
@import '../button';

@mixin modal-dialog-content-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

@mixin modal-dialog-child {
  .c11n-modal-dialog-document {
    $headerFooterSpace: 160;
    $closeButtonOffset: 7;
    $iconTopOffset: 28;
    $iconLeftOffset: 20;
    // Using 1vh as fallback in case --vh wasn't calculated/set in the component file.
    // --vh is only used in this block (not media query) since we don't need to re-calculate it for desktop.
    --modal-max-height: calc(calc(var(--vh, 1vh) * 100) - #{rem($c11n-size-space-6x)});
    --modal-max-height-sm-breakpoint: calc(100vh - 256px);
    --modal-min-height: #{rem($headerFooterSpace)};
    --modal-close-button-offset: #{rem($closeButtonOffset)};
    --modal-icon-left-offset: #{rem($iconLeftOffset)};
    --modal-icon-top-offset: #{rem($iconTopOffset)};
    --modal-bg-color: #{$c11n-color-ink-000};
    --modal-padding-top: #{rem($c11n-size-space-4x)};
    --modal-internal-banner-height: #{rem($c11n-size-space-5x)};
    --modal-internal-banner-margin-bottom: #{rem($c11n-size-space-4x)};
    --modal-internal-banner-padding-left: #{rem($c11n-size-space-4x)};
    --modal-grid-rows: auto 1fr auto;
    --modal-grid-areas:
      'header'
      'main'
      'footer';

    $minWindowHeightForMargin: rem(724);
    outline: rem(1) solid transparent;
    @include c11n-font-rules('regular','md');
    box-sizing: border-box;
    position: relative;
    border: 0 none;
    box-sizing: border-box;
    background-color: var(--modal-bg-color);
    box-shadow: $c11n-elevation-box-shadow-3200;
    padding: var(--modal-padding-top) 0 rem($c11n-size-space-4x) 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--modal-grid-rows);
    grid-template-areas: var(--modal-grid-areas);
    width: auto;
    min-width: fit-content;
    min-height: auto;
    height: auto;
    max-height: var(--modal-max-height);
    box-sizing: border-box;
    @include c11n-font-rules('regular','md');
    &__header {
      grid-area: header;
    }
    &__content {
      grid-area: main;
    }
    &__footer {
      grid-area: footer;
    }
    &__internal-banner {
      grid-area: internal-banner;
      margin-bottom: var(--modal-internal-banner-margin-bottom);
      background-color: $c11n-color-internal-800;
      color: $white;
      p {
        padding: rem($c11n-size-space-1x) 0 rem($c11n-size-space-1x) var(--modal-internal-banner-padding-left);
      }
    }
    margin: rem($c11n-size-space-3x);
    &--basic, &--internal {
      .c11n-modal-dialog-document__header, .c11n-modal-dialog-document__footer {
        padding: 0 rem($c11n-size-space-4x);
      }
      .c11n-modal-dialog-document__content{
        padding-left: rem($c11n-size-space-4x);
        padding-right: rem($c11n-size-space-4x);
      }
    }
    &__icon {
      position: absolute;
      left: var(--modal-icon-left-offset);
      top: var(--modal-icon-top-offset);
    }
    &--warning, &--error, &--success, &--internal-read-aloud {
      .c11n-modal-dialog-document__header, .c11n-modal-dialog-document__content, .c11n-modal-dialog-document__footer {
        padding: 0 rem($c11n-size-space-4x) 0 rem(60);
      }
    }
    &--warning, &--error {
      border-left: rem($c11n-size-space-0point5x) solid $error-color;
      .c11n-modal-dialog-document__icon {
        color: $error-color;
      }
    }
    &--success {
      border-left: rem($c11n-size-space-0point5x) solid $success-color;
      .c11n-modal-dialog-document__icon {
        color: $success-color;
      }
    }
    &--internal, &--internal-read-aloud {
      --modal-min-height: calc(var(--modal-internal-banner-height) + #{rem($headerFooterSpace)});
      --modal-max-height: calc(calc(var(--vh, 1vh) * 100) - var(--modal-internal-banner-margin-bottom));
      --modal-max-height-sm-breakpoint: var(--modal-max-height);
      --modal-bg-color: #{$c11n-color-internal-100};
      --modal-padding-top: 0;
      --modal-close-button-offset: calc(var(--modal-internal-banner-height) + #{rem($closeButtonOffset)});
      --modal-grid-rows: auto auto 1fr auto;
      --modal-grid-areas:
        'internal-banner'
        'header'
        'main'
        'footer';
    }
    &--internal-read-aloud {
      --modal-icon-top-offset: calc(#{rem($iconTopOffset)} + var(--modal-internal-banner-height));
      --modal-internal-banner-padding-left: var(--modal-icon-left-offset);
    }
    &--hide-body {
      .c11n-modal-dialog-document__footer {
        padding: 0 rem($c11n-size-space-4x);
      }
    }
    &__content {

      // the negative margin paired with the padding allows full visibility of the focus indicator on items that are flush with the content container
      $focus-indicator-offset: 6;
      margin-top: rem($c11n-size-space-2x - $focus-indicator-offset);
      margin-bottom: rem(-$focus-indicator-offset);
      padding-top: rem($focus-indicator-offset);
      padding-bottom: rem($focus-indicator-offset);
      overflow: auto;
      z-index: 1;
      height: auto;
      &__buttons {
        @include modal-dialog-content-buttons;
      }
    }
    &__footer, &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: auto;
    }
    &__header {
      margin: 0;
      padding: rem(1) 0 0 0;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__header h1 {
      font-weight: $c11n-font-weight-bold;
      @include c11n-text-crop-lg-bold();
      font-size: rem($c11n-size-typography-lg);
      height: auto;
      padding: 0 rem(14) 0 0;
    }
    &__footer {
      flex-direction: row;
      flex-wrap: wrap;
      margin: rem($c11n-size-space-3x) 0 0 0;
      padding: 0;
      justify-content: flex-start;
      flex-grow: 1;
      align-items: center;
      height: fit-content;
      gap: rem($c11n-size-space-3x);
      .c11n-button {
        margin: 0;
        flex-grow: 1;
        min-width: 100%;
      }
    }
    &__close {
      color: $c11n-color-ink-400;
      position: absolute;
      right: #{rem($closeButtonOffset)};
      top: var(--modal-close-button-offset);
    }
    &--overflowing {
      .c11n-modal-dialog-document__content {
        // removing negative margin when overflowing
        margin-bottom: 0;
        border-bottom: rem(1) solid $c11n-color-ink-300;
      }
      .c11n-modal-dialog-document__footer {
        margin: rem($c11n-size-space-4x) 0 0 0;
      }
    }

    @media screen and (min-width: $c11n-grid-breakpoint-sm) {
      max-height: var(--modal-max-height-sm-breakpoint);
      min-height: var(--modal-min-height);
      margin: rem($c11n-size-space-3x) rem($c11n-size-space-4x);
      &--full-height {
        max-height: calc(100vh - #{$c11n-size-space-6x});
      }
      &__footer {
        .c11n-button {
          flex-grow: 0;
          min-width: auto;
        }
      }
    }
    &__sendFocusToTop, &__sendFocusToBottom {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
}
