@import '../../variables';

@mixin graphic-pattern-host {
  display: block;
  color: $white;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  background-size: cover;
  background-origin: border-box;
  background-repeat: no-repeat;
}

@mixin graphic-pattern--version1 {
  background-position: 70% top;
  @media screen and (min-width: $c11n-grid-breakpoint-xxl) {
    background-position: 65% top;
  }
}

@mixin graphic-pattern--version2 {
  background-position: 50% top;
  @media screen and (min-width: $c11n-grid-breakpoint-xxl) {
    background-position: 10% top;
  }
}

@mixin graphic-pattern--bright-1 {
  background-color: $c11n-color-red-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23C20029' d='M0 480h2560V0H0z'/%3E%3Cpath d='M2560 480V0h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693.306 480H2560z' fill='%23F6001E'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--dark-1 {
  background-color: $c11n-color-red-800;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23670026' d='M0 480h2560V0H0z'/%3e%3cpath d='M2560 480V0h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693.306 480H2560z' fill='%23C20029'/%3e%3c/g%3e%3c/svg%3e");
}

@mixin graphic-pattern--turquoise-1 {
  background-color: $c11n-color-turquoise-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23007873' d='M0 480h2560V0H0z'/%3E%3Cpath d='M2560 480V0h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693.306 480H2560z' fill='%2300BDA3'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--yellow-1 {
  background-color: $c11n-color-yellow-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23BF8200' d='M0 480h2560V0H0z'/%3E%3Cpath d='M2560 480V0h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693.306 480H2560z' fill='%23FFAF00'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--bright-2 {
  background-color: $c11n-color-red-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23F6001E' d='M0 480h2560V0H0z'/%3E%3Cpath d='M0 480V0h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 480H0z' fill='%23C20029'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--dark-2 {
  background-color: $c11n-color-red-800;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23C20029' d='M0 480h2560V0H0z'/%3E%3Cpath d='M0 480V0h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 480H0z' fill='%23670026'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--turquoise-2 {
  background-color: $c11n-color-turquoise-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%2300BDA3' d='M0 480h2560V0H0z'/%3E%3Cpath d='M0 480V0h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 480H0z' fill='%23007873'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--yellow-2 {
  background-color: $c11n-color-yellow-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 480' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFAF00' d='M0 480h2560V0H0z'/%3E%3Cpath d='M0 480V0h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 480H0z' fill='%23BF8200'/%3E%3C/g%3E%3C/svg%3E%0A");
}

@mixin graphic-pattern--stacked-red {
  background-color: $c11n-color-red-800;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 1440' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23C20029' d='M0 1440h2560V960H0zM0 480h2560V0H0z'/%3E%3Cpath d='M1374.476 0c108.76 14.464 145.938 158.346 83.394 291.334L1369.9 480H0v480h2560V0H1374.476z' fill='%23670026'/%3E%3Cpath d='M0 1440V960h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 1440H0zM0 480V0h510.648C524.608 2.048 536.73 7.274 547 15.672c10.256 8.4 19.922 21.416 28.948 39.018L805.158 480H0zM2559.694 960V480h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693 960h866.694z' fill='%23F6001E'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--stacked-multi {
  background-color: $c11n-color-red-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 1440' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFAF00' d='M0 1440h2560V960H0z'/%3E%3Cpath d='M0 1440V960h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 1440H0z' fill='%23FFF'/%3E%3Cpath fill='%23670026' d='M0 960h2560V480H0z'/%3E%3Cpath d='M2560 960V480h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693.306 960H2560zM0 480h2560V0H0z' fill='%23FFF'/%3E%3Cpath d='M0 480V0h510.648C524.608 2.048 536.73 7.274 547 15.672c10.256 8.4 19.922 21.416 28.948 39.018L805.158 480H0z' fill='%23F6001E'/%3E%3Cpath d='M2560 480V0H1374.476c108.76 14.464 145.936 158.346 83.396 291.334L1369.9 480H2560z' fill='%2300BDA3'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--stacked-turquoise {
  background-color: $c11n-color-turquoise-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 1440' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%2300BDA3' d='M0 1440h2560V960H0zM0 480h2560V0H0z'/%3E%3Cpath d='M1374.476 0c108.76 14.464 145.938 158.346 83.394 291.334L1369.9 480H0v480h2560V0H1374.476z' fill='%23007873'/%3E%3Cpath d='M0 1440V960h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 1440H0zM0 480V0h510.648C524.608 2.048 536.73 7.274 547 15.672c10.256 8.4 19.922 21.416 28.948 39.018L805.158 480H0zM2559.694 960V480h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693 960h866.694z' fill='%2340CDBA'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--stacked-yellow {
  background-color: $c11n-color-yellow-600;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg viewBox='0 0 2560 1440' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFAF00' d='M0 1440h2560V960H0zM0 480h2560V0H0z'/%3E%3Cpath d='M1374.476 0c108.76 14.464 145.938 158.346 83.394 291.334L1369.9 480H0v480h2560V0H1374.476z' fill='%23BF8200'/%3E%3Cpath d='M0 1440V960h1264.522c13.96 2.048 26.084 7.276 36.352 15.672 10.254 8.398 19.918 21.416 28.946 39.018L1559.032 1440H0zM0 480V0h510.648C524.608 2.048 536.73 7.274 547 15.672c10.256 8.4 19.922 21.416 28.948 39.018L805.158 480H0zM2559.694 960V480h-862.118c108.76 14.464 145.936 158.346 83.396 291.334L1693 960h866.694z' fill='%23FFC240'/%3E%3C/g%3E%3C/svg%3E");
}

@mixin graphic-pattern--stacked-all {
  background-position: 50%;
}