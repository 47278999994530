@import './select-mixins';

.c11n-select {
  @include select-host;
  @include select-child;

  &:focus-within {
    @include select--focus-within;
  }

  &.c11n-select--error {
    @include select--error;
  }

  &.c11n-select--medium {
    @include select--medium;
  }

  &.c11n-select--large {
    @include select--large;
  }

  .c11n-select__box__select {
    @include select-input-host;
    @include select-input-child;
  }
}
