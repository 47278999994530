@import '../../variables';

@mixin details-reset {
  @supports not (-ms-ime-align: auto) {
    details.c11n-details {
      border: none;

      summary::-webkit-details-marker {
        display: none;
      }
      
      summary {
        list-style: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: default;

        &:-moz-focusring {
          color: transparent;
          text-shadow: none;
        }

        &::-moz-focus-inner {
          border: 0;
        }
      }
    }
  }
}

@mixin details-main {
  .c11n-details {
    position: relative;
    display: block;
    $border-color: $c11n-color-ink-300;    
    background: transparent;
    @include c11n-font-rules('regular', 'md');
    text-align: left;
    z-index: 0;

    &__heading {
      color: $c11n-color-ink-800;
    }

    &__summary {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-height: rem(30);

      .c11n-details__icon-container {
        display: flex;
        color: $c11n-color-ink-000;
        transition-property: background-color;
        transition-duration: $animation-timing-collapse-small;
        transition-timing-function: $animation-easing-standard-web;
      }

      @include c11n-link-underline-rules('.c11n-details__heading');
      
      .c11n-details__heading {
        // needed to add color as transition property and override the property set in the link underline mixin
        // by adding this line here instead of using !important in the following '&__heading' style block
        transition-property: background-size, color;
      }

      &:hover, &:focus-visible {
        .c11n-details__heading {
          color: $c11n-color-ink-800;
        }
        .c11n-details__icon-container {
          background-color: $c11n-color-ink-800;
        }
      }

      &:focus-visible {
        .c11n-details__icon-container {
          transition-property: none;
        }

        .c11n-details__summary-content {
          @include c11n-focus-outline-inner();
        }

        .c11n-details__focus-element {
          outline: rem($c11n-size-border-width-thick) solid $c11n-color-ink-000;
          outline-offset: rem($c11n-size-space-0point5x);
        }
      }

      &-content {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        background: transparent;
        margin: 0;
        padding-top: rem($c11n-size-space-0point5x);
      }
    }

    &__heading {
      padding: rem(1) 0 rem(6) 0;
      margin-left: 0;
      margin-right: rem($c11n-size-space-0point5x);
      text-align: left;
      text-decoration: none;
      @include c11n-font-rules('bold', 'md');
      @include c11n-text-crop-md();
      background: transparent;
    }

    &__icon {
      display: flex;
      transform: scaleY(1);
      transform-origin: center;
      transition-property: transform;
      transition-duration: $animation-timing-collapse-medium;
      transition-timing-function: $animation-easing-standard-web;
      backface-visibility: hidden;
      overflow: visible;
    }

    &[open] {
      .c11n-details__icon {
        transform: scaleY(-1);
        transition-duration: $animation-timing-expand-medium;
      }
    }

    &__body {
      @include c11n-font-rules('regular', 'md');
      background: transparent;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    &__icon-container {
      background-color: $interactive-color;
      border-radius: 50%;
      height: rem(14);
    }

    &--primary {
      .c11n-details__heading {
        color: $interactive-color;
      }
    }

    &--secondary {
      .c11n-details__heading {
        color: $c11n-color-ink-800;
      }
      .c11n-details__icon-container {
        background-color: $c11n-color-ink-800;
      }
    }

    &--leading {
      .c11n-details__heading {
        margin-right: 0;
        margin-left: rem($c11n-size-space-0point5x);
      }
      .c11n-details__summary-content {
        flex-direction: row-reverse;
      }
    }

    &--normal-weight {
      .c11n-details__heading {
        font-weight: $c11n-font-weight-normal;
      }
    }

    &--summary-align-left {
      .c11n-details__summary {
        justify-content: flex-start;
      }
    }

    &--summary-align-right {
      .c11n-details__summary {
        justify-content: flex-end;
      }
    }

    &--summary-align-center {
      .c11n-details__summary {
        justify-content: center;
      }
    }
  }
}

@mixin details-focus-element {
  .c11n-details__focus-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: rem($c11n-size-border-radius-sm);
    top: 0;
    left: 0;
    z-index: -1;
  }
}
